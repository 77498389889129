<mat-form-field class="mr-3">
  <mat-label>Application</mat-label>
  <mat-select
    [(ngModel)]="selectedApp"
    name="apps"
    (selectionChange)="onAppChange()"
  >
    @for (app of applications; track app.value) {
      <mat-option [value]="app.value">
        {{ app.name }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
<mat-form-field class="" class="mr-3">
  <input
    type="text"
    placeholder="Search For a Unit"
    matInput
    [(ngModel)]="selectedUnit"
    [matAutocomplete]="auto"
    name="unitsControl"
    [formControl]="unitsControl"
    (blur)="checkSelection()"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="autoCompleteUnitChanged($event)"
  >
    @for (unit of filteredUnits; track unit.id) {
      <mat-option [value]="unit.id">
        {{ unit.name }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
<mat-form-field class="" class="mr-3">
  <mat-label>Pick a ReportType</mat-label>
  <mat-select [(ngModel)]="selectedType" name="reportTypes">
    @for (type of reportTypes; track type) {
      <mat-option [value]="type">
        {{ type }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
<button
  mat-raised-button
  (click)="getUnitProfileReport()"
  [disabled]="(!selectedApp && !selectedType) || !selectedUnit"
  color="accent"
>
  Get Unit Settings Report
</button>
<ngx-loading [show]="loading"></ngx-loading>
