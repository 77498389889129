import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  effect,
} from '@angular/core';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { Context } from '../../models/admin-models';
import {
  ActivatedRoute,
  Router,
  Event as NavigationEvent,
  NavigationStart,
} from '@angular/router';
import { Location } from '@angular/common';
import { SessionStorageService } from '../../services/session-storage.service';
import { ModuleStatusService } from '../../services/module-status.service';
import { FormlyComponent } from '../../conditional-form/formly.component';
import { UnifiedFlowService } from '../unified.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { FormService } from '../../conditional-form/form-service.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';

@Component({
  selector: 'app-unified-case',
  templateUrl: './unified-case.component.html',
  styleUrls: ['./unified-case.component.scss'],
})
export class UnifiedCaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FormlyComponent, { static: false })
  private questionnaire: FormlyComponent;
  isNaN: Function = Number.isNaN;

  tabContext: Context = { type: 'unified', unit: null, app: null };
  profileData;
  loading = false;
  save = 'og';
  steps = [];
  tabChange;
  state;
  currentApp = 'rb';
  profile;
  resultsPage = { app: null, visible: false };
  availableApps = [];
  currentStep: any = 0;
  formModel = {};
  dataLoaded = false;
  liValidate = false;
  iwValidate = false;
  availableAppStatus = {
    clientinfo: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
    aw: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
    pp: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
    iw: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
    li: {
      available: false,
      required: false,
      ShowInSidebarNav: false,
    },
  };
  rights = [];
  notesValid = true;
  navDisabled = false;
  newProfileObs;
  prodsSelectedPP = false;
  progSelectedIW = null;
  stepsObs;
  summaryClient = '';
  formValid = true;
  downloadingFactsheet = false;
  unsubscribe: Subject<any> = new Subject();
  formSubmitted = false;
  initialErrorsList = [];
  noSaveProfile = false;
  modules = [];
  formModified = false;

  constructor(
    private rbs: RightBridgeApiService,
    private route: ActivatedRoute,
    public unfFlowSvc: UnifiedFlowService,
    private location: Location,
    private ss: SessionStorageService,
    public modStatusSvc: ModuleStatusService,
    private router: Router,
    private dialog: MatDialog,
    private frmSvc: FormService
  ) {
    this.rights = this.ss.get('rights');
    this.noSaveProfile = this.rights?.includes('ReadOnly');

    router.events
      .pipe(
        takeUntil(this.unsubscribe),
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((ev: NavigationEvent) => {
        if (ev instanceof NavigationStart) {
          const eventUrl = ev.url.split('/');
          const urlApp = eventUrl[2] ? eventUrl[2].toLowerCase() : '';
          const validApp =
            urlApp == 'aw' ||
            urlApp == 'pp' ||
            urlApp == 'rb' ||
            urlApp == 'li' ||
            urlApp == 'iw';

          if (
            this.currentApp === 'li' &&
            (this.currentStep === 'validate' ||
              this.currentStep === 'products' ||
              this.currentStep === 'compare' ||
              this.currentStep === 'results') &&
            eventUrl[eventUrl.length - 1] === '0' &&
            ev.navigationTrigger === 'popstate'
          ) {
            this.getQuestionnaire();
          } else if (validApp && urlApp != this.currentApp) {
            this.currentApp = urlApp;
            this.getQuestionnaire();
          }
        }
      });

    effect(() => {
      if (
        this.frmSvc.formModified().modified &&
        this.modStatusSvc.appStatuses().complete.includes(this.currentApp)
      ) {
        this.formModified = true;
      } else {
        this.formModified = false;
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.profile = params.id;

      this.currentApp = params.app ? params.app : 'rb';
      this.currentStep = params.step ? params.step : this.currentStep;
      this.ss.set('currentStep', this.currentStep);
      this.ss.set('currentApp', this.currentApp);
    });

    this.setModuleStatus();

    this.availableApps = this.ss.get('availableApps');

    if (!this.availableApps || typeof this.availableApps == 'undefined') {
      this.availableApps = [];

      if (this.rights?.includes('PP')) {
        this.availableApps.push('pp');
        this.availableApps.push('clientinfo');
      }

      if (this.rights?.includes('AW')) {
        this.availableApps.push('aw');
      }

      if (this.rights?.includes('IW')) {
        this.availableApps.push('iw');
      }

      if (this.rights?.includes('LI')) {
        this.availableApps.push('li');
      }

      this.ss.set('availableApps', this.availableApps);
    }

    this.getQuestionnaire();
    this.ss.set('currentStep', this.currentStep);
  }

  ngAfterViewInit() {
    if (!this.newProfileObs) {
      this.newProfileObs = this.unfFlowSvc.newProfileExternal;
      this.newProfileObs.subscribe(x => this.handleNewProfile(x));
    }

    if (!this.stepsObs) {
      this.stepsObs = this.unfFlowSvc.stepsListExternal;
      this.stepsObs.pipe(debounceTime(50)).subscribe(x => {
        this.steps = x;
      });

      if (!this.steps.length && this.currentStep === 'compare-results') {
        this.getQuestionnaire();
      }
    }

    if (!this.steps.length && this.currentStep === 'compare-results') {
      this.getQuestionnaire();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setModuleStatus() {
    this.availableAppStatus = {
      clientinfo: {
        available: this.currentApp == 'rb',
        required: false,
        ShowInSidebarNav: false,
      },
      aw: {
        available: this.currentApp == 'aw',
        required: false,
        ShowInSidebarNav: false,
      },
      pp: {
        available: this.currentApp == 'pp',
        required: false,
        ShowInSidebarNav: false,
      },
      iw: {
        available: this.currentApp == 'iw',
        required: false,
        ShowInSidebarNav: false,
      },
      li: {
        available: this.currentApp == 'li',
        required: false,
        ShowInSidebarNav: false,
      },
    };
    this.modStatusSvc.getApps(this.profile, this.noSaveProfile);
  }

  getQuestionnaire() {
    const existing = this.ss.get('noSave');

    this.setLoading(true);
    // this.profileData = null;
    if (!this.unfFlowSvc.getSaving()) {
      if (this.noSaveProfile && this.currentApp != 'summary' && existing) {
        if (existing.questionnaire) {
          const data = JSON.parse(
            JSON.stringify(this.ss.get('noSave').questionnaire)
          );
          this.profileData = data;
        } else {
          this.rbs.getQuestions().subscribe(data => {
            this.unfFlowSvc.setStatusCheck();
            this.profileData = data.results.questionnaire;
            setTimeout(() => {
              this.setLoading(false);
            }, 500);
          });
        }
        this.setLoading(false);
      } else {
        if (this.currentApp != 'summary') {
          this.rbs
            .getQuestions(this.profile, this.currentApp)
            .subscribe(data => {
              this.profileData = data.results.questionnaire;
              if (this.currentStep == 0) {
                this.unfFlowSvc.setStatusCheck();
              }
              setTimeout(() => {
                this.setLoading(false);
              }, 500);
              this.navDisabled = false;
            });
        }
      }
    } else {
      setTimeout(() => {
        this.getQuestionnaire();
      }, 250);
    }
  }

  handleTabChange(ev, fromNav = false) {
    const random = Math.floor(Math.random() * 10);
    const save = this.unfFlowSvc.getSaving();
    const guardParams = {
      currentApp: this.currentApp,
      currentStep: this.currentStep,
      prodsSelectedPP: this.prodsSelectedPP,
      progSelectedIW: this.progSelectedIW,
      formValid: this.formValid,
      steps: this.steps,
    };

    setTimeout(() => {
      if (save && fromNav) {
        setTimeout(() => {
          this.handleTabChange(ev, fromNav);
        }, 250);
      } else {
        let currentAppIdx = 0;
        if (
          this.isNaN(+ev) &&
          ev != 'next' &&
          ev != 'back' &&
          !ev.hasOwnProperty('index')
        ) {
          if (
            this.unfFlowSvc.allowNavigation(
              true,
              fromNav,
              false,
              false,
              guardParams
            )
          ) {
            this.currentStep = ev;
            this.router.navigate([
              `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
            ]);
          }
          this.navDisabled = false;
        } else if (
          this.currentApp != 'rb' &&
          this.isNaN(+this.currentStep) &&
          !this.isNaN(+ev.index)
        ) {
          this.setLoading(true);
          if (
            this.unfFlowSvc.allowNavigation(
              true,
              fromNav,
              false,
              false,
              guardParams
            )
          ) {
            this.getQuestionnaire();
            if (!this.isNaN(+this.currentStep)) {
              this.unfFlowSvc.setSaving(false);
            }
            this.currentStep = ev.index;
            this.router.navigate([
              `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
            ]);
          }
        } else if (ev === 'next') {
          const validationParam =
            this.steps.length == +this.currentStep + 1
              ? 'all'
              : this.currentStep;
 
          if(!this.isNaN(+this.currentStep)) {
            this.unfFlowSvc.setTriggerValidate({ validate: validationParam });
          }
 
          if (this.isNaN(+this.currentStep)) {
            this.modStatusSvc.getNextApp(this.profile);
            setTimeout(() => {
              if (
                this.unfFlowSvc.allowNavigation(
                  true,
                  fromNav,
                  true,
                  false,
                  guardParams
                ) &&
                this.unfFlowSvc.getNoteValidity(this.currentApp)
              ) {
                currentAppIdx =
                  this.currentApp == 'rb'
                    ? -1
                    : this.availableApps.indexOf(this.currentApp);
                const option = this.navOption();
                if (option && option != 'appChange') {
                  this.router.navigate([option]);
                }
                this.navDisabled = false;
              } else if (
                !this.unfFlowSvc.getNoteValidity(this.currentApp) &&
                !this.rights.includes('BridgeNotes') &&
                this.unfFlowSvc.allowNavigation(
                  true,
                  fromNav,
                  true,
                  false,
                  guardParams
                )
              ) {
                currentAppIdx =
                  this.currentApp == 'rb'
                    ? -1
                    : this.availableApps.indexOf(this.currentApp);
                const option = this.navOption();
                if (option && option != 'appChange') {
                  this.router.navigate([option]);
                }
                this.navDisabled = false;
              }
              this.navDisabled = false;
            }, 500);
          } else if (
            this.currentApp == 'rb' &&
            this.steps.length == +this.currentStep + 1
          ) {
            setTimeout(() => {
              this.unfFlowSvc.setTriggerSave({
                msg: 'unifiedTabSave-' + currentAppIdx,
                id: this.profile,
              });
              this.handleAppChange();
              // }
              this.navDisabled = false;
            }, 250);
          } else if (
            this.currentApp != 'rb' &&
            this.steps.length == +this.currentStep + 1
          ) {
            this.setLoading(true);

            setTimeout(() => {
              if (
                this.unfFlowSvc.allowNavigation(
                  true,
                  fromNav,
                  true,
                  false,
                  guardParams
                )
              ) {
                this.ss.set('currentStep', 9999);
                this.unfFlowSvc.setTriggerSave({
                  msg: 'unifiedTabSave-' + random,
                  id: this.profile,
                });
                // setTimeout(() => {
                const url = this.navOption();
                this.router.navigate([url]);
                // }, 500);
              }
              this.setLoading(false);
              this.navDisabled = false;
            }, 500);
          } else {
            this.setLoading(true);
            const existing = this.profile && this.profile.length > 0;
            const saveVal = existing
              ? `unifiedTabSave-${this.currentApp}-${this.currentStep}`
              : 'new-profile';
            this.unfFlowSvc.setTriggerSave({ msg: saveVal, id: this.profile });
            if (
              this.unfFlowSvc.allowNavigation(
                true,
                fromNav,
                true,
                false,
                guardParams
              ) &&
              +this.currentStep + 1 < this.steps.length
            ) {
              this.currentStep = +this.currentStep + 1;
              if (existing) {
                this.router.navigate([
                  `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
                ]);
              }
              this.ss.set('currentStep', this.currentStep);
            }
            this.setLoading(false);
          }
          this.navDisabled = false;
        } else if (ev === 'back') {
          if (
            this.currentStep !== 'validate' &&
            this.currentStep !== 'products' &&
            this.currentStep !== 'compare'
          ) {
            this.unfFlowSvc.setTriggerSave({
              msg: 'unifiedTabSave-' + this.currentStep,
              id: this.profile,
            });
          }

          if (this.currentApp === 'iw' && this.currentStep === 'validate') {
            this.unfFlowSvc.setNoteValidity({
              app: this.currentApp,
              valid: true,
            });
          }

          const timer =
            !this.isNaN(+this.profile) && this.profile.length > 7 ? 4000 : 0;
          setTimeout(() => {
            this.location.back();
            if (this.isNaN(+this.currentStep)) {
              this.getQuestionnaire();
            }
          }, timer);
        } else if (fromNav && !this.isNaN(ev.index)) {
          this.unfFlowSvc.setTriggerSave({
            msg: 'unifiedTabSave-' + this.currentStep,
            id: this.profile,
          });
          this.currentStep = ev.index;
          this.router.navigate([
            `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
          ]);
          this.ss.set('currentStep', this.currentStep);
          this.navDisabled = false;
        } else {
          if (
            this.unfFlowSvc.allowNavigation(
              true,
              fromNav,
              false,
              false,
              guardParams
            )
          ) {
            this.unfFlowSvc.setTriggerSave({
              msg: 'unifiedTabSave-' + this.currentStep,
              id: this.profile,
            });
            // if(!this.isNaN(+ev.index)) {
            // this.getQuestionnaire();
            // }
            this.currentStep = ev.index;
            this.router.navigate([
              `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
            ]);
            this.ss.set('currentStep', this.currentStep);
            this.navDisabled = false;
          }
        }
        this.navDisabled = false;
      }
    }, 250);
  }

  handleAppChange(fromNav = false, ev?) {
    // const save = this.unfFlowSvc.getSaving();

    this.modStatusSvc.getNextApp(this.profile);
    let nextApp = ev?.app ? ev.app : this.modStatusSvc.nextApp();
    this.setModuleStatus();

    if (this.unfFlowSvc.getSaving()) {
      setTimeout(() => {
        this.handleAppChange(fromNav, ev);
      }, 250);
    } else {
      if (this.formModified && !this.isNaN(+this.currentStep)) {
        let modifiedDialogRef = this.dialog.open(WarnDialogComponent, {
          panelClass: 'warn-dialog',
          data: {
            headline: 'Questionnaire Has Been Modified',
            content:
              'It appears you have updated data in the questionnaire. You will need to visit the results page before completing the case to ensure the case is updated. Would you like to visit there now?',
            confirm: 'Yes',
          },
        });
        modifiedDialogRef.afterClosed().subscribe(result => {
          if (result == 'continue') {
            this.frmSvc.formModified.set({ modified: false, count: 0, values: {}, app: this.currentApp })
            if (this.currentApp == 'iw') {
              this.router.navigate([`case/iw/${this.profile}/validate`]);
            } else if (this.currentApp == 'li') {
              this.router.navigate([`case/li/${this.profile}/validate`]);
            } else {
              this.router.navigate([
                `/case/${this.currentApp}/${this.profile}/results`,
              ]);
            }
          }
          modifiedDialogRef = null;
        });
      } else {
        if (!this.isNaN(+this.currentStep) && nextApp != 'summary') {
          this.unfFlowSvc.setTriggerValidate({ validate: this.currentStep });
          // Hopefully this doesn't break things, trying to reduce module status calls
          // this.modStatusSvc.getApps(this.profile);
        } else if (!this.isNaN(+this.currentStep) || nextApp != 'summary') {
          this.unfFlowSvc.setTriggerSave({
            msg: 'appChangeSave-' + this.modStatusSvc.nextApp(),
            id: this.profile,
          });
          this.modStatusSvc.getApps(this.profile);
        }

        const timer =
          (this.currentApp === 'rb' && fromNav && nextApp === 'pp') ||
          this.currentApp === 'iw'
            ? 1000
            : 500;

        setTimeout(() => {
          nextApp = ev?.app ? ev.app : this.modStatusSvc.nextApp();

          const guardParams = {
            currentApp: this.currentApp,
            currentStep: this.currentStep,
            prodsSelectedPP: this.prodsSelectedPP,
            progSelectedIW: this.progSelectedIW,
            formValid: this.formValid,
            steps: this.steps,
          };

          this.unfFlowSvc.setNoteValidity({
            app: this.currentApp,
            valid: true,
          });
          this.steps = [];

          if (
            nextApp == 'summary' &&
            this.unfFlowSvc.allowNavigation(
              true,
              true,
              false,
              !fromNav,
              guardParams
            )
          ) {
            if (this.currentApp != nextApp) {
              this.currentStep =
                nextApp == 'summary' || this.currentApp == 'summary'
                  ? 'summary'
                  : this.currentStep;
            }
            const nestedTimer =
              !this.isNaN(+this.profile) && this.profile.length > 7 ? 3000 : 0;
            setTimeout(() => {
              this.router.navigate([`/case/summary/${this.profile}`]);
              this.ss.set('currentApp', this.currentApp);
              this.ss.set('currentStep', this.currentStep);
            }, nestedTimer);
          } else if (nextApp != 'summary' && nextApp !== 'cdrnotrequired') {
            if (
              (this.currentApp == 'rb' &&
                (this.unfFlowSvc.allowNavigation(
                  true,
                  true,
                  false,
                  false,
                  guardParams
                ) ||
                  this.modStatusSvc.clientInfoNotRequired())) ||
              this.currentApp != 'rb'
            ) {
              if (this.currentApp != nextApp && nextApp) {
                this.currentApp = nextApp;
                this.currentStep = 0;
              }
              // setTimeout(() => {
              this.getQuestionnaire();
              this.router.navigate([
                `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
              ]);
              this.ss.set('currentApp', this.currentApp);
              this.ss.set('currentStep', this.currentStep);
              this.rbs.setApp(this.currentApp);
            }
          } else if (nextApp === 'cdrnotrequired') {
            this.router.navigate([`/cdrnotrequired`]);
          }
        }, timer);
      }
    }
  }

  handleAppPage(ev, noSave = false) {
    this.unfFlowSvc.setTriggerValidate({ validate: 'all' });
    const save = this.unfFlowSvc.getSaving();

    if (save) {
      setTimeout(() => {
        this.handleAppPage(ev, true);
      }, 250);
    } else {
      const guardParams = {
        currentApp: this.currentApp,
        currentStep: this.currentStep,
        prodsSelectedPP: this.prodsSelectedPP,
        progSelectedIW: this.progSelectedIW,
        formValid: this.formValid,
        steps: this.steps,
        requestedStep: ev.tab,
      };

      setTimeout(() => {
        if (
          this.unfFlowSvc.allowNavigation(
            false,
            true,
            false,
            false,
            guardParams
          )
        ) {
          if (!noSave && !this.isNaN(+this.currentStep)) {
            const random = Math.floor(Math.random() * 10);
            this.unfFlowSvc.setTriggerSave({
              msg: 'appPageSave-' + random,
              id: this.profile,
            });
          }
          this.currentStep = ev.tab;
          this.router.navigate([
            `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
          ]);
        }
      }, 500);
    }
  }

  handleNewProfile(ev) {
    this.profile = ev;
    let app = this.currentApp;
    let step = 0;

    if (this.currentApp == 'rb') {
      const currentAppIdx =
        this.currentApp == 'rb'
          ? -1
          : this.availableApps.indexOf(this.currentApp);
      app = this.availableApps[+currentAppIdx + 1];
    } else {
      step++;
    }

    this.router.navigate([`/case/${app}/${this.profile}/${step}`]);
  }

  handleModelChange(ev) {
    this.formModel = ev.model;

    if (
      ev.model['LifeWizard_AssessmentType'] &&
      ev.model['LifeWizard_AssessmentType'] == 2
    ) {
      this.liValidate = true;
    } else if (
      ev.model['LifeWizard_AssessmentType'] &&
      ev.model['LifeWizard_AssessmentType'] != 2
    ) {
      this.liValidate = false;
    }

    if (
      ev.model.ValidateProduct &&
      ev.model.ValidateProduct['InvestmentWizard_AssessmentType'] &&
      ev.model.ValidateProduct['InvestmentWizard_AssessmentType'] == 2
    ) {
      this.iwValidate = true;
    } else if (
      ev.model.ValidateProduct &&
      ev.model.ValidateProduct['InvestmentWizard_AssessmentType'] &&
      ev.model.ValidateProduct['InvestmentWizard_AssessmentType'] != 2
    ) {
      this.iwValidate = false;
    }
  }

  currentStepDisplay() {
    if (this.currentApp === 'summary') {
      return this.summaryClient;
    }
    if (!this.isNaN(+this.currentStep)) {
      return this.steps[this.currentStep]
        ? this.steps[this.currentStep].label
        : null;
    } else {
      let title;
      switch (this.currentStep) {
        case 'investment-model':
          title = 'Investment Model';
          break;
        case 'program-selection':
          title = 'Program Selection';
          break;
        case 'fund-allocations':
          title = 'Fund Allocations';
          break;
        case 'validate':
          if (this.currentApp == 'iw') {
            title = 'Validate Portfolio';
          } else {
            title = 'Validate Products';
          }
          break;
        case 'advisory':
          title = 'Advisory';
          break;
        case 'compare-results':
          title = 'Income Comparison';
          break;
        case 'results':
          if (this.currentApp == 'li') {
            title = 'Calculated Needs Results';
          } else {
            title = 'Results';
          }
          break;
        case 'needs':
          title = 'Calculated Needs Results';
          break;
        case 'compare':
          title = 'Compare Scenarios';
          break;
        case 'products':
          title = 'Product Considerations';
          break;
      }
      return title;
    }
  }

  currentAppDisplay() {
    switch (this.currentApp) {
      case 'pp':
        return 'Product Profiler';
      case 'aw':
        return 'Annuity Wizard';
      case 'iw':
        return 'Investment Wizard';
      case 'li':
        return 'Life Wizard';
      case 'rb':
        return 'Client Information';
      case 'summary':
        return 'Case Summary';
    }
  }

  navOption(returnStepName = false) {
    this.modStatusSvc.getNextApp(this.profile);

    switch (this.currentApp) {
      case 'pp':
        if (this.currentStep == 'results') {
          this.handleAppChange();
          return 'appChange';
        } else {
          this.setLoading(true);
          this.currentStep = 'results';
          return returnStepName ? 'results' : `case/pp/${this.profile}/results`;
        }
      case 'aw':
        if (this.currentStep == 'results') {
          this.handleAppChange();
          return 'appChange';
        } else if (
          this.currentStep * 1 === this.steps.length - 1 &&
          this.modStatusSvc.hasAwComparisonPage()
        ) {
          this.currentStep = 'compare-results';
          return returnStepName
            ? 'compare-results'
            : `case/aw/${this.profile}/compare-results`;
        } else {
          if (this.noSaveProfile) {
            // setTimeout(_ => {
            this.currentStep = 'results';
            return returnStepName
              ? 'results'
              : `case/aw/${this.profile}/results`;
            // }, 500);
          } else {
            this.currentStep = 'results';
            return returnStepName
              ? 'results'
              : `case/aw/${this.profile}/results`;
          }
        }
      case 'iw':
        if (this.currentStep == 'program-selection') {
          if (this.progSelectedIW.selectionType.toLowerCase() == 'advisory') {
            this.currentStep = 'validate';
            return returnStepName
              ? 'validate'
              : `case/iw/${this.profile}/validate`;
          } else {
            this.currentStep = 'investment-model';
            return returnStepName
              ? 'investment-model'
              : `case/iw/${this.profile}/investment-model`;
          }
        } else if (this.currentStep == 'investment-model') {
          this.currentStep = 'fund-allocations';
          return returnStepName
            ? 'fund-allocations'
            : `case/iw/${this.profile}/fund-allocations`;
        } else if (this.currentStep == 'fund-allocations') {
          this.currentStep = 'validate';
          return returnStepName
            ? 'validate'
            : `case/iw/${this.profile}/validate`;
        } else if (
          this.currentStep == 'advisory' ||
          this.currentStep == 'validate'
        ) {
          this.handleAppChange();
          return 'appChange';
        } else if (
          this.formModel['ValidateProduct'][
            'InvestmentWizard_AssessmentType'
          ] == 2
        ) {
          this.currentStep = 'validate';
          return returnStepName
            ? 'validate'
            : `case/iw/${this.profile}/validate`;
        } else {
          this.currentStep = 'program-selection';
          return returnStepName
            ? 'program-selection'
            : `case/iw/${this.profile}/program-selection`;
        }
      case 'li':
        const gotoAnalysis = this.formModel[
          'UnitNeeds_PurposesUsingCalc'
        ]?.includes(this.formModel['LifeWizard_InsurancePurpose']);
        const gotoStrategies = !this.formModel[
          'UnitNeeds_PurposesUsingCalc'
        ]?.includes(this.formModel['LifeWizard_InsurancePurpose']);

        if (this.currentStep == 'products' || this.currentStep == 'validate') {
          this.handleAppChange();
          return 'appChange';
        } else if (
          (this.formModel['LifeWizard_AssessmentType'] == 2 &&
            this.formModel['UnitRegulationState_States'].includes(
              this.formModel['LifeWizard_StateOfIssue']
            )) ||
          this.formModel['LifeWizard_PurchaseType'] == 2
        ) {
          this.currentStep = 'validate';
          return returnStepName
            ? 'validate'
            : `case/li/${this.profile}/validate`;
        } else if (this.currentStep == 'compare') {
          this.currentStep = 'products';
          return returnStepName
            ? 'products'
            : `case/li/${this.profile}/products`;
        } else if (this.currentStep == 'results') {
          this.currentStep = 'compare';
          return returnStepName ? 'compare' : `case/li/${this.profile}/compare`;
        } else if (gotoStrategies) {
          this.currentStep = 'compare';
          return returnStepName ? 'compare' : `case/li/${this.profile}/compare`;
        } else if (gotoAnalysis) {
          this.currentStep = 'results';
          return returnStepName ? 'results' : `case/li/${this.profile}/results`;
        } else {
          this.currentStep = 'results';
          return returnStepName ? 'results' : `case/li/${this.profile}/results`;
        }
    }
  }

  hideForm() {
    if (
      this.unfFlowSvc.getloading() ||
      this.isNaN(+this.currentStep) ||
      this.currentApp == 'summary'
    ) {
      return true;
    }
  }

  checkStatus() {
    return (
      (this.currentApp != 'summary' && !this.isNaN(+this.currentStep)) ||
      this.currentApp != 'compare-results'
    );
    // return this.currentApp != 'summary' && !this.isNaN(+this.currentStep);
  }

  handlePPProds(ev) {
    this.prodsSelectedPP = ev;
  }

  handleIWProg(ev) {
    this.progSelectedIW = ev;
  }

  handleSummaryNavigate(ev) {
    if (ev.app == 'back') {
      const timer =
        !this.isNaN(+this.profile) && this.profile.length > 7 ? 3000 : 0;
      setTimeout(_ => {
        this.location.back();
      }, timer);
    } else {
      this.currentApp = ev.app;
      this.currentStep = ev.step;

      this.router.navigate([
        `/case/${this.currentApp}/${this.profile}/${this.currentStep}`,
      ]);
      this.getQuestionnaire();
    }
  }

  setSummaryClient(e) {
    if (e) {
      const clientName =
        e.Client.Client.FinalName ||
        e.Client.ClientPerson.FirstName + ' ' + e.Client.ClientPerson.LastName;
      this.summaryClient = clientName.replace('undefined', '');
    }
  }

  setLoading(state) {
    this.unfFlowSvc.setLoading(state);
  }

  downloadFactsheet(e) {
    this.downloadingFactsheet = e;
  }

  saveInProgress() {
    const random = Math.floor(Math.random() * 10);
    this.unfFlowSvc.setTriggerSave({
      msg: 'inProgressSave-' + random,
      id: this.profile,
    });
  }

  handleFormChange(ev) {}
}
