import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { Application, Unit } from '../../../models/admin-models';
import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { ReportingService } from '../../reporting.service';
import { saveAs } from '@progress/kendo-file-saver';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-reason-text-report',
  templateUrl: './reason-text.component.html',
  styleUrls: ['./reason-text.component.scss'],
})
export class ReasonTextComponent implements OnInit {
  @Input() baseUrl;

  reasonUnitsControl = new FormControl();
  filteredUnits;
  selectedUnit;
  reason = { unit: null, app: null };
  applications: Application[] = [];
  units: Unit[] = [] as Unit[];
  loading = false;

  constructor(
    private admnSvc: AdminService,
    private pltfrm: Platform,
    private rptSvc: ReportingService,
    private ss: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.ss.get('globals')?.Modules.forEach(el => {
      switch (el.toLowerCase()) {
        case 'aw':
          this.applications.push({
            name: 'Annuity Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'iw':
          this.applications.push({
            name: 'Investment Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'li':
          this.applications.push({
            name: 'Life Insurance Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'pp':
          this.applications.push({
            name: 'Product Profiler',
            value: el.toLowerCase(),
          });
          break;
        case 'rb':
          this.applications.push({
            name: 'Control KB (RB)',
            value: el.toLowerCase(),
          });
          break;
        default:
          break;
      }
    });
    this.getReasonUnits();
  }

  getReasonText() {
    this.loading = true;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.rptSvc.getReasonText(this.reason.unit, this.reason.app).subscribe({
      next: data => {
        const filename = `${this.reason.unit}_ReasonTextReport.xlsx`;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          this.loading = false;
        };
      },
      error: error => {
        this.loading = false;
        console.error(error);
      },
    });
  }

  getReasonUnits() {
    this.reasonUnitsControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.admnSvc.getUnitList(value).subscribe(resp => {
            if (resp['names']) {
              this.filteredUnits = resp['names'];
            }
          });
        }
      });
  }

  checkSelection() {
    if (
      !this.filteredUnits ||
      !this.filteredUnits.find(unit => {
        return unit.id === this.reasonUnitsControl.value;
      })
    ) {
      this.reasonUnitsControl.setValue(null);
    }
  }
}
