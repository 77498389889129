import {
  map,
  switchMap,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Inject,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UniversalSearchService } from '../services/universal-search.service';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-hierarchy-modal',
  templateUrl: './hierarchy-modal.component.html',
  styleUrls: ['./hierarchy-modal.component.scss'],
})
export class HierarchyModalComponent implements OnInit {
  @Input() templateInData;
  @Input() isDialog = true;
  @Output() templateOutData = new EventEmitter();

  form;
  users: any[];
  filteredUsers;
  selectedUser;
  units: any[];
  filteredUnits = [];
  selectedUnit;
  repCodes: any[];
  filteredRepCodes;
  selectedRepCode;

  userSearch;
  unitSearch;
  repCodeSearch;

  unitCtrl = new FormControl();
  userCtrl = new FormControl();
  repCtrl = new FormControl();
  rights;
  currentApp: string;

  constructor(
    private dialogRef: MatDialogRef<HierarchyModalComponent>,
    private usServ: UniversalSearchService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ss: SessionStorageService
  ) {}

  ngOnInit() {
    this.rights = this.ss.get('rights');
    this.currentApp = this.ss.get('currentApp');

    if (!this.isDialog && this.data.saved && this.data.saved.hierarchyData) {
      if (this.data.saved.hierarchyData.unit) {
        this.unitCtrl.setValue(this.data.saved.hierarchyData.unit);
        this.selectedUnit = this.data.saved.hierarchyData.unit;
      }
      if (this.data.saved.hierarchyData.user) {
        this.userCtrl.setValue(this.data.saved.hierarchyData.user);
        this.selectedUser = this.data.saved.hierarchyData.user;
      }
      if (this.data.saved.hierarchyData.rep) {
        this.repCtrl.setValue(this.data.saved.hierarchyData.rep);
        this.selectedRepCode = this.data.saved.hierarchyData.rep;
      }
    } else if (this.isDialog && this.data.data) {
      this.unitCtrl.setValue(this.data.data.unit);
      this.selectedUnit = this.data.data.unit;
      this.userCtrl.setValue(this.data.data.user);
      this.selectedUser = this.data.data.user;
      this.repCtrl.setValue(this.data.data.rep);
      this.selectedRepCode = this.data.data.rep;
    }

    if (this.selectedUnit || this.selectUser || this.selectedRepCode) {
      const outData = {
        data: {
          unit: this.selectedUnit,
          user: this.selectedUser,
          rep: this.selectedRepCode,
        },
      };
      this.templateOutData.emit(outData);
    }

    this.getUnits();
    this.getUsers();
    this.getRepCodes();
  }

  getUsers() {
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(user => {
        if (user.length > 2) {
          return this._filterUsers(user);
        }
        if (!user || user.length === 0) {
          this.selectedUser = null;
        }
        return [];
      })
    );
  }

  selectUser(ev) {
    this.selectedUser = ev.option.value;
    const outData = {
      data: {
        unit: this.selectedUnit,
        user: this.selectedUser,
        rep: this.selectedRepCode,
      },
    };
    this.templateOutData.emit(outData);
  }

  getUnits() {
    this.unitCtrl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (value && !value.id) {
          this.usServ.unitSearch(value).subscribe(resp => {
            if (resp['names']) {
              this.filteredUnits = resp['names'];
            }
          });
        }
      });
  }

  checkSelection() {
    if (
      !this.filteredUnits ||
      !this.filteredUnits.find(unit => {
        return (
          unit.id.toLowerCase() === this.unitCtrl.value.id?.toLowerCase() ||
          this.unitCtrl.value.toLowerCase()
        );
      })
    ) {
      this.unitCtrl.setValue(null);
    }
  }

  selectUnit(ev) {
    this.selectedUnit = ev.option.value;
    const outData = {
      data: {
        unit: this.selectedUnit,
        user: this.selectedUser,
        rep: this.selectedRepCode,
      },
    };
    this.templateOutData.emit(outData);
  }

  getRepCodes() {
    this.filteredRepCodes = this.repCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(rep => {
        if (rep.length > 2) {
          return this._filterReps(rep);
        }
        if (!rep || rep.length === 0) {
          this.selectedRepCode = null;
        }
        return [];
      })
    );
  }

  selectRep(ev) {
    this.selectedRepCode = ev.option.value;
    const outData = {
      data: {
        unit: this.selectedUnit,
        user: this.selectedUser,
        rep: this.selectedRepCode,
      },
    };
    this.templateOutData.emit(outData);
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    const outData = {
      data: {
        unit: this.selectedUnit,
        user: this.selectedUser,
        rep: this.selectedRepCode,
      },
    };
    this.dialogRef.close(outData);
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
  }

  private _filterUnits(value: string) {
    const list = this.usServ.unitSearch(value).pipe(
      map(x => {
        return x.names;
      })
    );
    return list;
  }

  private _filterUsers(value: string) {
    const unit = this.selectedUnit ? this.selectedUnit.id : null;
    const list = this.usServ.userSearch(value, unit).pipe(
      map(x => {
        return x.names;
      })
    );
    return list;
  }

  private _filterReps(value: string) {
    const unit = this.selectedUnit ? this.selectedUnit.id : null;
    const list = this.usServ.repCodeSearch(value, unit).pipe(
      map(x => {
        return x.names;
      })
    );
    return list;
  }

  displayFn(val) {
    return val ? val.id : '';
  }

  displayReps(val) {
    return val ? `${val.repCode} ${val.name} (${val.unitName})` : '';
  }
}
