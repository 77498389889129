import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { RightBridgeApiService } from './right-bridge-api.service';
import { SessionStorageService } from './session-storage.service';
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { WarnDialogComponent } from '../warn-dialog/warn-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NavHistoryService } from './nav-history.service';

@Injectable()
export class AuthService {
  constructor(
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    public router: Router,
    private titleService: Title,
    private dialog: MatDialog,
    private navHistory: NavHistoryService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const session = this.ss.get('session') || null;
    const rights = this.ss.get('rights') || null;
    const rightNeeded = route.data.rightNeeded;
    const appNeeded = route.data.appNeeded;

    if (session) {
      this.rbs.querySession(session).pipe(
        map(res => {
          const auth = +res.results > 0;

          if (auth) {
            if (
              (rights.includes(rightNeeded) || !rightNeeded) &&
              (rights.includes(appNeeded) || !appNeeded)
            ) {
              this.checkApp(state.url);
              return true;
            } else {
              if (
                !this.navHistory.getHistory() ||
                this.navHistory.getHistory().length === 0
              ) {
                this.router.navigate([``]);
              }
              let navDialog = this.dialog.open(WarnDialogComponent, {
                minWidth: '35vw',
                maxWidth: '75vw',
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content:
                    'This account does not have the correct permissions to view this page. Please reach out to your administrator if you believe this is an error.',
                  confirm: 'OK',
                  hideCancel: true,
                },
              });
              navDialog.afterClosed().subscribe(() => {
                navDialog = null;
              });
              return false;
            }
          } else {
            this.rbs.logout();
            this.router.navigate([`/login`]);
            return false;
          }
        }),
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error');
        })
      );
    } else {
      this.router.navigate([`/login`]);
    }
  }

  checkApp(url) {
    const app = this.ss.get('currentApp');
    let newApp: string;

    const startPage = this.ss.get('startPage');

    if (
      url &&
      url.split('/')[1].toLowerCase() != 'dashboard' &&
      url.split('/')[1].toLowerCase() != 'reporting' &&
      url.split('/')[1].toLowerCase() != 'downloads' &&
      url.split('/')[1].toLowerCase() != 'admin' &&
      url.split('/')[1].toLowerCase() != 'account' &&
      url.split('/')[1].toLowerCase() != 'case' &&
      url.split('/')[1].toLowerCase() != 'search'
    ) {
      newApp = url.split('/')[1];
    } else if (startPage) {
      newApp = startPage;
    } else {
      newApp = app;
    }
    if (url && newApp != app) {
      this.ss.set('currentApp', newApp);
      this.rbs.setParams(newApp);
      switch (newApp) {
        case 'aw':
          this.titleService.setTitle('Annuity Wizard');
          break;
        case 'iw':
          this.titleService.setTitle('Investment Wizard');
          break;
        case 'pp':
          this.titleService.setTitle('Product Profiler');
          break;
        case 'cp':
          this.titleService.setTitle('Client Profiler');
          break;
        case 'li':
          this.titleService.setTitle('Life Wizard');
          break;
        case 'se':
          this.titleService.setTitle('Suggestion Engine');
          break;
        case 'rw':
          this.titleService.setTitle('Rollover Wizard');
          break;
      }
    }
  }
}
