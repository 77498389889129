import {
  Component,
  OnChanges,
  OnInit,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../dashboard.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import * as Highcharts from 'highcharts';
import highChartsMore from 'highcharts/highcharts-more';
import { DateHandlingService } from '../../../services/date-handling.service';

@Component({
  selector: 'app-cases-by-scoreclass',
  templateUrl: './casesByScoreclass.component.html',
  styleUrls: ['./casesByScoreclass.component.scss'],
})
export class CasesByScoreclassComponent implements OnInit, OnChanges {
  @Input() solutionId;

  highChartsMore: any = highChartsMore(Highcharts);
  Highcharts: typeof Highcharts = Highcharts;
  loading = false;
  caseData = {};
  chartData;
  solutions = [];
  solution;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private dateSvc: DateHandlingService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getScoreclassSummary();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.solutionId && changes.solutionId.previousValue !== undefined) {
      this.getScoreclassSummary();
    }
  }

  getScoreclassSummary() {
    this.loading = true;
    const { start, end } = this.dateSvc.thirtyDays();

    this.dbs.getScoreclassSummary(this.unit, start, end).subscribe(x => {
      const dataSet = x.Summary.find(
        z => z.Solution.toLowerCase() == this.solutionId.toLowerCase()
      );
      this.buildChart(dataSet);
      this.loading = false;
    });
  }

  viewCaseExplorer(ev) {
    const scoreClass = ev.point.name;
    this.router.navigate(['/case-explorer', { scoreClass: scoreClass }]);
  }

  buildChart(data) {
    const that = this;
    this.chartData = {
      chart: {
        type: 'packedbubble',
        height: '110%',
        // Edit chart spacing
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        // Explicitly tell the width and height of a chart
        // width: '100%',
        // height: '100%',
        backgroundColor: null,
      },
      title: {
        text: null,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
        useHTML: true,
        pointFormat: '<b>{point.name}:</b> {point.value}',
      },
      // pane: {
      //   size: '100%',
      //   startAngle: 0,
      //   endAngle: 360,
      //   background: [{ // Track for Move
      //     outerRadius: '100%',
      //     innerRadius: '85%',
      //     backgroundColor: '#e6e7e8',
      //     borderWidth: 0
      //   }]
      // },
      plotOptions: {
        packedbubble: {
          minSize: '100%',
          maxSize: '100%',
          zMin: 0,
          zMax: 1000,
          bubblePadding: -8,
          borderColor: 'white',
          layoutAlgorithm: {
            enableSimulation: true,
            gravitationalConstant: 0.5,
            splitSeries: false,
            seriesInteraction: true,
            dragBetweenSeries: false,
            parentNodeLimit: true,
          },
          stacking: true,
          dataLabels: {
            enabled: true,
            padding: 0,
            x: 0,
            y: 0,
            verticalAlign: 'middle',
            align: 'center',
            style: {
              color: 'white',
              textOutline: 'none',
              fontWeight: 'bold',
              fontSize: '20px',
              fontFamily: 'Roboto',
            },
            formatter: function () {
              return `<span style="text-align: center; width: 100%; font-size: 26px; display: inline-block; line-height: 17px;">${this.point.y}<br><span style="text-align: center; width: 100%; font-size: 14px; display: inline-block; font-weight: 400;">${this.series.name}</span></span>`;
            },
            useHTML: true,
          },
          marker: {
            fillOpacity: 1,
            lineColor: 'white',
            lineWidth: 4,
          },
          events: {
            click: function (event) {
              that.viewCaseExplorer(event);
            },
          },
          cursor: 'pointer',
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: 'Positive',
          color: '#89c540',
          data: [
            {
              name: 'Positive',
              value: data.Positive,
              color: '#89c540',
            },
          ],
        },
        {
          name: 'Neutral',
          color: '#ffcb21',
          data: [
            {
              name: 'Neutral',
              value: data.Neutral,
              color: '#ffcb21',
            },
          ],
        },
        {
          name: 'Negative',
          color: '#bf2025',
          data: [
            {
              name: 'Negative',
              value: data.Negative,
              color: '#bf2025',
            },
          ],
        },
      ],
    };
  }
}
