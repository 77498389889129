import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { InvestmentWizardService } from '../../../investment-wizard/services/investment-wizard-service.service';

@Component({
  selector: 'app-form-calculator',
  standalone: true,
  imports: [PercentPipe, CurrencyPipe],
  templateUrl: './form-calculator.component.html',
  styleUrl: './form-calculator.component.scss',
})
export class FormCalculatorComponent {
  @Input() field;
  @Input() mode;
  @Input() totalAmount;
  @Input() totalInvestment;
  @Input() totalPercent;

  makeNumber(num) {
    if (typeof num === 'number') {
      return num;
    } else {
      const formatted = num.replace('$', '').replaceAll(',', '');
      return +formatted;
    }
  }
}
