<div mat-dialog-header>
  @if (!data.createGroup) {
    <h1>Account Grouping <span>- Recommended Accounts</span></h1>
  } @else {
    <h1>Create Account Group</h1>
  }
</div>
<div mat-dialog-content class="dialog-container">
  @if (!loading) {
    @if (!data.createGroup) {
      @if (tableView) {
        <table class="details col-12">
          <thead>
            <tr>
              <th></th>
              <th>Account #</th>
              <th>Owner Name</th>
              <th>SSN/TIN</th>
              <th>Rep Code</th>
              <th>Group ID</th>
            </tr>
          </thead>
          <tbody>
            @for (account of recommendedAccounts; track account.ID) {
              <tr [class]="account.GroupID ? 'group-assigned' : ''">
                <td width="75">
                  <div class="action-button-container">
                    @if (!account.GroupID) {
                      <mat-checkbox
                        matTooltip="Select Account"
                        [checked]="selectedAccount === account.HashedAccountID"
                        [ngModelOptions]="{ standalone: true }"
                        (click)="selectAccount(account.HashedAccountID)"
                      ></mat-checkbox>
                    } @else {
                      <button
                        mat-icon-button
                        (click)="viewAccountGroup(account)"
                        color="primary"
                        matTooltip="View Account Group"
                      >
                        <mat-icon>preview</mat-icon>
                      </button>
                    }
                  </div>
                </td>
                <td>{{ account.AccountNumDisplay }}</td>
                <td>{{ account.OwnerNameFull }}</td>
                <td>{{ account.MaskedSSN }}</td>
                <td>{{ account.RepID }}</td>
                <td>{{ account.GroupID }}</td>
              </tr>
            }
          </tbody>
        </table>
      } @else if (!tableView && !data.initialStystemData) {
        <div>
          <form [formGroup]="accountGroupForm">
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label>Account #</mat-label>
                <input
                  matInput
                  [formControl]="HashedAccountIDControl"
                  [placeholder]="'Account #'"
                  (blur)="hashInput($event, 'HashedAccountID')"
                  (keyup)="valueHashed = false"
                />
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Owner First Name</mat-label>
                <input
                  matInput
                  [formControlName]="'OwnerFirstName'"
                  [placeholder]="'Owner First Name'"
                />
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Owner Last Name</mat-label>
                <input
                  matInput
                  [formControlName]="'OwnerLastName'"
                  [placeholder]="'Owner Last Name'"
                />
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>SSN/TIN</mat-label>
                <input
                  matInput
                  [formControl]="HashedSSNControl"
                  [placeholder]="'SSN/TIN'"
                  (blur)="hashInput($event, 'HashedSSN')"
                  (keyup)="valueHashed = false"
                />
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>ROA</mat-label>
                <input
                  matInput
                  [formControlName]="'ROANumber'"
                  [placeholder]="'ROA'"
                />
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Cumulative Discount #</mat-label>
                <input
                  matInput
                  [formControlName]="'CumulativeDiscountNum'"
                  [placeholder]="'Cumulative Discount #'"
                />
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Rep Code</mat-label>
                <input
                  type="text"
                  placeholder="Rep Code"
                  matInput
                  [matAutocomplete]="autoUnit"
                  [formControl]="typeaheadControl"
                  (blur)="onBlur($event)"
                />
                <mat-autocomplete
                  #autoUnit="matAutocomplete"
                  (optionSelected)="changeSelection($event)"
                >
                  @for (opt of tyepaheadOptions; track opt.value) {
                    <mat-option value="{{ opt.value }}">
                      {{ opt.value }}: {{ opt.display }}
                    </mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </form>
        </div>
      }
    } @else {
      <div>
        <form [formGroup]="accountGroupForm">
          <div class="row">
            <mat-form-field class="col-9">
              <mat-label>Description</mat-label>
              <input
                matInput
                [formControlName]="'description'"
                [placeholder]="'Description'"
                [required]="true"
                maxLength="50"
              />
              <mat-error>This field is required</mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Unit</mat-label>
              <input
                type="text"
                placeholder="Unit"
                matInput
                [matAutocomplete]="autoUnit"
                [formControl]="typeaheadControl"
              />
              <mat-autocomplete
                #autoUnit="matAutocomplete"
                (optionSelected)="changeSelection($event)"
              >
                @for (opt of tyepaheadOptions; track opt.value) {
                  <mat-option value="{{ opt.value }}">
                    {{ opt.value }}: {{ opt.display }}
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </form>
      </div>
    }
  }
</div>
<div mat-dialog-actions class="row buttons">
  <span>
    @if (!data.createGroup) {
      @if (tableView) {
        <button mat-stroked-button (click)="toggleView()">
          Refine Results
        </button>
      } @else {
        <button mat-raised-button color="accent" (click)="toggleView()">
          <mat-icon>search</mat-icon> View Results
        </button>
      }
    }
  </span>
  <span>
    <button mat-button color="warn" (click)="dialogRef.close('Cancel')">
      Cancel
    </button>
    @if (tableView && !data.createGroup) {
      <button
        mat-raised-button
        [disabled]="selectedAccount === ''"
        color="primary"
        (click)="linkAccount()"
      >
        <mat-icon>save</mat-icon> Link Account
      </button>
    }
    @if (data.createGroup) {
      <button
        mat-raised-button
        [disabled]="
          !accountGroupForm.value.description || !accountGroupForm.value.unit
        "
        color="primary"
        (click)="createAccountGroup()"
      >
        <mat-icon>save</mat-icon> Create Group
      </button>
    }
  </span>
</div>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
