<div class="app app-ai-summary">
  <div class="col-12 row">
    @if (title) {
      <div>
        <h4> AI Case Summary </h4>
      </div>
    }
    <div class="w-95 clearfix">
      <div class="clearfix">
        <div class="ai-summary-section">
          <div class="ai-summary-text" [innerHTML]="text"> </div>
        </div>
        <div class="ai-summary-footnote"
          >*Note: This summary was generated using AI.</div
        >
      </div>
    </div>
  </div>
</div>
