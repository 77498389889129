<div class="mb-3">
  <mat-form-field class="mr-3">
    <mat-label>Application</mat-label>
    <mat-select [(ngModel)]="selectedApp">
      @for (app of applications; track app.value) {
        <mat-option [value]="app.value">
          {{ app.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="mr-3">
    <mat-label>Alert ID</mat-label>
    <mat-select [(ngModel)]="alertId" [disabled]="!selectedApp">
      @for (alertId of alertIds; track alertId.AlertID) {
        @if (alertId.Module.toLowerCase() === selectedApp) {
          <mat-option [value]="alertId.AlertID">
            {{ alertId.AlertTitle }}
          </mat-option>
        }
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="mr-3">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start date" [(ngModel)]="startDate" />
      <input matEndDate placeholder="End date" [(ngModel)]="endDate" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <mat-form-field class="mr-3">
    <input
      type="text"
      placeholder="Search For a Unit"
      matInput
      [matAutocomplete]="auto"
      name="unitsControl"
      [formControl]="unitsControl"
      [(ngModel)]="selectedUnit"
      (blur)="checkSelection()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="autoCompleteUnitChanged($event)"
    >
      @for (unit of filteredUnits; track unit.id) {
        <mat-option [value]="unit.id">
          {{ unit.name }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

  <button
    mat-raised-button
    color="primary"
    class="mx-auto"
    (click)="getExceptions()"
    class="mr-3"
    [disabled]="
      !selectedUnit || !startDate || !endDate || !alertId || !selectedApp
    "
  >
    Get Exceptions
  </button>
</div>

<div class="row mb-3">
  <mat-accordion class="w-100">
    <mat-expansion-panel [expanded]="viewAlertIds">
      <mat-expansion-panel-header>
        <mat-panel-title> Alert ID Descriptions </mat-panel-title>
        <mat-panel-description>
          Click to view/hide the Alert ID descriptions
        </mat-panel-description>
      </mat-expansion-panel-header>
      <table class="mt-3 description-table">
        <colgroup>
          <col span="1" style="width: 20%" />
          <col span="1" style="width: 80%" />
        </colgroup>

        <thead>
          <th>Alert Title</th>
          <th>Description</th>
        </thead>
        <tbody>
          @if (selectedApp && hasAlerts()) {
            @for (alertId of alertIds; track alertId.AlertID) {
              @if (alertId.Module.toLowerCase() === this.selectedApp) {
                <tr>
                  <td>
                    <strong>
                      {{ alertId.AlertTitle }}
                    </strong>
                  </td>
                  <td>{{ alertId.AlertDescription }}</td>
                </tr>
              }
            }
          } @else {
            <tr>
              <td colspan="2" class="no-results">
                Select and application to see alerts
              </td>
            </tr>
          }
        </tbody>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="row">
  @if (exceptionsData.data) {
    <button
      mat-button
      color="primary"
      class="grid-button pull-right"
      (click)="exportToExcel(exceptionsGrid)"
    >
      <mat-icon>cloud_download</mat-icon>Download Excel File
    </button>
  }

  <kendo-grid
    #exceptionsGrid="kendoGrid"
    class="col-12 mt-2 load-history-grid"
    [kendoGridBinding]="exceptionsData.data"
  >
    <ng-template ngFor [ngForOf]="exceptionsData.headers" let-column>
      <kendo-grid-column [field]="column.DataField" [title]="column.Label">
        @if (column.DataField === 'Pct') {
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem[column.DataField] | percent }}
          </ng-template>
        }
      </kendo-grid-column>
    </ng-template>
    <ng-template kendoGridNoRecordsTemplate> No Results </ng-template>
    <kendo-grid-excel
      fileName="RightBridge-RepExceptionsReport.xlsx"
    ></kendo-grid-excel>
  </kendo-grid>
</div>
