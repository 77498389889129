import {
  Component,
  OnChanges,
  OnInit,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Location } from '@angular/common';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import { EnvironmentService } from '../../../services/environment.service';
import { RightBridgeApiService } from '../../../services/right-bridge-api.service';
import { DateHandlingService } from '../../../services/date-handling.service';

@Component({
  selector: 'app-top-5-summary',
  templateUrl: './top5Summary.component.html',
  styleUrls: ['./top5Summary.component.scss'],
})
export class Top5SummaryComponent implements OnInit, OnChanges {
  @Input() solutionId;

  private environment;

  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  gridData = { Headers: [], Data: null };
  solution;
  casesRan = [];
  classesChanged = [];
  rights = [];
  turnOffSummaryPage = false;

  constructor(
    private dbs: DashboardService,
    private dateSvc: DateHandlingService,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private router: Router,
    private envSvc: EnvironmentService,
    private location: Location
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.rights = this.ss.get('rights');
    this.getTop5();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.solutionId && changes.solutionId.previousValue !== undefined) {
      this.getTop5();
    }
  }

  dateChange() {
    this.getTop5();
  }

  getTop5() {
    const { start, end } = this.dateSvc.thirtyDays();
    this.dbs
      .getCaseStatsTop(this.unit, this.solutionId, start, end)
      .subscribe(x => {
        this.casesRan = x.results.MostCases;
        this.classesChanged = x.results.ScoreClassChanges;
      });

    this.rbs.getGroupProfile('DashboardGlobals').subscribe(x => {
      if (x.UnitInfo?.TurnOffSummaryPage) {
        this.turnOffSummaryPage = true;
      }
    });
  }

  viewUser(ev, id) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.router.navigate(['/case-explorer', { user: id.id }]);
  }

  viewCase(ev, data) {
    const { ProfileID, Solution } = data;
    const solution = Solution.toLowerCase();

    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if (this.turnOffSummaryPage) {
      if (solution === 'li') {
        window.open(
          `${this.location.prepareExternalUrl(
            ''
          )}case/${solution}/${ProfileID}/validate`,
          '_blank'
        );
      }
      window.open(
        `${this.location.prepareExternalUrl(
          ''
        )}case/${solution}/${ProfileID}/results`,
        '_blank'
      );
    } else {
      window.open(
        `${this.location.prepareExternalUrl('')}case/summary/${ProfileID}`,
        '_blank'
      );
    }
  }

  viewHistory(ev, data) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    window.open(
      `${this.environment.basePath}profile-history/${data.Solution}/${data.ProfileID}`,
      '_blank'
    );
  }
}
