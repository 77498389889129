<div class="page-container dashboard-container container">
  <div class="advertisement-banner">
    Announcing the new Report Request Portal (beta)!
    <button (click)="nav('/reporting')">Click here to learn more</button>
  </div>
  <div class="row w-100">
    <app-utility-toolbar
      [current]="app"
      [vertical]="true"
      class="w-15 pt-5 vertical"
    ></app-utility-toolbar>
    <div class="w-85 pt-5 pl-2">
      <div
        class="content row"
        [ngClass]="{
          'col-10 no-border': rights?.includes('SummaryWidgets'),
          'col-12 no-border': !rights?.includes('SummaryWidgets')
        }"
      >
        @if (rights?.includes('SummaryWidgets')) {
          <div class="col-12 row toolbar">
            <mat-form-field class="mb-4">
              <mat-label>Solution</mat-label>
              <mat-select [(ngModel)]="solution">
                @for (sltn of solutions; track sltn.value) {
                  <mat-option [value]="sltn.value">
                    {{ sltn.label }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <app-summary-charts
            class="col-md-8"
            [solutionId]="solution"
          ></app-summary-charts>
          <app-cases-by-scoreclass
            class="col-md-4"
            [solutionId]="solution"
          ></app-cases-by-scoreclass>
          <app-system-usage-summary
            class="col-12 mt-3"
          ></app-system-usage-summary>
          <app-top-5-summary
            class="col-12 mt-5"
            [solutionId]="solution"
          ></app-top-5-summary>
        }
        @if (
          !rights?.includes('SummaryWidgets') && rights?.includes('Launcher')
        ) {
          <legacy-app-links class="w-100"></legacy-app-links>
        }
        @if (rights?.includes('CaseStatsWidget')) {
          <app-case-stats class="col-12"></app-case-stats>
        }
        @if (rights?.includes('FundCompareReport')) {
          <app-fund-comparison class="col-12"></app-fund-comparison>
        }
        @if (rights?.includes('HighActivity')) {
          <app-high-activity-stats class="col-12"></app-high-activity-stats>
        }
        @if (rights?.includes('UnitProductCaseStats')) {
          <app-unit-product-stats class="col-12"></app-unit-product-stats>
        }
        @if (
          rights?.includes('LWRecentActivityWidget') &&
          rights?.includes('LWSummaryWidget')
        ) {
          <app-lifeStats class="col-12"></app-lifeStats>
        }
        @if (rights?.includes('UsageWidget')) {
          <app-system-usage class="col-sm-12"></app-system-usage>
        }
        @if (rights?.includes('opportunitiesOfTheDayWidget')) {
          <app-top5 class="col-sm-12 col-md-6"></app-top5>
        }
        @if (rights?.includes('TopOpportunitiesWidget')) {
          <app-topOpps class="col-sm-12 col-md-6 col-xl-4"></app-topOpps>
        }
        @if (rights?.includes('TimeSensitiveWidget')) {
          <app-timeSensitive class="col-sm-12 col-md-6"></app-timeSensitive>
        }
        @if (rights?.includes('FollowupWidget')) {
          <app-followUp class="col-sm-12 col-md-6"></app-followUp>
        }
        @if (rights?.includes('ClientStatisticsWidget')) {
          <app-scorecard class="col-sm-12 col-md-6 col-xl-4"></app-scorecard>
        }
        @if (rights?.includes('TopBridgesWidget')) {
          <app-topBridges class="col-sm-12 col-md-6"></app-topBridges>
        }
        @if (rights?.includes('AWDB-FixedRates')) {
          <app-annuities class="col-sm-12 col-md-12 col-xl-12"></app-annuities>
          <app-fixed-annuities-active-rates
            class="col-sm-12 col-md-12 col-xl-12"
          ></app-fixed-annuities-active-rates>
          <app-fia-rates class="col-sm-12 col-md-12 col-xl-12"></app-fia-rates>
          <app-fiaactiverates
            class="col-sm-12 col-md-12 col-xl-12"
          ></app-fiaactiverates>
          <app-via-rates class="col-sm-12 col-md-12 col-xl-12"></app-via-rates>
          <app-via-active-rates
            class="col-sm-12 col-md-12 col-xl-12"
          ></app-via-active-rates>
          <app-ppfa class="col-sm-12 col-md-12 col-xl-12"></app-ppfa>
          <app-activeppfa
            class="col-sm-12 col-md-12 col-xl-12"
          ></app-activeppfa>
          <app-annuities-by-connector
            class="col-sm-12 col-md-12 col-xl-12"
          ></app-annuities-by-connector>
          <app-living-benefits
            class="col-sm-12 col-md-12 col-xl-12"
          ></app-living-benefits>
          <app-death-benefits
            class="col-sm-12 col-md-12 col-xl-12"
          ></app-death-benefits>
        }
        @if (rights?.includes('Upload1040')) {
          <app-uploader class="col-sm-12 col-md-6 col-xl-4"></app-uploader>
        }
        <div class="messages-container">
          @if (hasMessages(1)) {
            <app-messages
              [message]="msgContent[0]"
              class="col-sm-12 col-md-6 col-xl-4"
            ></app-messages>
          }
          @if (hasMessages(2)) {
            <app-messages
              [message]="msgContent[1]"
              class="col-sm-12 col-md-6 col-xl-4"
            ></app-messages>
          }
          @if (hasMessages(3)) {
            <app-messages
              [message]="msgContent[2]"
              class="col-sm-12 col-md-6 col-xl-4"
            ></app-messages>
          }
          @if (hasMessages(4)) {
            <app-messages
              [message]="msgContent[3]"
              class="col-sm-12 col-md-6 col-xl-4"
            ></app-messages>
          }
          @if (hasMessages(5)) {
            <app-messages
              [message]="msgContent[4]"
              class="col-sm-12 col-md-6 col-xl-4"
            ></app-messages>
          }
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
