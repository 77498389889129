<div mat-dialog-header>
  <h1>{{ data.name }}</h1>
  <mat-toolbar color="primary">
    <span>{{ data.header }}</span>
  </mat-toolbar>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <div class="row compare-container col-12">
    @for (t of tables; track t.header) {
      @if (t.header && t.header.title) {
        <h1 class="table-header">{{ t.header.title }}</h1>
      }
      @if (t.header && t.header.bullets) {
        <ul class="header-details">
          @for (z of t.header.bullets; track z) {
            <li>{{ z }}</li>
          }
        </ul>
      }
      <kendo-grid
        #grid
        [kendoGridBinding]="t.gridData"
        [sortable]="t.type === 'horz'"
        class="compare-table w-100"
      >
        @if (t.type !== 'horz') {
          <kendo-grid-column
            field="metric"
            title=""
            width="150"
            [locked]="true"
          ></kendo-grid-column>
          <kendo-grid-column
            *ngFor="let column of t.columns"
            field="{{ column.id }}"
            title="{{ column.name }}"
            class="percent-column"
            [width]="450"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.id]"></span>
            </ng-template>
          </kendo-grid-column>
        }
        @if (t.type === 'horz') {
          <kendo-grid-column
            *ngFor="let column of t.columns"
            field="{{ column.id }}"
            title="{{ column.name }}"
            class="percent-column"
            [width]="column.id === 'name' ? 250 : 150"
            [locked]="column.id === 'name'"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.id]"></span>
            </ng-template>
          </kendo-grid-column>
        }
      </kendo-grid>
      @if (t.footer && t.footer.footnotes) {
        @for (z of t.footer.footnotes; track z) {
          <div [innerHTML]="z"></div>
        }
      }
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  @if (data.iwProductShelf) {
    <button
      mat-stroked-button
      color="primary"
      (click)="downloadComparisonPdf()"
    >
      <mat-icon>download</mat-icon>Download Comparison
    </button>
  }
  <button mat-raised-button (click)="dialogRef.close('Cancel')">Close</button>
</mat-dialog-actions>
<ngx-loading
  [show]="loading"
  [config]="{
    fullScreenBackdrop: false,
    backdropBackgroundColour: 'rgba(0,0,0,.5)'
  }"
></ngx-loading>
