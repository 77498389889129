import { Injectable } from '@angular/core';
import { PercentPipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CustomFiltersService {
  constructor(
    private http: HttpClient,
    private percPipe: PercentPipe,
    private decPipe: DecimalPipe,
    private currPipe: CurrencyPipe
  ) {}

  private updatePresets = new Subject();
  private updateDropdowns = new Subject();
  updatePresets$ = this.updatePresets.asObservable();
  updateDropdowns$ = this.updateDropdowns.asObservable();

  updatePresetDateValues(values) {
    this.updatePresets.next(values);
  }

  updateDropdownValues(values) {
    this.updateDropdowns.next(values);
  }
  updateValueRanges(values) {
    this.updatePresets.next(values);
  }

  buildColumnFilterOptions(gridData, headers) {
    const output = {};
    const keys = gridData && gridData[0] ? Object.keys(gridData[0]) : [];

    keys.forEach(key => {
      let col = gridData.map(row => row[key]);
      col = [...new Set(col)];
      col = col.sort((a, b) => (a < b ? -1 : 1));
      col = col.map(x => {
        if (x || (!x && (typeof x === 'boolean' || x === 0))) {
          let displayValue;
          const header = headers.find(z => z.DataField === key);
          if (header && header.Type) {
            switch (header.Type) {
              case 'perc':
                if (x == -9.99 || x == -999) {
                  displayValue = 'N/A';
                  x = 'N/A';
                } else if (x == 'N/A' || x == 'Uncapped') {
                  displayValue = x;
                } else {
                  displayValue = this.percPipe.transform(x);
                }
                break;

              case 'perc2':
                if (x == -9.99 || x == -999) {
                  displayValue = 'N/A';
                  x = 'N/A';
                } else if (x == 'N/A' || x == 'Uncapped') {
                  displayValue = x;
                } else {
                  displayValue = this.percPipe.transform(x, '1.2');
                }
                break;

              case 'perc3':
                if (x == -9.99 || x == -999) {
                  displayValue = 'N/A';
                  x = 'N/A';
                } else if (x == 'N/A' || x == 'Uncapped') {
                  displayValue = x;
                } else {
                  displayValue = this.percPipe.transform(x, '1.3');
                }
                break;

              case 'integer':
                displayValue = this.decPipe.transform(x, '1.0-0');
                break;

              case 'curr':
                displayValue = this.currPipe.transform(x);
                break;

              case 'rateLock':
                if (x.toLowerCase() == 'f') {
                  displayValue = 'Full Term';
                } else if (x.toLowerCase() == 'a') {
                  displayValue = 'Annual';
                } else if (x.toLowerCase() == 's') {
                  displayValue = 'Semi-annual';
                } else if (x.toLowerCase() == 'q') {
                  displayValue = 'Quarterly';
                } else if (x.toLowerCase() == 'm') {
                  displayValue = 'Monthly';
                } else if (x.toLowerCase() == 'd') {
                  displayValue = 'Daily';
                }
                break;

              case 'bool':
                if (x) {
                  displayValue = 'Yes';
                } else if (!x && typeof x == 'boolean') {
                  displayValue = 'No';
                }
                break;

              default:
                displayValue = x;
                break;
            }
          } else {
            displayValue = x;
          }

          return { display: displayValue, value: x };
        }
      });
      col = col.filter(x => {
        if (
          (x && x.value) ||
          (x && !x.value && (typeof x.value === 'boolean' || x.value === 0))
        ) {
          return true;
        }
      });

      output[key] = col;
    });
    return output;
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  calculatePresetRange(filter, queryData) {
    let calculatedStartDate;

    const formatStartDate = () =>
      DateTime.fromJSDate(new Date(calculatedStartDate)).toUTC().toISO();

    const endDate = DateTime.fromJSDate(new Date()).toUTC().toISO();

    if (filter['value'] === 'mtd') {
      // Set the date picker with the selected times
      calculatedStartDate = DateTime.now().startOf('month').toLocaleString();
      queryData.search[filter['field']]['start'] = formatStartDate();
      queryData.search[filter['field']]['end'] = endDate;
    } else if (filter['value'] === 'ytd') {
      // Set the date picker with the selected times
      calculatedStartDate = DateTime.now().startOf('year').toLocaleString();
      queryData.search[filter['field']]['start'] = formatStartDate();
      queryData.search[filter['field']]['end'] = endDate;
    } else if (filter['value'] === '30 days' || filter['value'] === '90 days') {
      // Calculate time difference
      const calculateTime = filter['value'].split(' ')[0];
      const calculateUnit = filter['value'].split(' ')[1];
      const timeDiffObject = {};
      timeDiffObject[calculateUnit] = calculateTime;
      calculatedStartDate = DateTime.now()
        .minus(timeDiffObject)
        .toLocaleString();

      // Set the date picker with the selected times
      queryData.search[filter['field']]['start'] = formatStartDate();
      queryData.search[filter['field']]['end'] = endDate;
    } else if (filter['value'] === 'custom' && filter['customRange']) {
      queryData.search[filter['field']]['start'] =
        filter['customRange']['start'];
      queryData.search[filter['field']]['end'] = filter['customRange']['end'];
      this.updatePresetDateValues(filter);
    }
  }
}
