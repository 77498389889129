import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { Application, Unit } from '../../../models/admin-models';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { saveAs } from '@progress/kendo-file-saver';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-unit-settings',
  templateUrl: './unit-settings.component.html',
  styleUrls: ['./unit-settings.component.scss'],
})
export class UnitSettingsComponent implements OnInit {
  @Input() baseUrl;

  unitsControl = new FormControl();
  applications: Application[] = [];
  units: Unit[] = [] as Unit[];
  filteredUnits = [];
  selectedUnit;
  selectedApp;
  reportTypes: string[];
  selectedType;
  loading = false;

  constructor(
    private admnSvc: AdminService,
    private pltfrm: Platform,
    private ss: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.ss.get('globals')?.Modules.forEach(el => {
      switch (el.toLowerCase()) {
        case 'aw':
          this.applications.push({
            name: 'Annuity Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'iw':
          this.applications.push({
            name: 'Investment Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'li':
          this.applications.push({
            name: 'Life Insurance Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'pp':
          this.applications.push({
            name: 'Product Profiler',
            value: el.toLowerCase(),
          });
          break;
        case 'rb':
          this.applications.push({
            name: 'Control KB (RB)',
            value: el.toLowerCase(),
          });
          break;
        default:
          break;
      }
    });
    this.getUnits();
  }

  getUnits() {
    this.unitsControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.admnSvc.getUnitList(value, true).subscribe(resp => {
            if (resp['names']) {
              this.filteredUnits = resp['names'];
            }
          });
        }
      });
  }

  checkSelection() {
    if (
      !this.filteredUnits ||
      !this.filteredUnits.find(unit => {
        return unit.id.toLowerCase() === this.unitsControl.value.toLowerCase();
      })
    ) {
      this.unitsControl.setValue(null);
    }
  }

  getUnitProfileReport() {
    this.loading = true;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.admnSvc
      .getUnitProfileReport(
        this.selectedUnit,
        this.selectedApp,
        this.selectedType
      )
      .subscribe(data => {
        const filename = `${this.selectedUnit}_${this.selectedApp}_${this.selectedType}_report.pdf`;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
        };
        this.loading = false;
      });
  }

  onAppChange() {
    this.admnSvc.getReportTypes(this.selectedApp).subscribe(data => {
      this.reportTypes = data['reportTypes'];
      this.selectedType = this.reportTypes[0];
    });
  }

  autoCompleteUnitChanged(ev) {
    this.unitsControl.setValue(ev.option.value);
  }
}
