<div class="page-container trade-review-grid-container">
  <h1 class="header col-12 mb-2">
    Transaction Monitor
    <button
      mat-button
      color="primary"
      class="grid-button header-button"
      (click)="downloadGridReport()"
    >
      <mat-icon>cloud_download</mat-icon>Download Grid Data
    </button>
  </h1>
  @if (!hideGrid) {
    <div class="row mb-0 mx-1">
      <app-grid-filters
        class="col-12"
        [gridName]="'TradeReviewGrid'"
        (filtersUpdated)="updateFilters($event)"
        [filters]="externalFilters"
        [reset]="resetFilters"
        [showFilterManager]="true"
        [manualSearch]="true"
        (triggerSearch)="getGridData()"
        [searchButtonText]="'Update Search'"
      ></app-grid-filters>
    </div>
    <div class="row mb-0 mx-1 grid-controls">
      <span class="approval-container">
        <span class="mr-3">
          <mat-checkbox
            (change)="bulkSelect($event)"
            [checked]="selectAllCheck()"
          >
            Select All
          </mat-checkbox>
        </span>
        <mat-form-field class="mr-2">
          <mat-label>Bulk Action</mat-label>
          <mat-select [formControl]="bulkActionControl">
            @for (option of bulkActionOptions; track option.value) {
              <mat-option value="{{ option.value }}">
                {{ option.display }}
              </mat-option>
            }
          </mat-select>
          @if (bulkActionControl.value) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              color="warn"
              matTooltip="Clear Action"
              (click)="resetBulkAction($event)"
            >
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!bulkActionControl.value || selectedTradeList.length <= 0"
          (click)="executeBulkAction()"
        >
          Execute
        </button>
      </span>

      <app-grid-column-filter
        [columns]="gridData.headers"
        (columnsUpdated)="externalColumnsUpdated($event)"
        class="ml-auto mr-2"
      ></app-grid-column-filter>

      <button mat-button color="primary" class="mr-0" (click)="reset()">
        <mat-icon class="mr-2">replay</mat-icon>Reset
      </button>
    </div>

    <kendo-grid
      class="col-12"
      #postTradeGrid="kendoGrid"
      [data]="gridData"
      [skip]="state.skip * 1"
      [pageSize]="state.take"
      [pageable]="true"
      [sortable]="{ allowUnsort: true, mode: 'multiple' }"
      [sort]="state.sort"
      [filterable]="'menu'"
      [filter]="state.filter"
      [columnMenu]="{ columnChooser: false }"
      [state]="state"
      (dataStateChange)="dataStateChange($event)"
      [height]="gridHeight()"
    >
      <kendo-grid-column width="125" title="Actions">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="action-buttons-container">
            <mat-checkbox
              matTooltip="Select Trade"
              (click)="toggleTradeSelect(dataItem)"
              [checked]="selectedChecked[dataItem.TradeID]"
            >
            </mat-checkbox>
            <button
              mat-icon-button
              class="fs-button"
              (click)="viewTrade(dataItem)"
              color="primary"
              matTooltip="View Trade"
            >
              <mat-icon>preview</mat-icon>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>
      <ng-template ngFor [ngForOf]="gridData.headers" let-column>
        <kendo-grid-column
          [field]="column.DataField"
          [hidden]="isHidden(column.DataField)"
          [title]="column.Label"
          [sortable]="column.Sort"
          [groupable]="column.Group"
          [media]="column.RespVis"
          [width]="column.Width ? column.Width : 150"
          [filterable]="column.Filter"
          [sticky]="column.Sticky"
        >
          @if (column.Sort || column.Filter) {
            <ng-template kendoGridColumnMenuTemplate let-service="service">
              @if (column.Sort) {
                <kendo-grid-columnmenu-sort
                  [column]="column"
                  [service]="service"
                >
                  <kendo-grid-messages
                    [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                    [sortDescending]="getFilterMenuText(column.Type, 'desc')"
                  ></kendo-grid-messages>
                </kendo-grid-columnmenu-sort>
              }
              @if (column.Filter) {
                <kendo-grid-columnmenu-filter
                  [column]="column"
                  [service]="service"
                >
                  <ng-template
                    kendoGridFilterMenuTemplate
                    let-filter="filter"
                    let-filterService="filterService"
                  >
                    <kendo-multiselect
                      style="width: 99%"
                      [data]="filterData[column.DataField]"
                      [filterable]="true"
                      (filterChange)="onFilterChange($event, column.DataField)"
                      textField="display"
                      valueField="value"
                      [valuePrimitive]="true"
                      [value]="filter | filterValues"
                      (valueChange)="
                        filterValueChange(
                          $event,
                          column.DataField,
                          filterService
                        )
                      "
                      [fillMode]="'outline'"
                      placeholder="Filter By..."
                    ></kendo-multiselect>
                  </ng-template>
                </kendo-grid-columnmenu-filter>
              }
            </ng-template>
          }
          @if (column.Type === 'integer') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | number: '1.0-0' }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | number: '1.0-0' }}
            </ng-template>
          } @else if (column.Type === 'perc') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | percent }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | percent }}
            </ng-template>
          } @else if (column.Type === 'perc2') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | percent: '1.2' }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | percent: '1.2' }}
            </ng-template>
          } @else if (column.Type === 'curr') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | currency }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | currency }}
            </ng-template>
          } @else if (column.Type === 'date') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | date: 'short' }}
            </ng-template>
          } @else if (column.DataField === 'notes') {
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.DataField]"></span>
            </ng-template>
          } @else if (column.DataField === 'ScoreClass') {
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="scoreclass-container">
                <mat-icon class="ml-0" [class]="dataItem.ScoreClass">
                  {{ scoreClassKey[dataItem.ScoreClass] }}
                </mat-icon>
              </div>
            </ng-template>
          } @else if (column.DataField === 'AlertIDs') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ alertListDisplay(dataItem[column.DataField]) }}
            </ng-template>
          } @else if (column.Validation) {
            <ng-template kendoGridCellTemplate let-dataItem>
              <span
                [innerHtml]="displayValue(column, dataItem[column.DataField])"
              ></span>
            </ng-template>
          }
        </kendo-grid-column>
      </ng-template>
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-currentPage="currentPage"
      >
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="buttonCount"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes
          [pageSizes]="pageSizeOptions"
        ></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>
  }

  <ngx-loading [show]="loading"></ngx-loading>
</div>
