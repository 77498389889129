import { Component, Input } from '@angular/core';
import { Global } from '../models/global.model';

@Component({
  selector: 'app-ai-summary',
  standalone: true,
  imports: [],
  templateUrl: './ai-summary.component.html',
  styleUrl: './ai-summary.component.scss',
})
export class AiSummaryComponent {
  @Input() title = false;
  @Input() text = '';
  aiBtnExpand = false;
  aiSummaryBtnText = 'Generate';
  globals: Global;
  loading = false;
  profile;
  rights: string[];
}
