import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

import { AnnuityLookupModule } from '../annuity-lookup/annuity-lookup.module';
import { RixtremaModule } from '../rixtrema/rixtrema.module';
import { FundFeeLookupModule } from '../fund-fee-lookup/fund-fee-lookup.module';

import { AdminService } from '../admin/admin.service';

import { MatStepperModule } from '@angular/material/stepper';
import {
  CdkDrag,
  CdkDropList,
  CdkDragPlaceholder,
  CdkDragPreview,
} from '@angular/cdk/drag-drop';

import { FormlyComponent } from './formly.component';
import { FormlyCustomCurrencyComponent } from './components/form-currency/form-currency.component';
import { FormlyCustomCurrencyExpComponent } from './components/form-currencyExp/form-currencyExp.component';
import { FormlyCustomInputComponent } from './components/form-input/form-input.component';
import { FormlyCustomInputExp } from './components/form-inputExp/form-inputExp.component';
import { FormlyRadioExpComponent } from './components/form-radioExp/form-radioExp.component';
import { FormlyRadioComponent } from './components/form-radio/form-radio.component';
import { FormlyIntegerExp } from './components/form-integerExp/form-integerExp.component';
import { FormlyNumberExp } from './components/form-numberExp/form-numberExp.component';
import { FormlyMultiSelect } from './components/form-multi/form-multiselect.component';
import { FormlyPercentageComponent } from './components/form-percentage/form-percentage.component';
import { FormlyDropExpComponent } from './components/form-dropExp/form-dropExp.component';
import { FormlyDropComponent } from './components/form-drop/form-drop.component';
import { FormlyMessage } from './components/form-message/form-message.component';
import { FormlySectionHeader } from './components/form-sectionHeader/form-sectionHeader.component';
import { FormlyToggle } from './components/form-toggle/form-toggle.component';
import { FormlyCheckbox } from './components/form-checkbox/form-checkbox.component';
import { FormlyCustomDateComponent } from './components/form-date/form-date.component';
import { FormlyRepeatingComponent } from './components/form-repeating/form-repeating.component';
import { PanelWrapperComponent } from './components/panel/panel-wrapper.component';
import { FormlyButtonComponent } from './components/form-button/form-button.component';
import { FormlyYesNoComponent } from './components/form-yesno/form-yesno.component';
import { FormlyTextArea } from './components/form-textarea/form-textarea.component';
import { FormlyPercentageExpComponent } from './components/form-percentageExp/form-percentageExp.component';
import { FormlyNumberComponent } from './components/form-number/form-number.component';
import { FormlyRadioValidation } from './components/form-radioValidation/form-radioValidation.component';
import { FormlyHashComponent } from './components/form-hash/form-hash.component';
import { FormlyInsuredSwap } from './components/form-insuredSwap/form-insuredSwap.component';
import { FormlyRepCodeComponent } from './components/form-repCode/form-repCode.component';
import { FormlyStatus } from './components/form-status/form-status.component';
import { FormlyDataGridComponent } from './components/form-dataGrid/form-dataGrid.component';
import { FormlyGridExpandComponent } from './components/form-gridExpand/form-gridExpand.component';
import { FormlyStepper } from './components/stepper/stepper.component';
import { FormlyCompactRepeat } from './components/form-compactRepeat/form-compactRepeat.component';
import { FormlyRankOrderComponent } from './components/form-rankOrder/form-rankOrder.component';
import { FormlyGridComponent } from './components/form-grid/form-grid.component';
import { FormlyTabs } from './components/tabs/tabs.component';
import { FormlyTypeAheadComponent } from './components/form-typeAhead/form-typeAhead.component';
import { FormlyRadioValidationExp } from './components/form-radioValidationExp/form-radioValidationExp.component';

// import { FundSearchComponent } from '../shared-components/fund-search/fund-search.component';
import { TemplatePickerComponent } from '../template-picker/template-picker.component';
import { UnifiedShellComponent } from './components/unified-shell/unified-shell.component';

import { MaskitoDirective } from '@maskito/angular';
import { FiveTwoNinePickerComponent } from './components/five-two-nine-picker/five-two-nine-picker.component';
import { FormlyCustomDateCalcComponent } from './components/form-dateCalc/form-dateCalc.component';
import { FormCalculatorComponent } from './components/form-calculator/form-calculator.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      extras: {
        // checkExpressionOn: 'changeDetectionCheck',
        resetFieldOnHide: false,
      },
      validationMessages: [
        { name: 'required', message: 'This field is required.' },
        {
          name: 'regRequired',
          message:
            'This field is needed for suitability review. You can opt out below if the applicant refuses to provide the information.',
        },
      ],
      types: [
        {
          name: 'formly-group',
          defaultOptions: {
            defaultValue: {},
          },
        },
        {
          name: 'formly-array',
          defaultOptions: {
            defaultValue: [],
          },
        },
        { name: 'custom-currency', component: FormlyCustomCurrencyComponent },
        {
          name: 'custom-currency-exp',
          component: FormlyCustomCurrencyExpComponent,
        },
        { name: 'custom-input', component: FormlyCustomInputComponent },
        { name: 'custom-input-exp', component: FormlyCustomInputExp },
        { name: 'custom-radio-exp', component: FormlyRadioExpComponent },
        { name: 'custom-radio', component: FormlyRadioComponent },
        { name: 'custom-integer-exp', component: FormlyIntegerExp },
        { name: 'custom-number-exp', component: FormlyNumberExp },
        { name: 'custom-number', component: FormlyNumberComponent },
        { name: 'custom-multi-select', component: FormlyMultiSelect },
        { name: 'custom-percentage', component: FormlyPercentageComponent },
        {
          name: 'custom-percentage-exp',
          component: FormlyPercentageExpComponent,
        },
        { name: 'custom-drop-exp', component: FormlyDropExpComponent },
        { name: 'custom-drop', component: FormlyDropComponent },
        { name: 'custom-toggle', component: FormlyToggle },
        { name: 'custom-date', component: FormlyCustomDateComponent },
        { name: 'custom-date-calc', component: FormlyCustomDateCalcComponent },
        { name: 'custom-checkbox', component: FormlyCheckbox },
        { name: 'custom-sectionHeader', component: FormlySectionHeader },
        {
          name: 'custom-repeating',
          component: FormlyRepeatingComponent,
          wrappers: ['panel'],
          defaultOptions: {
            defaultValue: [],
          },
        },
        { name: 'custom-message', component: FormlyMessage },
        { name: 'custom-button', component: FormlyButtonComponent },
        { name: 'custom-yesno', component: FormlyYesNoComponent },
        { name: 'custom-textarea', component: FormlyTextArea },
        { name: 'custom-radio-validate', component: FormlyRadioValidation },
        {
          name: 'custom-radio-validate-exp',
          component: FormlyRadioValidationExp,
        },
        { name: 'custom-hash', component: FormlyHashComponent },
        { name: 'custom-insuredSwap', component: FormlyInsuredSwap },
        { name: 'custom-repCode', component: FormlyRepCodeComponent },
        { name: 'custom-status', component: FormlyStatus },
        {
          name: 'custom-dataGrid',
          component: FormlyDataGridComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'custom-gridExpand',
          component: FormlyGridExpandComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'custom-grid',
          component: FormlyGridComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'custom-rankorder',
          component: FormlyRankOrderComponent,
        },
        {
          name: 'stepper',
          component: FormlyStepper,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'tabs',
          component: FormlyTabs,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'custom-type-ahead',
          component: FormlyTypeAheadComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        { name: 'unified', component: UnifiedShellComponent },
        {
          name: 'custom-compactRepeat',
          component: FormlyCompactRepeat,
          defaultOptions: {
            defaultValue: [],
          },
        },
      ],
      wrappers: [{ name: 'panel', component: PanelWrapperComponent }],
    }),
    FormlyMaterialModule,
    FormsModule,
    NgxLoadingModule,
    SharedComponentsModule,
    AnnuityLookupModule,
    RixtremaModule,
    MatStepperModule,
    FundFeeLookupModule,
    MaskitoDirective,
    CdkDrag,
    CdkDropList,
    CdkDragPlaceholder,
    CdkDragPreview,
    FormCalculatorComponent,
  ],
  declarations: [
    FormlyComponent,
    FormlyCheckbox,
    FormlyCustomCurrencyComponent,
    FormlyCustomCurrencyExpComponent,
    FormlyCustomDateComponent,
    FormlyCustomDateCalcComponent,
    FormlyCustomInputComponent,
    FormlyCustomInputExp,
    FormlyDropComponent,
    FormlyDropExpComponent,
    FormlyMessage,
    FormlyMultiSelect,
    FormlyIntegerExp,
    FormlyNumberExp,
    FormlyPercentageComponent,
    FormlyPercentageExpComponent,
    FormlyRadioComponent,
    FormlyRadioExpComponent,
    FormlyRepeatingComponent,
    FormlySectionHeader,
    FormlyToggle,
    PanelWrapperComponent,
    FormlyButtonComponent,
    FormlyYesNoComponent,
    FormlyTextArea,
    FormlyNumberComponent,
    FormlyRadioValidation,
    FormlyHashComponent,
    FormlyInsuredSwap,
    FormlyRepCodeComponent,
    FormlyStatus,
    FormlyDataGridComponent,
    FormlyGridExpandComponent,
    FormlyGridComponent,
    FormlyRankOrderComponent,
    FormlyStepper,
    FormlyTabs,
    FormlyTypeAheadComponent,
    FormlyCompactRepeat,
    TemplatePickerComponent,
    UnifiedShellComponent,
    FiveTwoNinePickerComponent,
    FormlyRadioValidationExp,
  ],
  exports: [FormlyComponent],
  providers: [
    AdminService,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    CdkDragPreview,
  ],
})
export class ConditionalFormModule {}
