import { Component, OnInit } from '@angular/core';
import { SuggestionEngineService } from '../suggestion-engine/suggestion-engine.service';
import { SessionStorageService } from '../services/session-storage.service';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  msgContent;
  app = this.ss.get('currentApp');
  solutions = [{ label: 'All', value: 'ALL' }];
  solution = 'ALL';
  unit = this.ss.get('firm');
  startDate = DateTime.now().minus({ days: 30 }).toUTC().toISO();
  endDate = DateTime.now().toUTC().toISO();
  rights = this.ss.get('rights');
  loading = false;

  constructor(
    private ses: SuggestionEngineService,
    private ss: SessionStorageService,
    private route: Router
  ) {}

  ngOnInit() {
    this.ses.getMessages().subscribe(msgs => {
      this.msgContent = msgs.messages;
    });

    this.ss.get('globals')?.Modules.forEach(el => {
      switch (el.toLowerCase()) {
        case 'aw':
          this.solutions.push({
            label: 'Annuity Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'iw':
          this.solutions.push({
            label: 'Investment Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'li':
          this.solutions.push({
            label: 'Life Insurance Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'pp':
          this.solutions.push({
            label: 'Product Profiler',
            value: el.toLowerCase(),
          });
          break;
        default:
          break;
      }
    });

    this.solution = this.solutions[0].value;
  }

  hasMessages(messageWidgetNum) {
    const messageContentIndex = messageWidgetNum - 1;
    return (
      this.rights?.includes(`MessagesWidget${messageWidgetNum}`) &&
      this.msgContent &&
      this.msgContent[messageContentIndex] &&
      this.msgContent[messageContentIndex].title
    );
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }
}
