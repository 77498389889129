<mat-form-field>
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date" [(ngModel)]="startDate" />
    <input matEndDate placeholder="End date" [(ngModel)]="endDate" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>

<mat-form-field class="col-4 ml-3">
  <mat-label>Activity Codes</mat-label>
  <input
    matInput
    type="text"
    [(ngModel)]="activityCode"
    placeholder="Activity Codes"
  />
  <mat-hint>Comma delimited list, no spaces. Default is 'ALL'.</mat-hint>
</mat-form-field>

<mat-form-field class="ml-3">
  <input
    type="text"
    placeholder="Search For a Unit"
    matInput
    [matAutocomplete]="auto"
    name="unitsControl"
    [formControl]="unitsControl"
    [(ngModel)]="selectedUnit"
  />
  <mat-autocomplete #auto="matAutocomplete">
    @for (unit of filteredUnits | async; track $index) {
      <mat-option [value]="unit.id">
        {{ unit.name }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
<button
  mat-raised-button
  color="primary"
  class="mx-auto"
  (click)="getSessionActivity()"
  class="ml-3"
  [disabled]="!selectedUnit || !startDate || !endDate"
>
  Get Exceptions
</button>
@if (unitSessionActivity && unitSessionActivity.data) {
  <button
    mat-button
    color="primary"
    class="grid-button pull-right"
    (click)="exportToExcel(unitUsageGrid)"
  >
    <mat-icon>cloud_download</mat-icon>Download Excel File
  </button>

  <kendo-grid
    #unitUsageGrid="kendoGrid"
    [hidden]="!unitSessionActivity.data"
    class="col-12 mt-3 usage-grid"
    [kendoGridBinding]="unitSessionActivity.data"
    [height]="600"
  >
    @for (col of unitSessionActivity.header; track $index) {
      <kendo-grid-column
        [field]="col.DataField"
        [title]="col.Label"
      ></kendo-grid-column>
    }
    <kendo-grid-excel
      fileName="RightBridge-UnitSessionActivity.xlsx"
    ></kendo-grid-excel>
  </kendo-grid>
}
<ngx-loading [show]="loading"></ngx-loading>
