import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { ReportingService } from '../../reporting.service';
import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Unit } from '../../../models/admin-models';

@Component({
  selector: 'app-unit-usage',
  templateUrl: './unit-usage.component.html',
  styleUrls: ['./unit-usage.component.scss'],
})
export class UnitUsageComponent implements OnInit {
  usageUnitsControl = new FormControl();
  usage = { unit: null, start: null, end: null };
  usageGridData = { data: null, header: null };
  filteredUnits;
  units: Unit[] = [] as Unit[];

  constructor(
    private admnSvc: AdminService,
    private rptSvc: ReportingService
  ) {}

  ngOnInit(): void {
    this.getUsageUnits();
  }

  getUsageUnits() {
    this.usageUnitsControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.admnSvc.getUnitList(value).subscribe(resp => {
            if (resp['names']) {
              this.filteredUnits = resp['names'];
            }
          });
        }
      });
  }

  checkSelection() {
    if (
      !this.filteredUnits ||
      !this.filteredUnits.find(unit => {
        return unit.id === this.usageUnitsControl.value;
      })
    ) {
      this.usageUnitsControl.setValue(null);
    }
  }

  autoCompleteUnitChanged(ev) {
    this.usageUnitsControl.setValue(ev.option.value);
  }

  getUsage() {
    this.rptSvc
      .getUsage(
        this.usage.unit,
        this.usage.start.toISOString(),
        this.usage.end.toISOString()
      )
      .subscribe({
        next: data => {
          this.usageGridData = { ...data };
        },
        error: error => {
          console.error(error);
        },
      });
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25';
    }
    const list = this.admnSvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }

  exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
}
