import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from './session-storage.service';
import { RightBridgeApiService } from './right-bridge-api.service';

@Injectable({ providedIn: 'root' })
export class UserPreferencesService {
  private sessionId: string = this.ss.get('session');
  public baseUrl: string;
  private firm: string;
  private app: string;
  private org: string;
  private columnsPreferences;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService
  ) {
    this.baseUrl = this.rbs.baseUrl;
  }

  saveColumns(columns): Observable<any> {
    const url = `${this.baseUrl}/UserPreferences?prefName=columnVisibility`;
    let existing;
    let data;

    if (this.columnsPreferences) {
      const columnsKey = Object.keys(columns)[0];

      try {
        existing = JSON.parse(this.columnsPreferences);
      } catch (e) {
        existing = this.columnsPreferences;
      }

      existing[columnsKey] = columns[columnsKey];
      data = existing;
    } else {
      data = columns;
    }

    return this.rbs.postRequest(url, data);
  }

  getColumns(): Observable<any> {
    const url = `${this.baseUrl}/UserPreferences?prefName=columnVisibility`;

    return this.rbs.getRequest(url).pipe(
      tap(x => {
        this.columnsPreferences = x;
      })
    );
  }
}
