<div class="w-100 totals">
  @if (mode === 'A') {
    <table class="w-100">
      <tr>
        <td>
          <h5 class="mr-2 text-right">Total Amount to Invest:</h5>
        </td>
        <td style="width: 1%">
          <h5 class="mr-2">
            {{ totalAmount | currency }}
          </h5>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="mr-2 text-right">Amount Allocated:</h5>
        </td>
        <td>
          <h5 class="mr-2">{{ totalInvestment | currency: 'USD' }}</h5>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="mr-2 text-right">Amount Left to Allocate:</h5>
        </td>
        <td>
          <h5 class="mr-2">
            {{ totalAmount - totalInvestment | currency: 'USD' }}
          </h5>
        </td>
      </tr>
    </table>
  }

  @if (mode === 'P') {
    <table class="w-100">
      <tr>
        <td>
          <h5 class="mr-2 text-right">Total Amount to Invest:</h5>
        </td>
        <td style="width: 1%">
          <h5 class="mr-2">
            {{
              (field.parent.model.ValidateProduct.InvestmentWizard_AssetAmount
                ? makeNumber(
                    field.parent.model.ValidateProduct
                      .InvestmentWizard_AssetAmount
                  )
                : 0
              ) | currency
            }}
          </h5>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="mr-2 text-right">Percent Allocated:</h5>
        </td>
        <td>
          <h5 class="mr-2">{{ totalPercent | percent }}</h5>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="mr-2 text-right">Percent Left to Allocate:</h5>
        </td>
        <td>
          <h5 class="mr-2">{{ 1 - totalPercent | percent }}</h5>
        </td>
      </tr>
    </table>
  }
</div>
