<div class="col-12 row summary-charts-container">
  <div class="col-12 title-container">
    <h4>Case Stats <small>(Last 30 days)</small></h4>
  </div>
  @if (chartData.length > 0) {
    @for (chrt of chartData; track chrt) {
      <div class="chart-container col-4">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="chrt"
          style="width: 100%; display: block; overflow: hidden"
        >
        </highcharts-chart>
      </div>
    }
  }
</div>
<ngx-loading
  [show]="loading"
  [config]="{
    fullScreenBackdrop: false
  }"
></ngx-loading>
