<mat-form-field class="" class="mr-3">
  <input
    type="text"
    placeholder="Search For a Unit"
    matInput
    [(ngModel)]="selectedUnit"
    [matAutocomplete]="auto"
    name="unitsControl"
    [formControl]="unitsControl"
    (blur)="checkSelection()"
  />
  <mat-autocomplete #auto="matAutocomplete">
    @for (unit of filteredUnits; track unit.id) {
      <mat-option [value]="unit.id">
        {{ unit.name }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
<mat-form-field class="" class="mr-3">
  <mat-label>Pick a ReportType</mat-label>
  <mat-select [(ngModel)]="selectedType" name="reportTypes">
    <mat-option *ngFor="let type of reportTypes" [value]="type.value">
      {{ type.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
<button
  mat-raised-button
  (click)="getUnitActiveUsers()"
  [disabled]="!selectedType || !selectedUnit"
  color="accent"
>
  Get Active Users Report
</button>
<ngx-loading [show]="loading"></ngx-loading>
