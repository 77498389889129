import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UnifiedFlowSearchComponent } from './unified-flow-search/unified-flow-search.component';
import { UnifiedCaseComponent } from './unified-case/unified-case.component';
import { ConditionalFormModule } from '../conditional-form/conditional-form.module';
import { PpResultsComponent } from './pp-results/pp-results.component';
import { AmountModalComponent } from './amount-modal/amount-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UnifiedFlowService } from './unified.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { UnifiedSummaryPageComponent } from './unified-summary-page/unified-summary-page.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxLoadingModule } from 'ngx-loading';
import { MaskitoDirective } from '@maskito/angular';
import { AiSummaryComponent } from '../ai-summary/ai-summary.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    UnifiedFlowSearchComponent,
    UnifiedCaseComponent,
    PpResultsComponent,
    AmountModalComponent,
    UnifiedSummaryPageComponent,
  ],
  imports: [
    CommonModule,
    SharedComponentsModule,
    ConditionalFormModule,
    ReactiveFormsModule,
    FormsModule,
    HighchartsChartModule,
    MatProgressBarModule,
    NgxLoadingModule,
    MaskitoDirective,
    AiSummaryComponent,
  ],
  providers: [UnifiedFlowService, { provide: Window, useValue: window }],
})
export class UnifiedFlowModule {}
