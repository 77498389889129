import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import {
  Location,
  PopStateEvent,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { timer } from 'rxjs';
import { HasPermissionDirective } from '../directives/permissions.directive';
import { MatDialog } from '@angular/material/dialog';
import { WarnDialogComponent } from '../warn-dialog/warn-dialog.component';
import { SessionStorageService } from '../services/session-storage.service';
import { TechNotesModalComponent } from './tech-note-modal/tech-notes-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class AppHeaderComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) public sideNav: MatSidenav;
  @ViewChild(HasPermissionDirective) permDirect: HasPermissionDirective;

  window: any = window;

  loggedIn: any = false;
  session: string = this.ss.get('session');
  user = null;
  rights: Array<string> = [];
  currApp;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  ProxyinMenu = false;
  proxyActive = false;
  dashLink = false;
  reporting = false;
  displayHelp = false;
  displayHelpMenu = false;
  proxyData;
  dialogRef;
  hideMenu = false;
  ceteraAction;
  isAdmin = false;
  external;
  noLink = true;
  logoName = 'rightbridge-logo.png';
  toolsLandingPage = false;

  constructor(
    private rbs: RightBridgeApiService,
    public route: Router,
    private ss: SessionStorageService,
    private location: Location,
    private dialog: MatDialog,
    private zone: NgZone
  ) {
    this.currApp = this.ss.get('currentApp');
  }

  ngOnInit() {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    this.ceteraAction = `${window.location.origin}/rightbridge.aspx?rpt=rprtpg`;
    this.currApp = this.ss.get('currentApp');

    const newTimer = timer(60000, 180000);
    newTimer.subscribe(() => {
      const sess = this.ss.get('session');
      if (sess) {
        this.checkSession();
      }
    });

    this.proxyStatus();

    this.ss.storageChangeExternal.subscribe(chng => {
      if (chng.key === 'proxySession') {
        this.proxyStatus();
      }
    });

    this.route.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        const globals = this.ss.get('globals') || null;
        setTimeout(() => {
          this.currApp = this.ss.get('currentApp');
          if (this.proxyActive) {
            this.user = this.ss.get('origSession');
          } else {
            this.user = globals ? globals.user : null;
          }
          this.hideMenu = this.ss.get('rights')
            ? this.ss.get('rights').includes('HideMenu')
            : null;
          this.session = this.ss.get('session');

          if (!this.user) {
            this.hideMenu = true;
          } else if (this.hideMenu && this.user) {
            this.hideMenu = true;
          }

          this.loggedIn = this.user || false;

          if (e.url == this.lastPoppedUrl) {
            this.lastPoppedUrl = undefined;
            window.scroll(0, this.yScrollStack.pop());
          } else {
            window.scroll(0, 0);
          }
          this.rights = this.ss.get('rights') || [];

          if (this.rights) {
            this.ProxyinMenu = this.rights.includes('Proxy');
            this.dashLink = this.rights.includes('NewDashboardTab');
            this.isAdmin = this.rights.includes('Admin');
            this.reporting = this.rights.includes('ReportingTabInMenu');
            this.toolsLandingPage = this.rights.includes('ToolsLandingPage');
            let helpUrl;

            if (
              globals &&
              globals.GlobalsGroup &&
              globals.GlobalsGroup.UnitInfo
            ) {
              helpUrl =
                globals.GlobalsGroup.UnitInfo.FirmHelpURL &&
                globals.GlobalsGroup.UnitInfo.FirmHelpURL.length > 0
                  ? globals.GlobalsGroup.UnitInfo.FirmHelpURL
                  : null;
            } else {
              helpUrl =
                globals && globals.HelpURL.length > 0 ? globals.HelpURL : null;
            }

            this.displayHelp =
              helpUrl ||
              this.rights.includes('AWHelp') ||
              this.rights.includes('PPHelp') ||
              this.rights.includes('IWHelp') ||
              this.rights.includes('LIHelp');
            this.displayHelpMenu =
              this.rights.includes('AWHelp') ||
              this.rights.includes('PPHelp') ||
              this.rights.includes('IWHelp') ||
              this.rights.includes('LIHelp');
            this.noLink = !helpUrl;
            this.logoName =
              globals &&
              globals.GlobalsGroup &&
              globals.GlobalsGroup.UnitInfo &&
              globals.GlobalsGroup.UnitInfo.FirmLogo
                ? globals.GlobalsGroup.UnitInfo.FirmLogo
                : this.logoName;
          }
        }, 200);
      }
    });
  }

  checkSession() {
    const session = this.ss.get('session');
    if (session) {
      this.rbs.querySession(session).subscribe(x => {
        const time = x.results;
        if (session) {
          if (time <= 0 || isNaN(time)) {
            if (this.dialogRef) {
              this.dialogRef.close();
              this.dialogRef
                .afterClosed()
                .subscribe(() => (this.dialogRef = null));
            }
            this.ss.clear();
            this.logout();
          } else if (time <= 300) {
            if (!this.dialogRef) {
              this.dialogRef = this.dialog.open(WarnDialogComponent, {
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content:
                    'Due to inactivity, your session is close to ending. To stay logged in please click "Continue"',
                  confirm: 'Continue',
                },
              });
              this.dialogRef.afterClosed().subscribe(result => {
                if (result == 'continue') {
                  this.rbs.resetSession(session).subscribe();
                }
                this.dialogRef = null;
              });
            }
          }
        }
      });
    }
  }

  logout() {
    const session = this.ss.get('session');
    if (this.sideNav) {
      this.sideNav.close();
    }

    this.rbs.endSession(session).subscribe(
      () => {
        this.user = null;
        this.loggedIn = false;
        this.zone.run(() => {
          this.route.navigate(['/login']);
        });
      },
      () => {
        this.user = null;
        this.loggedIn = false;
        this.zone.run(() => {
          this.route.navigate(['/login']);
        });
      }
    );
  }

  updateApp() {
    this.currApp = this.ss.get('currentApp');
  }

  getHelp(url?, app?) {
    let custUrl;
    if (app && app != 'rb') {
      this.rbs.getGlobals(null, null, 'aw').subscribe(x => {
        const glbls = x.results;
        custUrl =
          glbls.HelpURL && glbls.HelpURL.length > 0 ? glbls.HelpURL : null;
      });
    } else if (app && app == 'rb') {
      const glbls = this.ss.get('globals');
      custUrl =
        glbls.GlobalsGroup.UnitInfo.FirmHelpURL &&
        glbls.GlobalsGroup.UnitInfo.FirmHelpURL.length > 0
          ? glbls.GlobalsGroup.UnitInfo.FirmHelpURL
          : null;
    } else {
      custUrl =
        this.ss.get('globals') && this.ss.get('globals').HelpURL.length > 0
          ? this.ss.get('globals').HelpURL
          : null;
    }

    this.external = custUrl ? custUrl.includes('http') : null;

    if (url) {
      window.open(url, '_blank');
    } else if (this.external) {
      window.open(custUrl, '_blank');
    }
  }

  home() {
    this.updateApp();
    const url = this.dashLink ? `/dashboard` : `/${this.currApp}`;
    this.ss.remove('selectedFunds');
    if (this.sideNav) {
      this.sideNav.close();
    }
    this.route.navigate([url]);
  }

  proxy() {
    this.updateApp();
    this.ss.remove('selectedFunds');
    if (this.sideNav) {
      this.sideNav.close();
    }
    const url = '/' + this.currApp + '/proxy/';
    if (this.currApp === 'summary' || this.currApp === 'rb') {
      this.route.navigate(['/proxy/']);
    } else {
      this.route.navigate([url]);
    }
  }

  proxyStatus() {
    this.proxyData = this.ss.get('proxySession');
    if (this.proxyData) {
      this.proxyActive = Object.keys(this.proxyData).length > 1;
    } else {
      this.proxyActive = false;
    }
  }

  endProxy() {
    this.proxyActive = false;
    const origSession = this.ss.get('origSession');
    this.ss.set('session', origSession.session);

    this.ss.remove('proxySession');
    this.ss.remove('origSession');

    this.updateApp();
    this.rbs.getGlobals().subscribe(() => {
      const url = this.currApp === 'se' ? '/dashboard' : '/unified';
      this.route.navigate([url]);
    });
  }

  openTechNotes() {
    this.dialog.open(TechNotesModalComponent, {
      panelClass: 'app-tech-notes-modal',
    });
  }

  goToPage(link: string): void {
    this.route.navigate([link]);
  }
}
