import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SsoComponent } from './sso/sso.component';
import { F5500ProductShelfComponent } from './f5500-product-shelf/f5500-product-shelf.component';
import { AuthService } from './services/auth-service.service';
import { UnifiedFlowSearchComponent } from './unified-flow/unified-flow-search/unified-flow-search.component';
import { UnifiedCaseComponent } from './unified-flow/unified-case/unified-case.component';
import { PostbackThankYouComponent } from './shared-components/postback-thank-you/postback-thank-you.component';
import { ProxyComponent } from './proxy/proxy.component';
import { ToolsPageComponent } from './tools-page/tools-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NoCdrRequiredComponent } from './no-cdr-required/no-cdr-required.component';
import { LandingPageComponent } from './unified-flow/landing-page/landing-page.component';
import { CaseExplorerComponent } from './case-explorer/case-explorer.component';
import { ProfileHistoryComponent } from './admin/profile-history/profile-history.component';
import { LegacyProfileHistoryComponent } from './admin/legacy-profile-history/legacy-profile-history.component';

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LandingPageComponent,
    canActivate: [AuthService],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/:session/:org',
    component: SsoComponent,
  },
  {
    path: 'login/:session/:org/profile-error',
    component: SsoComponent,
  },
  {
    path: 'login/:session/:org/:app',
    component: SsoComponent,
  },
  {
    path: 'login/:session/:org/:app/:profile',
    component: SsoComponent,
  },
  {
    path: 'searchLogin/:session/:org/:app/:search',
    component: SsoComponent,
  },
  {
    path: 'searchLogin/:session/:org/:app/:search/:bridgeId',
    component: SsoComponent,
  },
  {
    path: 'regions/login/:session/:org',
    component: SsoComponent,
  },
  {
    path: 'regions/login/:session/:org/:app',
    component: SsoComponent,
  },
  {
    path: 'regions/login/:session/:org/:app/:profile',
    component: SsoComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthService],
    data: {
      rightNeeded: 'NewDashboardTab',
    },
  },
  {
    path: 'case-explorer',
    component: CaseExplorerComponent,
    canActivate: [AuthService],
    data: {
      rightNeeded: 'CaseExplorer',
    },
  },
  {
    path: 'legacy-profile-history/:solution/:profileId',
    component: LegacyProfileHistoryComponent,
    canActivate: [AuthService],
  },
  {
    path: 'profile-history/:solution/:profileId',
    component: ProfileHistoryComponent,
    canActivate: [AuthService],
  },
  {
    path: 'f5500',
    component: F5500ProductShelfComponent,
    canActivate: [AuthService],
    data: {
      rightNeeded: 'PlanLookupTool',
    },
  },
  {
    path: 'iw',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'aw',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'pp',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'li',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'case-management',
    loadChildren: () =>
      import('./case-management/case-management.module').then(
        m => m.CaseManagementModule
      ),
    canActivate: [AuthService],
    data: {
      rightNeeded: 'CM',
    },
  },
  {
    path: 'book-management',
    loadChildren: () =>
      import('./book-management/book-management.module').then(
        m => m.BookManagementModule
      ),
    canActivate: [AuthService],
    data: {
      rightNeeded: 'BookManagement',
    },
  },
  {
    path: 'post-trade',
    loadChildren: () =>
      import('./post-trade/post-trade.module').then(m => m.PostTradeModule),
    canActivate: [AuthService],
    data: {
      rightNeeded: 'PostTrade',
    },
  },
  {
    path: 'se',
    loadChildren: () =>
      import('./suggestion-engine/suggestion-engine.module').then(
        m => m.SuggestionEngineModule
      ),
    canActivate: [AuthService],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account-management/account-management.module').then(
        m => m.AccountManagementModule
      ),
    canActivate: [AuthService],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthService],
    data: {
      rightNeeded: 'Admin',
    },
  },
  {
    path: 'reporting',
    loadChildren: () =>
      import('./reporting/reporting.module').then(m => m.ReportingModule),
    canActivate: [AuthService],
    data: {
      rightNeeded: 'ReportingTabInMenu',
    },
  },
  {
    path: 'downloads',
    loadChildren: () =>
      import('./downloads/downloads.module').then(m => m.DownloadsModule),
    canActivate: [AuthService],
    data: {
      rightNeeded: 'Sensitive',
    },
  },
  {
    path: 'training',
    loadChildren: () => import('./training/training-routing'),
    canActivate: [AuthService],
  },
  {
    path: 'unified',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'search',
    component: UnifiedFlowSearchComponent,
    canActivate: [AuthService],
  },
  {
    path: 'case',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'case/:app',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'case/:app/:id',
    redirectTo: 'case/:app/:id/0',
    pathMatch: 'full',
  },
  {
    path: 'case/:app/:id/:step',
    component: UnifiedCaseComponent,
    canActivate: [AuthService],
  },
  {
    path: 'case-conclusion',
    component: PostbackThankYouComponent,
    canActivate: [AuthService],
  },
  {
    path: 'summary/proxy',
    component: ProxyComponent,
    canActivate: [AuthService],
  },
  {
    path: 'proxy',
    component: ProxyComponent,
    canActivate: [AuthService],
  },
  {
    path: 'tools',
    component: ToolsPageComponent,
    canActivate: [AuthService],
    data: {
      rightNeeded: 'ToolsLandingPage',
    },
  },
  {
    path: 'cdrnotrequired',
    pathMatch: 'full',
    component: NoCdrRequiredComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

export const AppRouting = RouterModule.forRoot(appRoutes, {
  useHash: false,
  onSameUrlNavigation: 'reload',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64], // [x, y]
  //   , enableTracing: true
});
