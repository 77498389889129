<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    Case Activity Statistics
    <button
      mat-button
      class="download"
      (click)="exportCSV(viewingUser ? data.Cases[0].AdvisorID : null)"
    >
      <mat-icon>cloud_download</mat-icon>Download CSV File
    </button>
  </mat-card-header>
  <mat-card-content class="row">
    <div class="col-12">
      @if (viewingUser) {
        <h4>
          <mat-icon (click)="getData()" class="cursor">arrow_back</mat-icon
          >Stats for {{ data.Cases[0].AdvisorID }}
        </h4>
      }
      @if (!limit30) {
        <mat-form-field class="mr-2">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startPicker"
            placeholder="Choose a start date"
            [formControl]="startDate"
            (dateChange)="getData(viewingUser ? data.Cases[0].AdvisorID : null)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mr-2">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="endPicker"
            placeholder="Choose an end date"
            [formControl]="endDate"
            (dateChange)="getData(viewingUser ? data.Cases[0].AdvisorID : null)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      }
    </div>
    <div class="col-12 data-container">
      @if (data) {
        <table class="content col-12 products">
          <thead class="mb-2">
            @if (!viewingUser) {
              <th>User</th>
            }
            <th>Client Name</th>
            <th>Product</th>
            <th class="text-center">Score Class</th>
            <th class="text-center">Case Run Count</th>
            <th class="text-center">Score Class Changes</th>
            <th class="text-center">Product Change Count</th>
          </thead>
          <tbody>
            @for (case of data.Cases; track case) {
              <tr>
                @if (!viewingUser) {
                  <td (click)="getData(case.AdvisorID)" class="cursor">
                    {{ case.AdvisorID }}
                  </td>
                }
                <td>{{ case.FullName }}</td>
                <td>{{ case.Products[0].ProductName }}</td>
                <td class="text-center">
                  @switch (case.Products[0].ScoreClass) {
                    @case ('positive') {
                      <mat-icon class="positive" matTooltip="Positive">
                        check_circle
                      </mat-icon>
                    }
                    @case ('neutral') {
                      <mat-icon class="neutral" matTooltip="Neutral">
                        error
                      </mat-icon>
                    }
                    @case ('negative') {
                      <mat-icon class="negative" matTooltip="Negative">
                        remove_circle
                      </mat-icon>
                    }
                  }
                </td>
                <td class="text-center">{{ case.RunCount }}</td>
                <td class="text-center">{{ case.ScoreClassChangeCount }}</td>
                <td class="text-center">{{ case.ProductChangeCount }}</td>
              </tr>
            }
          </tbody>
        </table>
      }
    </div>
  </mat-card-content>
  <ngx-loading
    [show]="loading"
    [config]="{ fullScreenBackdrop: false }"
  ></ngx-loading>
</mat-card>
