import { Component } from '@angular/core';

@Component({
  selector: 'app-no-cdr-required',
  standalone: true,
  imports: [],
  templateUrl: './no-cdr-required.component.html',
  styleUrl: './no-cdr-required.component.scss',
})
export class NoCdrRequiredComponent {}
