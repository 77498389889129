<div class="page-container container unified">
  <h1 class="header col-12">
    <button
      mat-button
      color="primary"
      class="header-button"
      (click)="compare()"
      [disabled]="canCompare()"
    >
      <mat-icon>compare_arrows</mat-icon>
      <span>Compare</span>
    </button>
  </h1>
  <div class="row">
    <div
      class="message-container col-8 offset-2 my-3 row"
      *ngIf="!loading && !data"
    >
      <h3>
        The information entered indicates that the insured has adequate coverage
        or is over-insured, there is no need for additional coverage. We are
        unable to provide results in this situation.
      </h3>
    </div>

    <div
      class="message-container col-8 offset-2 my-3 row"
      *ngIf="
        !loading &&
        this.products.tempLifeProducts.length <= 0 &&
        this.products.permLifeProducts.length <= 0
      "
    >
      <h3>
        Based on the data entered for the insured, no results were found. We are
        unable to provide results in this situation.
      </h3>
    </div>

    <div
      class="products-container col-7"
      *ngIf="
        !loading &&
        (this.products.tempLifeProducts.length > 0 ||
          this.products.permLifeProducts.length > 0)
      "
    >
      <ng-container *ngFor="let category of products | keyvalue">
        <div
          class="col-12"
          [ngClass]="{
            'perm-product-container': category.key === 'permLifeProducts',
            'temp-product-container': category.key === 'tempLifeProducts'
          }"
        >
          <h3 *ngIf="category.key === 'permLifeProducts'"
            >Permanent Product Options</h3
          >
          <h3 *ngIf="category.key === 'tempLifeProducts'"
            >Term Product Options</h3
          >
          <ng-container *ngIf="category.value.length > 0; else noOptions">
            <ul
              class="products col-12 mb-5"
              [ngClass]="{
                'group-collapsed':
                  !altsExpanded && grp === 'select' && caseAssesmentType === 2
              }"
            >
              <ng-container *ngFor="let prod of category.value; let i = index">
                <li
                  *ngIf="!prod.hidden && !showAll"
                  class="product col-12 {{ prod.ScoreClass }} {{
                    prod.expandedClass
                  }}"
                  [class.selected]="
                    (selectedPerm &&
                      selectedPerm.vars['CurrentLifeItem^LifeItemID'].raw[0] ===
                        prod.vars['CurrentLifeItem^LifeItemID'].raw[0]) ||
                    (selectedTemp &&
                      selectedTemp?.vars['CurrentLifeItem^LifeItemID']
                        .raw[0] ===
                        prod.vars['CurrentLifeItem^LifeItemID'].raw[0])
                  "
                >
                  <div class="col-12 row">
                    <div class="col-11 content">
                      <span class="score-icon {{ prod.ScoreClass }}">
                        <mat-icon *ngIf="prod.ScoreClass === 'positive'"
                          >check_circle</mat-icon
                        >
                        <mat-icon *ngIf="prod.ScoreClass === 'neutral'"
                          >error</mat-icon
                        >
                        <mat-icon *ngIf="prod.ScoreClass === 'negative'"
                          >remove_circle</mat-icon
                        >
                      </span>
                      <button
                        mat-button
                        color="primary"
                        class="mr-5 select-button"
                        *ngIf="category.key === 'permLifeProducts'"
                        (click)="
                          selectProducts($event, prod, false, false);
                          $event.stopPropagation()
                        "
                      >
                        <ng-container
                          *ngIf="
                            selectedPerm &&
                            selectedPerm.vars['CurrentLifeItem^LifeItemID']
                              .raw[0] ===
                              prod.vars['CurrentLifeItem^LifeItemID'].raw[0]
                          "
                        >
                          Deselect {{ prod.selectedBridge }}
                        </ng-container>
                        <ng-container
                          *ngIf="
                            !selectedPerm ||
                            (selectedPerm &&
                              selectedPerm.vars['CurrentLifeItem^LifeItemID']
                                .raw[0] !==
                                prod.vars['CurrentLifeItem^LifeItemID'].raw[0])
                          "
                        >
                          Select
                        </ng-container>
                      </button>
                      <button
                        mat-button
                        color="primary"
                        class="mr-5 select-button"
                        *ngIf="category.key === 'tempLifeProducts'"
                        (click)="
                          selectProducts($event, prod, false, false);
                          $event.stopPropagation()
                        "
                      >
                        <ng-container
                          *ngIf="
                            selectedTemp &&
                            selectedTemp?.vars['CurrentLifeItem^LifeItemID']
                              .raw[0] ===
                              prod.vars['CurrentLifeItem^LifeItemID'].raw[0]
                          "
                        >
                          Deselect
                        </ng-container>
                        <ng-container
                          *ngIf="
                            !selectedTemp ||
                            (selectedTemp &&
                              selectedTemp?.vars['CurrentLifeItem^LifeItemID']
                                .raw[0] !==
                                prod.vars['CurrentLifeItem^LifeItemID'].raw[0])
                          "
                        >
                          Select
                        </ng-container>
                      </button>
                      <div>
                        <h4>
                          {{ prod.ShortName }}
                          <span *hasPermission="'!RelevanceHideBridgeScore'">
                            | {{ scoreFormat(prod.score) }}</span
                          >
                        </h4>
                        <mat-checkbox
                          [(ngModel)]="compareChecked[prod.LifeItemID]"
                          [ngModelOptions]="{ standalone: true }"
                          (click)="
                            compareToggle($event, prod);
                            $event.stopPropagation()
                          "
                        >
                          Compare
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="col-1 button d-flex align-items-center">
                      <button
                        class="mb-0"
                        mat-icon-button
                        (click)="expand($event)"
                      >
                        <span class="expand-toggle-icon">
                          <mat-icon>expand_circle_down</mat-icon>
                        </span>
                      </button>
                    </div>
                  </div>

                  <div class="details col-12">
                    <div class="details-container row">
                      <h5 class="col-12 pl-0">Description</h5>
                      <p
                        class="w-100 pb-3"
                        *ngIf="prod.ShortDescription"
                        [innerHTML]="prod.ShortDescription"
                      ></p>
                      <ng-container *ngIf="showReasons">
                        <div
                          *ngIf="prod.ComplianceChart"
                          class="table-container"
                        >
                          <h5 class="col-12 pl-0">Regulation Review</h5>
                          <div class="tables w-100">
                            <table class="compliance-details">
                              <thead>
                                <tr>
                                  <th
                                    *ngFor="
                                      let x of prod.ComplianceChart.Header;
                                      let idx = index
                                    "
                                    [class.text-center]="idx === 1 || idx === 3"
                                    >{{ x }}</th
                                  >
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let t of prod.ComplianceChart.Data;
                                    let idx = index
                                  "
                                  [ngClass]="t.Status"
                                >
                                  <ng-container
                                    *ngIf="idx < prod.ComplianceChart.Limit"
                                  >
                                    <td
                                      ><strong>{{ t.Area }}</strong></td
                                    >
                                    <td class="text-center">
                                      <mat-icon *ngIf="t.Status === 'Positive'"
                                        >check</mat-icon
                                      >
                                      <mat-icon *ngIf="t.Status === 'Negative'"
                                        >close</mat-icon
                                      >
                                      <mat-icon *ngIf="t.Status === 'Warning'"
                                        >priority_high</mat-icon
                                      >
                                    </td>
                                  </ng-container>
                                </tr>
                              </tbody></table
                            >
                            <table class="compliance-details">
                              <thead>
                                <tr>
                                  <th
                                    *ngFor="
                                      let x of prod.ComplianceChart.Header
                                    "
                                    >{{ x }}</th
                                  >
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let t of prod.ComplianceChart.Data;
                                    let idx = index
                                  "
                                  [ngClass]="t.Status"
                                >
                                  <ng-container
                                    *ngIf="idx >= prod.ComplianceChart.Limit"
                                  >
                                    <td
                                      ><strong>{{ t.Area }}</strong></td
                                    >
                                    <td class="text-center">
                                      <mat-icon *ngIf="t.Status === 'Positive'"
                                        >check</mat-icon
                                      >
                                      <mat-icon *ngIf="t.Status === 'Negative'"
                                        >close</mat-icon
                                      >
                                      <mat-icon *ngIf="t.Status === 'Warning'"
                                        >priority_high</mat-icon
                                      >
                                    </td>
                                  </ng-container>
                                </tr>
                              </tbody></table
                            >
                          </div>
                        </div>
                        <h5 class="col-12 mx-auto">
                          Reasons for this Product
                        </h5>
                        <div
                          class="details col-12 px-0 mx-auto {{
                            z.reasonType.toLowerCase()
                          }}"
                          *ngFor="let z of prod.reasons"
                        >
                          <strong class="header">
                            <img
                              alt="product reason"
                              src="{{
                                './assets/' +
                                  path +
                                  z.reasonType.toLowerCase() +
                                  '.png'
                              }}"
                            />
                            <span [innerHTML]="z.headline"></span>
                            <span *hasPermission="'!RelevanceHideReasonScores'">
                              | {{ scoreFormat(z.score) }}</span
                            >
                          </strong>
                          <p
                            class="explanation"
                            *ngIf="z.explanation"
                            [innerHTML]="z.explanation"
                          ></p>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </ng-container>
          <ng-template #noOptions>
            <div class="product no-products row">
              <mat-icon class="col-1">report</mat-icon>
              <h5 class="col-9 pl-0">
                Based on the questionnaire input, no strategies are available
                for this product option.
              </h5>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <div
      class="charts col-5"
      *ngIf="
        !loading &&
        (this.products.tempLifeProducts.length > 0 ||
          this.products.permLifeProducts.length > 0)
      "
    >
      <h3>Selected Product Mix</h3>
      <slider-charts
        [fundData]="fundData"
        (mixChange)="handleMixChange($event)"
        (cashFlowChange)="handleCashFlowChange($event)"
      ></slider-charts>
      <div class="disclaimer col-12 mt-3 px-0" [innerHTML]="disclaimer">
        NOTICE: The strategies represented are not actual products for sale.
        Graphs displaying rates, premium, death benefit and cash value are for
        educational purposes only and are designed to demonstrate a relationship
        between premiums paid and future values.
      </div>
    </div>
  </div>
</div>
