import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { MaskService } from '../../../services/mask.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InvestmentWizardService } from '../../../investment-wizard/services/investment-wizard-service.service';
@Component({
  selector: 'app-form-currency',
  styleUrls: ['form-currency.component.scss'],
  template: `
    <mat-form-field floatPlaceholder="auto" class="w-100">
      <mat-label>{{ to.label }}</mat-label>
      <input
        matInput
        [maskito]="dollarMask"
        maxlength="20"
        [placeholder]="props.placeholder || props.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [required]="props.required"
        [defaultValue]="formControl.value || ''"
        value="formControl.value || ''"
      />
      <mat-icon
        *ngIf="props.help"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        >help</mat-icon
      >
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('regRequired')
        "
        >This field is required</mat-error
      >
      <mat-error *ngIf="formControl.hasError('regRequired')"
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.</mat-error
      >
    </mat-form-field>
  `,
})
export class FormlyCustomCurrencyComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  dollarMask;
  unsubscribe = new Subject();

  constructor(
    public mask: MaskService,
    private iws: InvestmentWizardService
  ) {
    super();
    this.dollarMask = this.mask.dollarMaskSpecs();
  }

  ngOnInit(): void {
    this.field.form.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.iws.getNeedToUpdateCalc()) {
          this.iws.setUpdateExternalCalcValue(true);
        }
      });
  }
}
