<mat-form-field class="mr-2">
  <mat-label>Application</mat-label>
  <mat-select
    [(ngModel)]="selectedApp"
    name="apps"
    (selectionChange)="onAppChange()"
  >
    @for (app of applications; track app.name) {
      <mat-option [value]="app.value">
        {{ app.name }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
<mat-form-field class="mr-2">
  <mat-label>Pick a Report Type</mat-label>
  <mat-select
    [(ngModel)]="selectedType"
    name="reportTypes"
    [disabled]="!selectedApp"
  >
    @for (type of reportTypes; track type) {
      <mat-option [value]="type">
        {{ type }}
      </mat-option>
    }
    <mat-option [value]="'other'"> Other </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field class="mr-2">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input
    [rangePicker]="picker"
    [min]="dateRangeGovernor('min')"
    [max]="dateRangeGovernor('max')"
  >
    <input matStartDate placeholder="Start date" [(ngModel)]="startDate" />
    <input matEndDate placeholder="End date" [(ngModel)]="endDate" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
  <mat-hint>3 month max.</mat-hint>
</mat-form-field>
<mat-form-field class="mr-2">
  <mat-label>Report Details</mat-label>
  <input
    matInput
    type="text"
    [(ngModel)]="reportDetails"
    placeholder="Report details"
  />
</mat-form-field>
<mat-form-field class="mr-2">
  <mat-label>Report Recipient Email</mat-label>
  <input
    matInput
    type="text"
    [(ngModel)]="email"
    placeholder="Report Recipient Email"
  />
</mat-form-field>
<button
  mat-raised-button
  (click)="submitReportRequest()"
  [disabled]="!selectedApp || !selectedType || !startDate || !endDate || !email"
  color="accent"
>
  Submit Request
</button>

<table class="mt-2">
  <colgroup>
    <col span="1" style="width: 20%" />
    <col span="1" style="width: 80%" />
  </colgroup>

  <thead>
    <th>Report Name</th>
    <th>Description</th>
  </thead>
  <tbody>
    @if (reportOptions.length > 0) {
      @for (reportOption of reportOptions; track reportOption.name) {
        <tr>
          <td>
            <strong>
              {{ reportOption.name }}
            </strong>
          </td>
          <td>{{ reportOption.description }}</td>
        </tr>
      }
    } @else {
      <tr>
        <td colspan="2" class="no-results"> No results found </td>
      </tr>
    }
  </tbody>
</table>

<ngx-loading [show]="loading"></ngx-loading>
