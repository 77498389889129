import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import { DateTime } from 'luxon';
import { DateHandlingService } from '../../../services/date-handling.service';

@Component({
  selector: 'app-case-stats',
  templateUrl: './caseStats.component.html',
  styleUrls: ['./caseStats.component.scss'],
})
export class CaseStatsComponent implements OnInit {
  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(
    DateTime.now().minus({ days: 30 }).toUTC().startOf('day').toISO()
  );
  endDate = new FormControl(DateTime.now().toUTC().endOf('day').toISO());
  data = [];
  solutions = [];
  solution;
  assessmentType = 'ALL';
  level = 'UNIT_ONLY';
  unitList = [];
  limit30 = true;

  constructor(
    private dateSvc: DateHandlingService,
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.ss.get('globals')?.Modules.forEach(el => {
      switch (el.toLowerCase()) {
        case 'aw':
          this.solutions.push({
            label: 'Annuity Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'iw':
          this.solutions.push({
            label: 'Investment Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'li':
          this.solutions.push({
            label: 'Life Insurance Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'pp':
          this.solutions.push({
            label: 'Product Profiler',
            value: el.toLowerCase(),
          });
          break;
        default:
          break;
      }
    });

    const rights = this.ss.get('rights');
    this.limit30 = rights?.includes('limit30Days');

    this.solution = this.solutions[0]?.value;

    this.updateData();
  }

  updateData(clear?) {
    if (clear) {
      this.unitList = [];
    }

    this.loading = true;

    const start = this.limit30
      ? this.dateSvc.thirtyDays().start
      : this.dateSvc.toUseableString(this.startDate.value, 'start');

    const end = this.limit30
      ? this.dateSvc.thirtyDays().end
      : this.dateSvc.toUseableString(this.endDate.value, 'end');

    this.dbs
      .getCaseStats(
        this.unit,
        this.solution,
        start,
        end,
        this.assessmentType,
        this.level
      )
      .subscribe(x => {
        this.data = x.Summary;
        this.loading = false;
      });
  }

  handleRowClick(ev) {
    if (ev.column.field == 'UnitName') {
      if (ev.dataItem.Units >= 1) {
        if (
          (this.unitList.length &&
            this.unitList[this.unitList.length - 1].UnitID !=
              ev.dataItem.UnitID) ||
          !this.unitList.length
        ) {
          this.unitList.push(ev.dataItem);
        }
        this.unit = ev.dataItem.UnitID;
        this.level = 'UNIT_CHILDREN';
        this.updateData();
      }
    } else if (
      ev.column.field == 'Users' ||
      ev.column.field == 'UsersWithCases'
    ) {
      if (!this.unitList.length) {
        this.unitList.push(ev.dataItem);
      }
      this.unit = ev.dataItem.UnitID;
      this.level = 'UNIT_USERS';
      this.updateData();
    }
  }

  goBack() {
    if (this.unitList.length > 1) {
      this.unit = this.unitList[this.unitList.length - 2].UnitID;
      this.level = 'UNIT_CHILDREN';
      this.updateData();
      this.unitList.pop();
    } else if (this.unitList.length == 1) {
      this.unit = this.unitList[0].UnitID;
      this.level = 'UNIT_ONLY';
      this.updateData(true);
      this.unitList.pop();
    } else {
      this.unit = this.unitList[this.unitList.length - 1].UnitID;
      this.level = 'UNIT_ONLY';
      this.updateData();
      this.unitList.pop();
    }
  }

  goToProfile(ev, id) {
    this.router.navigate([`/li/questionnaire/${id}`]);
  }
}
