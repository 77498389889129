import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { states } from '../../../lib/states';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  startWith,
  map,
} from 'rxjs/operators';
import { UniversalSearchService } from '../../../universal-search/services/universal-search.service';
import { DateTime } from 'luxon';
import { FormControl } from '@angular/forms';
import { MaskService } from '../../../services/mask.service';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { PostTradeService } from '../../../post-trade/post-trade.service';
import { CustomFiltersService } from '../../../services/custom-filters.service';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-grid-filter-fields',
  templateUrl: './grid-filter-fields.component.html',
  styleUrls: ['./grid-filter-fields.component.scss'],
})
export class GridFilterFieldsComponent implements OnInit, OnDestroy {
  @Input() field;
  @Input() filterForm;
  @Input() groupKey;
  @Output() textChange = new EventEmitter();
  @Output() valueRangeChange = new EventEmitter();
  @Output() dateChange = new EventEmitter();
  @Output() relatedDateChange = new EventEmitter();
  @Output() selectionChange = new EventEmitter();
  @Output() clearFilter = new EventEmitter();
  @ViewChild('pickerField') pickerField;
  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  floatingRangeOptions = [
    { value: '30 days', display: 'Last 30 Days' },
    { value: '90 days', display: 'Last 90 Days' },
    { value: 'mtd', display: 'Month-to-Date' },
    { value: 'ytd', display: 'Year-to-Date' },
    { value: 'custom', display: 'Custom Range' },
  ];
  start = new FormControl<Date | null>(null);
  end = new FormControl<Date | null>(null);
  relatedStart = new FormControl<Date | null>(null);
  relatedEnd = new FormControl<Date | null>(null);
  dollarMask = this.mask.dollarMaskSpecs();
  states = states;
  unsubscribe = new Subject();
  typeaheadControl = new FormControl<any>('');
  tyepaheadOptions = [];
  filteredOptions: Observable<any>;
  minField = new FormControl<string | null>(null);
  maxField = new FormControl<string | null>(null);
  rangeEvent;
  hashedControl = new FormControl<any>('');
  valueHashed = false;
  hashEvent;

  constructor(
    private cms: CaseManagementService,
    private ptSvc: PostTradeService,
    public usServ: UniversalSearchService,
    public cstmFltrSrvc: CustomFiltersService,
    private mask: MaskService
  ) {}

  ngOnInit(): void {
    if (
      this.field.controlType === 'repCode' ||
      this.field.controlType === 'unit' ||
      this.field.controlType === 'user'
    ) {
      this.getFilteredOptions(this.field.varName);
    }

    if (
      this.field.controlType === 'relatedDate' ||
      this.field.controlType === 'presetRange' ||
      this.field.controlType === 'currencyRange'
    ) {
      this.cstmFltrSrvc.updatePresets$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          field => {
            if (field && field['customRange']) {
              if (field['value'] === 'relatedSub') {
                this.relatedStart.setValue(
                  DateTime.fromISO(field['customRange']['start'], {
                    zone: 'utc',
                  })
                    .plus({ days: 1 })
                    .startOf('day')
                    .toISO()
                );
                this.relatedEnd.setValue(new Date(field['customRange']['end']));
              } else {
                this.start.setValue(
                  DateTime.fromISO(field['customRange']['start'], {
                    zone: 'utc',
                  })
                    .plus({ days: 1 })
                    .startOf('day')
                    .toISO()
                );
                this.end.setValue(new Date(field['customRange']['end']));
              }
            } else {
              this.relatedStart.setValue(null);
              this.relatedEnd.setValue(null);
              this.start.setValue(null);
              this.end.setValue(null);
            }
          },
          error => alert(error)
        );
    }
    if (this.field.controlType === 'dropdown') {
      this.cstmFltrSrvc.updateDropdowns$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          field => {
            if (field) {
              if (
                field['field'] === this.field.varName &&
                (typeof field['value'] === 'number' || field['value'])
              ) {
                this.typeaheadControl.setValue(field['value']);
              }
              if (field['value']['max'] || field['value']['min']) {
                this.minField.setValue(field['value']['min']);
                this.maxField.setValue(field['value']['max']);
              }
              if (field['value']['max'] || field['value']['min']) {
                this.minField.setValue(field['value']['min']);
                this.maxField.setValue(field['value']['max']);
              }
            } else {
              this.typeaheadControl.setValue(null);
              this.minField.setValue(null);
              this.maxField.setValue(null);
            }
          },
          error => alert(error)
        );

      this.field.options.forEach(option => {
        this.tyepaheadOptions.push(option);
      });
      this.filteredOptions = this.typeaheadControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          if (typeof value !== 'string' && value !== null) {
            this.filterForm.controls[this.groupKey].controls[
              this.field.varName
            ].setValue(value.value);
          }
          const name = typeof value === 'string' ? value : value?.label;
          return name
            ? this._filter(name as string)
            : this.tyepaheadOptions.slice();
        })
      );
      const defaultValue = this.tyepaheadOptions.find(
        option =>
          option.value ===
          this.filterForm.controls[this.groupKey].controls[this.field.varName]
            .value
      );
      this.typeaheadControl.setValue(
        typeof defaultValue === 'string' ? defaultValue : defaultValue?.value
      );
    }

    if (this.field.controlType === 'hashedInput') {
      this.cstmFltrSrvc.updateDropdowns$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          field => {
            if (
              field['field'] === this.field.varName &&
              (typeof field['value'] === 'number' || field['value'])
            ) {
              this.valueHashed = true;
              this.hashedControl.setValue(field['value']);
            }
          },
          error => alert(error)
        );
    }

    if (this.field.controlType === 'currencyRange') {
      this.minField.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          const valueRange = {
            min: value,
          };
          if (this.maxField.value) {
            valueRange['max'] = this.maxField.value;
          }

          this.filterForm.controls[this.groupKey].controls[
            this.field.varName
          ].setValue(valueRange);
          if (!this.rangeEvent) {
            this.rangeEvent = new InputEvent(null);
          }
          this.rangeEvent['valueRange'] = valueRange;
          this.valueRangeChange.emit(this.rangeEvent);
        });
      this.maxField.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          const valueRange = {
            max: value,
          };
          if (this.minField.value) {
            valueRange['min'] = this.minField.value;
          }
          this.filterForm.controls[this.groupKey].controls[
            this.field.varName
          ].setValue(valueRange);
          if (!this.rangeEvent) {
            this.rangeEvent = new InputEvent(null);
          }
          this.rangeEvent['valueRange'] = valueRange;
          this.valueRangeChange.emit(this.rangeEvent);
        });
    }
  }

  private _filter(name: string) {
    const filterValue = name.toLowerCase();

    return this.field.options.filter(option =>
      typeof option.value === 'string'
        ? option.value.toLowerCase().includes(filterValue)
        : null
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  changeText(ev) {
    this.filterForm.controls[this.groupKey].controls[
      this.field.varName
    ].setValue(ev.target.value);
    this.textChange.emit(ev);
  }

  changeValueRange(ev, fieldRange) {
    this[fieldRange].setValue(ev.target.value);
    this.rangeEvent = ev;
  }

  changeSelection(ev) {
    if (this.field.controlType === 'dropdown') {
      this.typeaheadControl.setValue(ev.option.getLabel());
      this.filterForm.controls[this.groupKey].controls[
        this.field.varName
      ].setValue(ev.option.value);
    }
    this.selectionChange.emit(ev);
  }

  changeDate(ev) {
    this.dateChange.emit(ev);

    if (this.field.controlType === 'presetRange') {
      // Change date range select to custom
      this.filterForm.controls[this.groupKey].controls[
        this.field.varName
      ].setValue('custom');
    }

    if (this.field.controlType === 'relatedDate') {
      // Clear the related dates
      this.relatedStart.setValue(null);
      this.relatedEnd.setValue(null);
      this.filterForm.controls[this.groupKey].controls[
        this.field.varName
      ].setValue('relatedMain');

      if (
        this.filterForm.controls[this.groupKey].controls[
          this.field.relatedVarName
        ]
      ) {
        this.filterForm.controls[this.groupKey].controls[
          this.field.relatedVarName
        ].setValue(null);
      }
    }
  }

  clearDateField() {
    this.start.setValue(null);
    this.end.setValue(null);
    this.filterForm.controls[this.groupKey].controls[
      this.field.varName
    ].setValue(null);
  }

  changeRelatedDate(ev) {
    this.relatedDateChange.emit(ev);
    if (
      !this.filterForm.controls[this.groupKey].controls[
        this.field.relatedVarName
      ]
    ) {
      this.filterForm.controls[this.groupKey].addControl(
        this.field.relatedVarName,
        new FormControl()
      );
    }
    this.filterForm.controls[this.groupKey].controls[
      this.field.relatedVarName
    ].setValue('relatedSub');

    this.filterForm.controls[this.groupKey].controls[
      this.field.varName
    ].setValue('');
    // Clear the related dates
    this.start.setValue(null);
    this.end.setValue(null);
  }

  resetFilter(ev) {
    if (this.typeaheadControl['value']) {
      this.typeaheadControl.setValue('');
    }
    this.clearFilter.emit(ev);
  }

  getFilteredOptions(varName) {
    this.filterForm.controls[this.groupKey].controls[varName].valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (this.field.controlType === 'repCode') {
          this.getRepCodeList(value);
        }
        if (this.field.controlType === 'unit') {
          this.getUnitList(value);
        }
        if (this.field.controlType === 'user') {
          this.getUserList(value);
        }
        if (this.field.controlType === 'hashedInput') {
          this.hashInput(value);
        }
      });
  }

  getRepCodeList(searchString?: string) {
    this.usServ.repCodeSearch(searchString).subscribe(resp => {
      const newRepCodeList = resp.names.map(rep => ({
        value: rep.repCode,
        display: rep.name,
      }));
      this.field.fullField.externalFilter['options'] = newRepCodeList;
    });
  }

  getUnitList(searchString?: string) {
    this.usServ.unitSearch(searchString).subscribe(resp => {
      const newUnitList = resp.names.map(rep => ({
        value: rep.id,
        display: rep.name,
      }));
      this.field.fullField.externalFilter['options'] = newUnitList;
    });
  }

  getUserList(searchString?: string) {
    // Possible temporary thing for CaseManagement.
    // Strange behavior for these two fields without the following code.
    if (
      this.field.varName === 'AssignedTo' ||
      this.field.varName === 'SubmittedBy'
    ) {
      this.cms.getCmUserFilterList(searchString).subscribe(resp => {
        const newUsersList = resp.Users.map(rep => ({
          value: rep.UserID,
          display: rep.UserName,
        }));
        this.field.fullField.externalFilter['options'] = newUsersList;
        this.cms.setModalPreparing(false);
      });
    } else {
      this.usServ.userSearch(searchString).subscribe(resp => {
        const newUsersList = resp.names.map(rep => ({
          value: rep.id,
          display: rep.name,
        }));
        this.field.fullField.externalFilter['options'] = newUsersList;
      });
    }
  }

  setHashEvent(ev) {
    this.valueHashed = false;
    this.hashEvent = ev;
  }

  hashInput(ev) {
    if (ev.target.value && !this.valueHashed) {
      this.ptSvc.getHashString(ev.target.value).subscribe(resp => {
        this.valueHashed = true;
        if (resp['resultString']) {
          this.filterForm.controls[this.groupKey].controls[
            this.field.varName
          ].setValue(resp['resultString']);
          this.hashedControl.setValue(resp['resultString']);
          this.hashEvent.target.value = resp['resultString'];
          this.changeText(this.hashEvent);
        }
      });
    }
  }

  getDropdownOptions() {
    return this.field.fullField.externalFilter['options'] || [];
  }

  showClearButton(fieldName) {
    if (this.filterForm.controls[this.groupKey].controls[fieldName].value) {
      return true;
    }
    return false;
  }

  rangeSelection(ev) {
    const { value } = ev;
    if (value === 'custom') {
      setTimeout(() => {
        this.pickerField.open();
      });
    } else if (value === 'mtd') {
      // Set the date picker with the selected times
      const calculatedStartDate = DateTime.now()
        .startOf('month')
        .toLocaleString();
      this.start.setValue(new Date(calculatedStartDate));
      this.end.setValue(new Date());
      // Fire dateChange method to update the filters
      this.dateChange.emit({
        target: this.startDatePicker,
        targetElement: this.startDatePicker.nativeElement,
        value: new Date(calculatedStartDate),
      });
      this.dateChange.emit({
        target: this.endDatePicker,
        targetElement: this.endDatePicker.nativeElement,
        value: new Date(),
      });
    } else if (value === 'ytd') {
      // Set the date picker with the selected times
      const calculatedStartDate = DateTime.now()
        .startOf('year')
        .toLocaleString();
      this.start.setValue(new Date(calculatedStartDate));
      this.end.setValue(new Date());
      // Fire dateChange method to update the filters
      this.dateChange.emit({
        target: this.startDatePicker,
        targetElement: this.startDatePicker.nativeElement,
        value: new Date(calculatedStartDate),
      });
      this.dateChange.emit({
        target: this.endDatePicker,
        targetElement: this.endDatePicker.nativeElement,
        value: new Date(),
      });
    } else {
      // Calculate time difference
      const calculateTime = value.split(' ')[0];
      const calculateUnit = value.split(' ')[1];
      const timeDiffObject = {};
      timeDiffObject[calculateUnit] = calculateTime;
      const calculatedStartDate = DateTime.now()
        .minus(timeDiffObject)
        .toLocaleString();

      // Set the date picker with the selected times
      this.start.setValue(new Date(calculatedStartDate));
      this.end.setValue(new Date());

      // Fire dateChange method to update the filters
      this.dateChange.emit({
        target: this.startDatePicker,
        targetElement: this.startDatePicker.nativeElement,
        value: new Date(calculatedStartDate),
      });
      this.dateChange.emit({
        target: this.endDatePicker,
        targetElement: this.endDatePicker.nativeElement,
        value: new Date(),
      });
    }
  }

  clearRange(ev) {
    this.clearFilter.emit(ev);
    this.start.setValue(null);
    this.end.setValue(null);

    this.dateChange.emit({
      target: this.startDatePicker,
      targetElement: this.startDatePicker.nativeElement,
      value: null,
    });
    this.dateChange.emit({
      target: this.endDatePicker,
      targetElement: this.endDatePicker.nativeElement,
      value: null,
    });
  }

  formattedRangeValue() {
    if (
      this.minField.value &&
      this.maxField.value &&
      typeof this.minField.value !== 'string' &&
      typeof this.maxField.value !== 'string'
    ) {
      return `$${this.minField.value} -> $${this.maxField.value}`;
    }

    if (this.minField.value && !this.maxField.value) {
      return `${this.minField.value} -> undefined`;
    } else if (!this.minField.value && this.maxField.value) {
      return `undefined -> ${this.maxField.value}`;
    } else if (this.minField.value && this.maxField.value) {
      return `${this.minField.value} -> ${this.maxField.value}`;
    }
    return null;
  }
}
