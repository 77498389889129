import { Component, OnInit } from '@angular/core';
import { ReportingService } from '../../reporting.service';
import {
  ExcelModule,
  GridModule,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Unit } from '../../../models/admin-models';
import {
  startWith,
  distinctUntilChanged,
  switchMap,
  map,
  catchError,
} from 'rxjs/operators';
import { AdminService } from '../../../admin/admin.service';
import { AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { saveAs } from '@progress/kendo-file-saver';
import { throwError } from 'rxjs';
import { NgxLoadingModule } from 'ngx-loading';

@Component({
  selector: 'app-unit-session-activity',
  standalone: true,
  imports: [
    GridModule,
    ExcelModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatInputModule,
    NgxLoadingModule,
  ],
  templateUrl: './unit-session-activity.component.html',
  styleUrl: './unit-session-activity.component.scss',
})
export class UnitSessionActivityComponent implements OnInit {
  startDate;
  endDate;
  unitSessionActivity: any;
  activityCode: string;
  filteredUnits;
  unitsControl = new FormControl();
  units: Unit[] = [] as Unit[];
  selectedUnit;
  loading: boolean;

  constructor(
    private rptSvc: ReportingService,
    private admnSrvc: AdminService
  ) {}

  ngOnInit(): void {
    this.getUnits();
    // this.getSessionActivity();
  }

  getUnits() {
    this.filteredUnits = this.unitsControl.valueChanges.pipe(
      startWith('%'),
      distinctUntilChanged(),
      switchMap(value => {
        if (value.length > 2) {
          return this._filterUnits(value);
        }
        return [];
      })
    );
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25';
    }
    const list = this.admnSrvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }

  getSessionActivity() {
    this.loading = true;
    const start = new Date(this.startDate).toISOString();
    const end = new Date(this.endDate).toISOString();
    const activityCode = this.activityCode || 'ALL';
    this.rptSvc
      .getUnitSessionActivity(start, end, activityCode, this.selectedUnit)
      .subscribe(
        res => {
          this.loading = false;
          let fileData;
          const filename = `Unit_Session_Activity-${new Date(this.startDate)
            .toDateString()
            .replaceAll(' ', '_')}-${new Date(this.endDate)
            .toDateString()
            .replaceAll(' ', '_')}.csv`;
          const reader = new FileReader();
          reader.readAsDataURL(res);

          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename);
          };
        },
        catchError(err => {
          this.loading = false;
          return throwError(err.error || 'Server error');
        })
      );
  }

  exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
}
