import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Global } from '../../../models/global.model';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RightBridgeApiService } from '../../../services/right-bridge-api.service';

@Component({
  selector: 'app-form-repcode',
  styleUrls: ['form-repCode.component.scss'],
  template: `
    <label
      class="section w-100"
      [class.mat-error]="formControl.errors && formControl.touched"
      *ngIf="props.label"
      [innerHTML]="props.label"
      ><sup *ngIf="props.required">*</sup></label
    >
    <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt"></p>
    <mat-form-field class="w-100">
      <mat-label>{{ loading ? 'Loading Rep Codes...' : to.label }}</mat-label>
      <input
        type="text"
        [placeholder]="props.placeholder || props.label"
        aria-label="Rep Code Search"
        matInput
        [formControl]="formControl"
        [matAutocomplete]="repAutocomplete"
        [required]="props.required"
        [formlyAttributes]="field"
        class="w-100"
        (blur)="checkSelection($event)"
      />
      <mat-autocomplete #repAutocomplete="matAutocomplete">
        @for (opt of repUnits; track opt) {
          <mat-option
            [value]="opt.repCode"
            matTooltip="({{ opt.repCode }})"
            matToolTipPosition="before"
            matTooltipShowDelay="750"
            >{{ opt.repName || opt.name }} ({{ opt.repCode }})</mat-option
          >
        }
      </mat-autocomplete>
      @if (
        formControl.hasError('required') && !formControl.hasError('regRequired')
      ) {
        <mat-error>This field is required</mat-error>
      }
      @if (formControl.hasError('regRequired')) {
        <mat-error
          >This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.</mat-error
        >
      }
    </mat-form-field>
  `,
})
export class FormlyRepCodeComponent extends FieldType implements OnInit {
  globals: Global;
  disabledDrop = false;
  appStatus = false;
  profile = '';
  loading = false;
  filteredReps: Observable<string[]>;
  repUnits: {
    repCode: string;
    repName: string;
    name?: string;
    unitId?: string;
    unitName?: string;
    unitType?: string;
  }[];
  rawRepCodes: {
    repCode: string;
    repName: string;
    name?: string;
    unitId?: string;
    unitName?: string;
    unitType?: string;
  }[];
  requestRepsSearch: boolean;
  lockRepField: boolean;

  constructor(
    private ss: SessionStorageService,
    private route: ActivatedRoute,
    private rbs: RightBridgeApiService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.globals = this.ss.get('globals');
    this.rawRepCodes = this.ss.get('repUnits');

    this.route.params.subscribe(params => {
      this.profile = params.id ? params.id : null;
    });
    if (
      this.globals &&
      this.globals.user.managerUnitCount &&
      this.globals.user.managerUnitCount > 0
    ) {
      this.requestRepsSearch = true;
    } else {
      this.requestRepsSearch = false;
    }

    this.lockRepField = !!this.model['ClientProfilingOut_RepCodeSelected'];

    if (!this.lockRepField) {
      //field has not been saved yet -> run normal set up logic
      if (this.requestRepsSearch) {
        //managed units user and not locked yet -> clear field and set up for searching
        this.formControl.setValue(null);
        this.setUpTypeAhead();
      } else {
        //no managed units and not locked yet -> set up dropdown options and
        //determine behavior based on number of repcodes available
        this.formControl.setValue(null);
        this.setUpDropDown();
      }
    } else {
      //field is saved already -> lock it and
      //don't change or set up anything
      this.formControl.disable();
      this.loading = false;
    }
  }

  setUpTypeAhead() {
    this.loading = false;
    this.formControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (value && typeof value === 'string' && value.length > 0) {
          return this._filterReps(value);
        } else {
          if (this.requestRepsSearch) {
            this.repUnits = [];
          } else {
            this.repUnits = this.rawRepCodes;
          }
          this.changeDetector.detectChanges();
        }
      });
  }

  private _filterReps(value: string) {
    if (this.requestRepsSearch) {
      this.rbs.getRepUnits(value).subscribe(res => {
        if (res.names && res.names.length > 0) {
          this.repUnits = res.names;
          this.changeDetector.detectChanges();
        } else if (!res || res.length === 0) {
          this.repUnits = [];
          this.changeDetector.detectChanges();
        }
      });
    } else {
      this.repUnits = this.rawRepCodes.filter(
        rep =>
          rep.repName.toLowerCase().includes(value.toLowerCase()) ||
          rep.repCode.toLowerCase().includes(value.toLowerCase())
      );
      if (this.repUnits.length === 0) {
        this.repUnits = [];
      }
      this.changeDetector.detectChanges();
    }
  }

  checkSelection(ev) {
    if (
      !this.repUnits ||
      !this.repUnits.find(repUnit => {
        return repUnit.repCode === this.formControl.value;
      })
    ) {
      this.formControl.setValue(null);
    }
  }

  setUpDropDown() {
    if (!this.rawRepCodes || this.rawRepCodes.length === 0) {
      this.rbs.getRepUnits().subscribe(res => {
        this.rawRepCodes = res;
        this.repCodeEval();
      });
    } else {
      this.repCodeEval();
    }
    this.setUpTypeAhead();
  }

  repCodeEval() {
    this.repUnits = this.rawRepCodes.map(x => {
      return { repCode: x.repCode, repName: x.repName };
    });
    if (this.repUnits.length < 1) {
      //if rep code list is empty set to raw value and lock
      //(error case but this is here to handle it. if there is
      //no raw value though, that's also an error)
      this.repUnits = [
        {
          repCode: this.props.rawValues['rawValue'],
          repName: this.props.rawValues['display'],
        },
      ];
      this.formControl.setValue(this.repUnits[0].repCode);
      this.formControl.disable();
    } else if (this.repUnits.length === 1) {
      //else if rep code list has only one value. set and lock
      this.formControl.setValue(this.repUnits[0].repCode);
      this.formControl.disable();
    } else {
      //else (not locked in yet and multiple codes to choose)
      this.formControl.setValue(null);
    }
    this.loading = false;
    this.changeDetector.detectChanges();
  }
}
