import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  signal,
} from '@angular/core';
import { FormlyFieldConfig, FieldArrayType } from '@ngx-formly/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { InvestmentWizardService } from '../../../investment-wizard/services/investment-wizard-service.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SessionStorageService } from '../../../services/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { WarnDialogComponent } from '../../../warn-dialog/warn-dialog.component';
import { FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Component({
  selector: 'app-form-grid',
  templateUrl: './form-grid.component.html',
  styleUrls: ['form-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormlyGridComponent
  extends FieldArrayType
  implements OnInit, OnDestroy
{
  constructor(
    private iws: InvestmentWizardService,
    private ss: SessionStorageService,
    private dialog: MatDialog,
    private snacky: MatSnackBar,
    private location: Location,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  @ViewChild('grid') public grid: GridComponent;
  @ViewChild('fundSearch') public fundSearch;

  public fileName: string;
  public file: File;
  columns;
  gridData;
  loading = false;
  noRecords = 'Please Add an Entry';
  searchOptions = null;
  showTemplates = false;
  placeholder = 'Select a template to apply';
  label = 'Select a template to apply';
  templates;
  firmTemplates;
  program;
  buttonOption;
  externalButtonOption: string;
  hideDelete = false;
  rights = this.ss.get('rights');
  totalInvestment = 0;
  assetAmount = '$0';
  mode = 'amount';
  totalPercent = 0;
  showTotals = false;
  totalsCondit;
  idVar;
  tspIdVar;
  parentIdVar;
  tspParentIdVar;
  needsRowId = false;
  rowIdPrefix;
  tspRowIdPrefix;
  rowId;
  tspRowId;
  investmentProgramLabel = '';
  fiveTwoNineSearch = false;
  plans529 = [];
  selected529 = { PlanId: null, PlanName: null };
  previous529 = { PlanId: null, PlanName: null };
  unsubscribe = new Subject();
  totalAmount = 0;
  clear529 = signal(false);
  uploadingFile = false;
  disableTemplates = true;
  canUseFundLookupPopulateBtn = false;
  fundLookupLoading = false;

  ngOnInit() {
    this.onPopulate(this.field);
    const vars = this.field.fieldArray['fieldGroup'].map(x => x.key);
    const parentVars = this.field.parent.fieldGroup.map(x => x.key);
    this.parentIdVar = parentVars.find((x: string) =>
      x.includes('SourceBridgeID')
    ) as string;

    this.tspParentIdVar = parentVars.find((x: string) =>
      x.includes('TSPSourceID')
    ) as string;

    this.idVar = vars.find((x: string) =>
      x.includes('SourceBridgeID')
    ) as string;
    this.tspIdVar = vars.find((x: string) =>
      x.includes('TSPSourceID')
    ) as string;

    if (this.idVar || this.tspIdVar) {
      this.needsRowId = true;
      this.rowId = this.field.parent.model[this.parentIdVar];
      this.rowIdPrefix = this.idVar ? this.idVar.split('_')[0] : this.idVar;
      this.tspRowId = this.field.parent.model[this.tspParentIdVar];
      this.tspRowIdPrefix = this.tspIdVar
        ? this.tspIdVar.split('_')[0]
        : this.tspIdVar;
    }

    this.buttonOption = this.field.fieldArray['props'].btnText;
    this.externalButtonOption = this.field.fieldArray['props'].externalBtnText;

    this.showTemplates =
      this.field.fieldArray['props'].showTemplates &&
      this.rights.includes('IWTemplates');
    this.canUseFundLookupPopulateBtn =
      this.field.parent.parent.parent.model.UnitExternalBridge_Include;
    this.searchOptions = this.field.fieldArray['props'].search;
    this.columns = this.field.fieldArray['fieldGroup'].filter(
      x => x.props.group === this.field.key
    );
    this.columns = this.columns.map(el => {
      const fld = {
        label: el.props.label || el.props.placeholder,
        key: el.key,
        help: el.props.help,
        visuallyHidden: el.className.includes('visual-hidden'),
        hide: el.expressions.hide(),
      };
      return fld;
    });
    this.totalsCondit = this.field.fieldArray['props']['showTotals'];
    this.hideDelete = this.field.fieldArray['props'].hideDelete;
    this.noRecords = this.field.props.placeholder
      ? this.field.props.placeholder
      : this.noRecords;

    if (this.showTemplates) {
      this.getTemplates(
        this.field.parent.model.ValidateProduct.InvestmentValidation_Program
      );
    }

    if (this.searchOptions) {
      if (this.searchOptions.fundtype.includes('529')) {
        this.fiveTwoNineSearch = true;

        const planId = this.field.parent.fieldGroup.find(
          x => x.key == 'Selected529PlansArray_PlanID'
        )?.formControl;
        const planName = this.field.parent.fieldGroup.find(
          x => x.key == 'Selected529PlansArray_PlanName'
        )?.formControl;
        this.selected529.PlanId = planId?.value;
        this.selected529.PlanName = planName?.value;
        this.previous529.PlanId = planId?.value;
        this.previous529.PlanName = planName?.value;
      }

      this.program =
        this.field.parent.model.ValidateProduct?.InvestmentValidation_Program;

      if (
        !this.program &&
        this.field.parent.parent.parent.model.ValidateProduct
      ) {
        this.program =
          this.field.parent.parent.parent.model.ValidateProduct
            ?.InvestmentValidation_Program;
      }

      if (
        this.field.parent.model.UNITPushToUI_NonFilteredPrograms &&
        this.field.parent.model.UNITPushToUI_NonFilteredPrograms.includes(
          this.field.parent.model.ValidateProduct.InvestmentValidation_Program
        )
      ) {
        this.program = null;
      }

      this.field.form.valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(z => {
          if (this.iws.getNeedToUpdateCalc()) {
            this.iws.setUpdateExternalCalcValue(true);
          }
          if (z.InvestmentValidation_PickPctOrAmt == 'P') {
            this.mode = 'percent';
          } else {
            this.mode = 'amount';
          }

          const newProgram = z.ValidateProduct?.InvestmentValidation_Program;
          if (newProgram && this.program != newProgram) {
            this.program = newProgram;
            this.getTemplates(newProgram);
          }

          if (
            this.field.parent.model.UNITPushToUI_NonFilteredPrograms &&
            this.field.parent.model.UNITPushToUI_NonFilteredPrograms.includes(
              this.field.parent.model.ValidateProduct
                .InvestmentValidation_Program
            )
          ) {
            this.program = null;
          }
          this.setAmounts(z);
        });
    }

    this.investmentProgramLabel =
      this.field.parent.model.ProgramDefinition_DisplayName &&
      this.field.parent.model.ProgramDefinition_ProgramID
        ? this.field.parent.model.ProgramDefinition_DisplayName[
            this.field.parent.model.ProgramDefinition_ProgramID.indexOf(
              this.field.parent.model.ValidateProduct
                .InvestmentValidation_Program
            )
          ]
        : '';

    if (this.field.key === 'exchange' || this.needsRowId) {
      this.field.fieldGroup.forEach((group, idx) => {
        group.fieldGroup.forEach(x => {
          if (x.key === 'CurrentAnnuityDetailsArray_IsExchange') {
            group['isExchange'] = x.formControl.value;
          }

          const fieldKey = x.key as string;

          if (fieldKey.includes('SourceBridgeID')) {
            if (!x.formControl.value) {
              x.formControl.setValue(`${this.rowIdPrefix}-${idx}`);
            }
          }

          if (fieldKey.includes('TSPSourceID')) {
            if (!x.formControl.value) {
              x.formControl.setValue(`TSPFundDetails-${idx}`);
            }
          }
        });
      });
    }

    this.form.valueChanges.pipe(tap()).subscribe(change => {
      if (change?.ValidateProduct?.InvestmentValidation_Program) {
        this.clear529.set(true);
        this.changeDetector.markForCheck();
      }
    });

    this.setAmounts(this.field.form.value);

    this.checkRequiredHeaders();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  add(fund?, amount?, percent?) {
    super.add();

    const row = this.model.length > 0 ? this.model.length - 1 : 0;

    const manuallyEnteredInvestment = this.columns.find(x =>
      x.key.includes('ManuallyEnteredInvestment')
    )?.key;
    const manuallyEnteredInvestmentField = this.getField(
      this.field,
      { key: manuallyEnteredInvestment },
      row
    );

    if (
      manuallyEnteredInvestmentField &&
      manuallyEnteredInvestmentField.formControl
    ) {
      manuallyEnteredInvestmentField.formControl.setValue(true);
      manuallyEnteredInvestmentField.model[manuallyEnteredInvestment] = true;
    }

    if (fund) {
      if (
        manuallyEnteredInvestmentField &&
        manuallyEnteredInvestmentField.formControl
      ) {
        manuallyEnteredInvestmentField.formControl.setValue(
          fund.ManuallyEnteredInvestment
        );
        manuallyEnteredInvestmentField.model[manuallyEnteredInvestment] =
          fund.ManuallyEnteredInvestment;
      }

      const fundId = this.columns.find(x => x.key.includes('FundID'))?.key;
      const fundIdField = this.getField(this.field, { key: fundId }, row);
      const fundName = this.columns.find(x => x.key.includes('Name'))?.key;
      const fundNameField = this.getField(this.field, { key: fundName }, row);
      const fundType = this.columns?.find(x => x.key.includes('FundType'))?.key;
      const fundTypeField = this.getField(this.field, { key: fundType }, row);
      const expenseRatio = this.columns.find(x =>
        x.key.includes('ExpenseRatio')
      )?.key;
      const expenseRatioField = this.getField(
        this.field,
        { key: expenseRatio },
        row
      );
      const tradeStatus = this.columns?.find(x => x.key.includes('TradeStatus'))
        ?.key;
      const tradeStatusField = this.getField(
        this.field,
        { key: tradeStatus },
        row
      );

      const shareClass = this.columns.find(x => x.key.includes('ShareClass'))
        ?.key;
      const shareClassField = this.getField(
        this.field,
        { key: shareClass },
        row
      );

      if (fundIdField && fundIdField.formControl) {
        fundIdField.formControl.setValue(fund.FundID);
        fundIdField.model[fundId] = fund.FundID;
      }

      if (fundNameField && fundNameField.formControl) {
        setTimeout(() => {
          fundNameField.formControl.setValue(fund.FundName);
        });
      }

      if (expenseRatioField && expenseRatioField.formControl) {
        setTimeout(() => {
          expenseRatioField.formControl.setValue(fund.ExpenseRatio);
        });
      }

      if (shareClassField && shareClassField.formControl) {
        setTimeout(() => {
          shareClassField.formControl.setValue(fund.ShareClass);
        });
      }

      if (tradeStatusField && tradeStatusField.formControl) {
        setTimeout(() => {
          tradeStatusField.formControl.setValue(fund.TradeStatus);
        });
      }

      if (fundTypeField && fundTypeField.formControl) {
        fundTypeField.formControl.setValue(fund.FundType);
        setTimeout(() => {
          fundTypeField.model[fundType] = fund.FundType;
        });
      }
    }

    if (amount) {
      const fundAmount = this.columns.find(x => x.key.includes('Amount')).key;
      const fundAmountField = this.getField(
        this.field,
        { key: fundAmount },
        row
      );

      if (fundAmountField) {
        setTimeout(() => {
          const setAmount =
            typeof amount === 'string' ? amount : amount.toString();
          fundAmountField.formControl.setValue(setAmount);
          fundAmountField.model[fundAmount] = amount;
        });
      }
      this.changeDetector.detectChanges();
      this.totalInvestment += +amount;
    }

    if (percent) {
      const fundPercent = this.columns.find(x => x.key.includes('Percent')).key;
      const fundPercentField = this.getField(
        this.field,
        { key: fundPercent },
        row
      );

      if (fundPercentField) {
        setTimeout(() => {
          fundPercentField.formControl.setValue(`${percent}%`);
        }, 500);
      }
      this.totalInvestment +=
        this.makeNumber(
          this.field.parent.model.ValidateProduct
            .InvestmentWizard_AssetAmount || 0
        ) *
        (percent / 100);
      this.totalPercent =
        this.makeNumber(
          this.field.parent.model.ValidateProduct
            .InvestmentWizard_AssetAmount || 0
        ) / this.totalInvestment;
    }
    this.focusCell(row, 1);

    if (this.needsRowId) {
      const fieldIdVar =
        this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y'
          ? this.tspIdVar
          : this.idVar;

      let existingRowValues = this.model.map(x => {
        try {
          if (this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y') {
            return +x[this.tspIdVar].split('_')[1];
          } else {
            return +x[this.idVar].split('_')[1];
          }
        } catch (error) {
          return null;
        }
      });

      // const rowId = this.getField(this.field, { key: fieldIdVar }, row);
      // if (rowId && rowId.formControl) {
      //   rowId.formControl.setValue(this.rowId);
      // }
      // this.model[row][this.idVar] = this.rowId; //had to add this in for some reason. May need it on other nested looping groups

      existingRowValues = existingRowValues.filter(
        x => x != null || x != undefined
      );

      const rowPrefix =
        this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y'
          ? this.tspRowIdPrefix
          : this.rowIdPrefix;
      const highestIdx =
        existingRowValues.length > 0 ? Math.max(...existingRowValues) : null;
      let newIdx = 0;

      if (this.parentIdVar || this.tspParentIdVar) {
        newIdx = +this.field.parent.key;
      } else {
        newIdx = !highestIdx || row > highestIdx ? row : highestIdx + 1;
      }

      const parentIdField =
        this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y'
          ? this.field.parent.get(this.tspParentIdVar)
          : this.field.parent.get(this.parentIdVar);
      const idField = this.getField(this.field, { key: fieldIdVar }, row);
      let idValue;

      if (this.field.parent.model.CurrentNonERISADetailsArray_TSP === 'Y') {
        if (!this.field.parent.model[this.tspParentIdVar]) {
          this.field.parent.model[this.tspParentIdVar] =
            `${rowPrefix}-${newIdx}`;
        }
        idValue = this.tspParentIdVar
          ? `${this.field.parent.model[this.tspParentIdVar]}`
          : `${rowPrefix}-${newIdx}`;
        parentIdField.formControl.setValue(`${rowPrefix}-${newIdx}`);
      } else {
        if (!this.field.parent.model[this.parentIdVar]) {
          this.field.parent.model[this.parentIdVar] = `${rowPrefix}-${newIdx}`;
          parentIdField.formControl.setValue(`${rowPrefix}-${newIdx}`);
        }

        idValue = this.parentIdVar
          ? `${this.field.parent.model[this.parentIdVar]}`
          : `${rowPrefix}-${newIdx}`;
      }

      idField?.formControl.setValue(idValue);
      this.field.model[row][fieldIdVar] = idValue;
    }
    this.checkRequiredHeaders();
  }

  checkRequiredHeaders() {
    const requiredFields = [];
    this.field.fieldArray['fieldGroup'].forEach(x => {
      x.props.formChecks &&
      x.props.formChecks.includes('required') &&
      x.props.label
        ? requiredFields.push(x.props.label)
        : null;
    });
    if (requiredFields && requiredFields.length > 0) {
      this.columns.forEach(column => {
        if (requiredFields.includes(column.label)) {
          column.label = column.label + ' *';
        }
      });
    }
  }

  onFileSelected(event) {
    if (
      event.target.files[0].type === 'text/csv' ||
      event.target.files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.file = event.target.files[0];

      if (this.file) {
        this.fileName = this.file.name;
      }
    } else {
      this.snacky.open(`Warning! File type not supported.`, 'Close', {
        duration: 4000,
      });
    }
  }

  completeCsvUpload() {
    const formData = new FormData();
    formData.append('Spreadsheet', this.file);
    this.uploadingFile = true;
    this.iws.uploadHoldingsFile(formData).subscribe(({ details, data }) => {
      if (details.FileUploadSuccess === true && data) {
        this.fileName = null;
        this.file = null;
        data.map(fundObj => {
          fundObj['ManuallyEnteredInvestment'] = false;
          this.add(fundObj, fundObj.Amount);
        });
        this.uploadingFile = false;
        if (
          details.InvestmentsErrorProcess.length <= 0 &&
          details.InvestmentsNotFound.length <= 0 &&
          !details.InvestmentFailureDetails
        ) {
          this.snacky.open(`File uploaded successfully.`, 'Close', {
            duration: 4000,
          });
        } else {
          this.dialog.open(WarnDialogComponent, {
            panelClass: 'warn-dialog',
            data: {
              headline: 'Warning',
              content: `${details.InvestmentFailureDetails} </br></br> Process: ${details.InvestmentsErrorProcess[0]} </br> Investment Not Found: ${details.InvestmentsNotFound[0]}`,
              confirm: 'OK',
            },
          });
        }
      }
    });
  }

  remove(idx, ev?, removeAll = false) {
    if (ev) {
      ev.preventDefault();
    }

    const localModel = [...this.model],
      deletedFund = localModel[idx],
      model = this.field.parent?.parent?.parent?.model,
      key = this.key as string,
      varSet = this.columns[0].key.split('_')[0],
      fundId = `${varSet}_FundID`,
      idVar = this.idVar?.split('_')[1],
      sourceBridge = `${varSet}_${idVar}`,
      fundIdx = model[key]
        ? model[key].findIndex(
            x =>
              deletedFund &&
              x &&
              x[fundId] == deletedFund[fundId] &&
              x[sourceBridge] == this.rowId
          )
        : -999;

    if (
      fundIdx > -1 &&
      this.field.fieldArray &&
      this.field.fieldArray['props'].nested
    ) {
      model[key].splice(fundIdx, 1);
    }

    const planId = this.field.parent.fieldGroup.find(
      x => x.key == 'Selected529PlansArray_PlanID'
    )?.formControl;

    if (planId) {
      this.selected529 = { PlanId: planId.value, PlanName: null };
    }

    const removeIdx = this.model[idx] ? idx : fundIdx;
    if (removeAll) {
      let i = this.model.length;
      while (i--) {
        super.remove(i);
      }
      this.model.length = 0;
    } else {
      super.remove(removeIdx);
    }
  }

  focusCell(row, cell) {
    if (this.grid) {
      this.grid.focusCell(row, cell);
    }
  }

  getField(
    field: FormlyFieldConfig,
    column,
    rowIndex: number
  ): FormlyFieldConfig {
    const fieldDef = field.fieldGroup[rowIndex]
      ? field.fieldGroup[rowIndex].fieldGroup.find(f => f.key === column.key)
      : null;
    return fieldDef ? fieldDef : { hide: false };
  }

  checkHighlightRow(field, rowIndex) {
    if (field.key && field.key === 'SelectedFunds') {
      if (
        field.fieldGroup[rowIndex].fieldGroup.find(
          fieldItem => fieldItem.key === 'SelectedFunds_FundID'
        ).props.highlightRow === true
      ) {
        return true;
      }
    }
    return false;
  }

  hideColumn(
    field: FormlyFieldConfig,
    column,
    rowIndex: number
  ): FormlyFieldConfig {
    let fieldVisible;
    if (field.formControl instanceof FormArray) {
      fieldVisible = field.fieldArray['fieldGroup']
        ? field.fieldArray['fieldGroup'].find(f => f.key === column.key)
        : false;
    } else {
      fieldVisible = field.fieldGroup[rowIndex]
        ? field.fieldGroup[rowIndex].fieldGroup.find(f => f.key === column.key)
        : false;
    }

    return fieldVisible.expressions.hide()
      ? fieldVisible.expressions.hide()
      : false;
  }

  getModel(model, rowIndex) {
    return model[rowIndex];
  }

  handleFund(ev) {
    this.add(ev.option.value);
  }

  checkApplyTemplate(ev) {
    let typeAmount = 0;
    if (
      this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === 3 ||
      this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === '3'
    ) {
      this.field.parent.model.CurrentPosition.forEach(investment => {
        if (
          investment &&
          (investment.CurrentPosition_Sell === 'true' ||
            investment.CurrentPosition_Sell) &&
          investment.CurrentPosition_SellAmount
        ) {
          typeAmount += this.makeNumber(investment.CurrentPosition_SellAmount);
        }
      });
    }

    if (
      (this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === '1' &&
        (!this.field.parent.model.ValidateProduct
          .InvestmentWizard_AssetAmount ||
          this.field.parent.model.ValidateProduct
            .InvestmentWizard_AssetAmount === '$0' ||
          this.field.parent.model.ValidateProduct
            .InvestmentWizard_AssetAmount === '0')) ||
      (this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === '2' &&
        (!this.field.parent.model.ValidateProduct
          .InvestmentWizard_AddMoneyAmount ||
          this.field.parent.model.ValidateProduct
            .InvestmentWizard_AddMoneyAmount === '$0' ||
          this.field.parent.model.ValidateProduct
            .InvestmentWizard_AddMoneyAmount === '0')) ||
      (this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === '3' &&
        typeAmount === 0)
    ) {
      const dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content:
            'You have chosen to apply a template and the entered investment amount is $0.<br/><br/>If you would like to apply the template anyway, press OK.<br/><br/>Otherwise, press Cancel, change the investment amount, and apply the template again.',
          confirm: 'OK',
        },
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          if (result == 'continue') {
            this.applyTemplate(ev);
          } else if (result == 'cancel') {
            ev.source.writeValue(null);
            ev.source.value = null;
          }
        });
    } else {
      this.applyTemplate(ev);
    }
  }

  applyTemplate(ev) {
    let investment = 0;

    if (
      (this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === 2 ||
        this.field.parent.model.ValidateProduct
          .InvestmentValidation_PurchaseType === '2') &&
      this.field.parent.model.ValidateProduct.InvestmentWizard_AddMoneyAmount
    ) {
      investment = this.makeNumber(
        this.field.parent.model.ValidateProduct.InvestmentWizard_AddMoneyAmount
      );
    } else if (
      this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === 3 ||
      this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === '3'
    ) {
      let totalInvestment = 0;
      this.field.parent.model.CurrentPosition.forEach(investment => {
        if (
          investment &&
          (investment.CurrentPosition_Sell === 'true' ||
            investment.CurrentPosition_Sell) &&
          investment.CurrentPosition_SellAmount
        ) {
          totalInvestment += this.makeNumber(
            investment.CurrentPosition_SellAmount
          );
        }
      });
      investment = totalInvestment;
    } else if (
      this.field.parent.model.ValidateProduct.InvestmentWizard_AssetAmount &&
      this.field.parent.model.ValidateProduct.InvestmentWizard_AssetAmount !==
        '$0'
    ) {
      investment = this.makeNumber(
        this.field.parent.model.ValidateProduct.InvestmentWizard_AssetAmount
      );
    }
    const funds = ev.value.funds;

    ev.source.writeValue(null);
    ev.source.value = null;

    let fundList = '';

    funds.forEach((x, i) => {
      if (!x.manual) {
        if (i != funds.length - 1) {
          fundList += `${x.ticker},`;
        } else {
          fundList += x.ticker;
        }
      } else if (
        x.manual &&
        this.field.parent.model.UNITPushToUI_ManualFundPrograms.includes(
          this.program
        )
      ) {
        const addFund = {
          FundName: x.name,
          FundID: x.ticker,
          ExpenseRatio: null,
          ManuallyEnteredInvestment: false,
        };
        const perc = x.percent * 100;
        const amount = investment * (perc / 100);
        this.add(addFund, amount.toFixed(2), perc);
      }
    });
    let fundInfo;
    const symbolList = [];

    this.iws
      .fundInfo(fundList)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(x => {
        fundInfo = x.results;
        fundInfo.forEach(el => {
          if (!symbolList.includes(el.Symbol)) {
            const fnd = {};
            const info = funds.find(x => {
              return x.ticker == el.Symbol;
            });

            const perc = info.percent * 100;
            const amount = investment * (perc / 100);

            fnd['FundName'] = el.FundName;
            fnd['FundID'] = info.ticker;
            fnd['ExpenseRatio'] = +el.ExpenseRatio;
            fnd['ManuallyEnteredInvestment'] = false;

            symbolList.push(el.Symbol);

            this.add(fnd, amount.toFixed(2), perc);
          }
        });
      });
  }

  getTemplates(program) {
    this.iws
      .getTemplates()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(x => {
        const userTemplates = x.results.UserTemplates;
        const firmTemplates = x.results.FirmTemplates;

        this.templates =
          userTemplates && userTemplates.length > 0
            ? userTemplates.filter(z => z.program == program)
            : [];
        this.firmTemplates =
          firmTemplates && firmTemplates.length > 0
            ? firmTemplates.filter(z => z.program == program)
            : [];

        this.disableTemplates =
          this.templates.length < 1 && this.firmTemplates.length < 1;
        this.changeDetector.detectChanges();
      });
  }

  get529() {
    // let programId =
    //   this.field.parent.model.ValidateProduct?.InvestmentValidation_Program;
    // if (!programId && this.field.parent.parent.parent.model.ValidateProduct) {
    //   programId =
    //     this.field.parent.parent.parent.model.ValidateProduct
    //       ?.InvestmentValidation_Program;
    // }
    // this.iws
    //   .get529(true, programId)
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe(x => {
    //     this.plans529 = [...x.data];
    //     this.changeDetector.detectChanges();
    //   });
  }

  handle529(plan) {
    this.selected529 = { ...plan };

    if (this.selected529) {
      const planId = this.field.parent.fieldGroup.find(
        x => x.key == 'Selected529PlansArray_PlanID'
      )?.formControl;
      const planName = this.field.parent.fieldGroup.find(
        x => x.key == 'Selected529PlansArray_PlanName'
      )?.formControl;
      let cancelSelection = false;

      if (
        planId.value &&
        planId.value != this.selected529.PlanId &&
        this.model &&
        this.model.length > 0
      ) {
        const dialogRef = this.dialog.open(WarnDialogComponent, {
          panelClass: 'warn-dialog',
          data: {
            headline: 'Warning',
            content:
              'If you choose to make this change your previous fund selections for this entry will be cleared.',
            confirm: 'OK',
          },
        });
        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(result => {
            if (result == 'continue') {
              planId.setValue(this.selected529.PlanId);
              planName.setValue(this.selected529.PlanName);
              this.remove(0, null, true);
            }
            if (result === 'cancel') {
              cancelSelection = true;
              planId.setValue(this.previous529.PlanId);
              planName.setValue(this.previous529.PlanName);
              this.selected529.PlanId = this.previous529.PlanId;
              this.selected529.PlanName = this.previous529.PlanName;
            }
          });
      } else {
        if (planId && !cancelSelection) {
          planId.setValue(this.selected529.PlanId);
          this.previous529.PlanId = this.selected529.PlanId;
        }
        if (planName && !cancelSelection) {
          planName.setValue(this.selected529.PlanName);
          this.previous529.PlanName = this.selected529.PlanName;
        }
      }
    }
    this.changeDetector.detectChanges();
  }

  makeNumber(num) {
    if (typeof num === 'number') {
      return num;
    } else {
      const formatted = num.replace('$', '').replaceAll(',', '');
      return +formatted;
    }
  }

  setAmounts(value) {
    this.assetAmount = value.ValidateProduct?.InvestmentWizard_AssetAmount;
    this.totalInvestment = 0;
    this.totalPercent = 0;

    if (value.SelectedFunds) {
      value.SelectedFunds.forEach(fnd => {
        if (fnd.SelectedFunds_Amount) {
          this.totalInvestment += this.makeNumber(fnd.SelectedFunds_Amount);
        } else if (
          typeof fnd.SelectedFunds_Percent === 'string' &&
          fnd.SelectedFunds_Percent.includes('%')
        ) {
          this.totalPercent =
            this.totalPercent +
            +fnd.SelectedFunds_Percent.replace('%', '') / 100;
        } else if (
          typeof fnd.SelectedFunds_Percent === 'string' &&
          !fnd.SelectedFunds_Percent.includes('%')
        ) {
          this.totalPercent =
            this.totalPercent + +fnd.SelectedFunds_Percent / 100;
        } else if (typeof fnd.SelectedFunds_Percent !== 'string') {
          if (fnd.SelectedFunds_Percent !== undefined) {
            this.totalPercent =
              this.totalPercent + +fnd.SelectedFunds_Percent / 100;
          }
        }
      });
    }

    if (this.field.parent.model.ValidateProduct) {
      this.calculateTotalAmount();
    }
  }

  calculateTotalAmount() {
    if (
      (this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === 2 ||
        this.field.parent.model.ValidateProduct
          .InvestmentValidation_PurchaseType === '2') &&
      this.field.parent.model.ValidateProduct.InvestmentWizard_AddMoneyAmount
    ) {
      this.totalAmount = this.makeNumber(
        this.field.parent.model.ValidateProduct.InvestmentWizard_AddMoneyAmount
      );
    } else if (
      this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === 3 ||
      this.field.parent.model.ValidateProduct
        .InvestmentValidation_PurchaseType === '3'
    ) {
      let totalDisplay = 0;
      this.field.parent.model.CurrentPosition.forEach(investment => {
        if (
          investment &&
          (investment.CurrentPosition_Sell === 'true' ||
            investment.CurrentPosition_Sell) &&
          investment.CurrentPosition_SellAmount
        ) {
          totalDisplay += this.makeNumber(
            investment.CurrentPosition_SellAmount
          );
        }
      });
      this.totalAmount = totalDisplay;
    } else if (
      this.field.parent.model.ValidateProduct.InvestmentWizard_AssetAmount
    ) {
      this.totalAmount = this.makeNumber(
        this.field.parent.model.ValidateProduct.InvestmentWizard_AssetAmount
      );
    }
    this.changeDetector.detectChanges();
  }

  downloadCsvTemplate(ev) {
    ev.preventDefault();
    window.open('assets/IW-Import-Holdings-template.csv', '_blank');
  }

  onLinkClick(ev) {
    window.open(
      `${this.location.prepareExternalUrl('/iw/product-shelf/')}${
        this.investmentProgramLabel
      }`,
      '_blank'
    );
  }

  fundLookup(): void {
    this.fundLookupLoading = true;
    const symbolList: string[] = [];

    this.model.forEach(fieldValue => {
      if (Object.keys(fieldValue).includes(`${this.rowIdPrefix}_FundID`)) {
        symbolList.push(fieldValue[`${this.rowIdPrefix}_FundID`]);
      }
    });
    this.iws.fundInfo(symbolList).subscribe(
      res => {
        res.results.forEach(resItem => {
          const rowToChange = this.field.fieldGroup.find(filterItem => {
            const field = filterItem.fieldGroup.find(fieldItem => {
              return fieldItem.key === `${this.rowIdPrefix}_FundID`;
            });
            return field.formControl.value === resItem.Symbol;
          });

          rowToChange.fieldGroup
            .find(col => col.key === `${this.rowIdPrefix}_Name`)
            .formControl.setValue(resItem.FundName);
          rowToChange.fieldGroup
            .find(col => col.key === `${this.rowIdPrefix}_ExpenseRatio`)
            .formControl.setValue(resItem.ExpenseRatio);
          rowToChange.fieldGroup
            .find(col => col.key === `${this.rowIdPrefix}_ShareClass`)
            .formControl.setValue(resItem.ShareClass);
        });
        this.fundLookupLoading = false;
        this.changeDetector.detectChanges();
      },
      () => {
        console.error('Network error');
        this.fundLookupLoading = false;
        this.changeDetector.detectChanges();
      }
    );
  }
}
