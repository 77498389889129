<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header> Product Summary Statistics </mat-card-header>
  <mat-card-content class="row">
    <div class="col-12">
      <mat-form-field class="mr-2">
        <mat-label>Solution</mat-label>
        <mat-select [(ngModel)]="solution" (selectionChange)="updateData()">
          @for (sltn of solutions; track sltn.value) {
            <mat-option [value]="sltn.value">{{ sltn.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (!limit30) {
        <mat-form-field class="mr-2">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startPicker"
            placeholder="Choose a start date"
            [formControl]="startDate"
            (dateChange)="updateData()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mr-2">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="endPicker"
            placeholder="Choose an end date"
            [formControl]="endDate"
            (dateChange)="updateData()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      }
    </div>
    <div class="col-12 data-container">
      @if (data) {
        <table class="content col-12 products">
          <thead class="mb-2">
            <th>Product</th>
            <th class="text-center">Total</th>
            <th class="large text-center">
              <mat-icon class="positive">check_circle</mat-icon>
            </th>
            <th class="large text-center">
              <mat-icon class="neutral">error</mat-icon>
            </th>
            <th class="large text-center">
              <mat-icon class="negative">remove_circle</mat-icon>
            </th>
          </thead>
          <tbody>
            @for (prdct of data.products; track prdct) {
              <tr>
                <td>{{ prdct.productName }}</td>
                <td class="text-center">
                  <span>{{ prdct.total }}</span>
                  <span>
                    {{ prdct.total / data.overall.total | percent: '1.2-2' }}
                  </span>
                </td>
                <td class="text-center">
                  <span>{{ prdct.positive }}</span>
                  <span>
                    {{
                      prdct.positive > 0
                        ? ((prdct.positive / prdct.total) * 100).toFixed(2)
                        : '0.00'
                    }}%
                  </span>
                </td>
                <td class="text-center">
                  <span>{{ prdct.neutral }}</span>
                  <span>
                    {{
                      prdct.neutral > 0
                        ? ((prdct.neutral / prdct.total) * 100).toFixed(2)
                        : '0.00'
                    }}%
                  </span>
                </td>
                <td class="text-center">
                  <span>{{ prdct.negative }}</span>
                  <span>
                    {{
                      prdct.negative > 0
                        ? ((prdct.negative / prdct.total) * 100).toFixed(2)
                        : '0.00'
                    }}%
                  </span>
                </td>
              </tr>
            }
            <tr>
              <td>Totals</td>
              <td class="text-center">
                <span>{{ data.overall.total }}</span> <span>100.00%</span>
              </td>
              <td class="text-center">
                <span>{{ data.overall.positive }}</span>
                <span>
                  {{
                    data.overall.positive > 0
                      ? (
                          (data.overall.positive / data.overall.total) *
                          100
                        ).toFixed(2)
                      : '0.00'
                  }}%
                </span>
              </td>
              <td class="text-center">
                <span>{{ data.overall.neutral }}</span>
                <span>
                  {{
                    data.overall.neutral > 0
                      ? (
                          (data.overall.neutral / data.overall.total) *
                          100
                        ).toFixed(2)
                      : '0.00'
                  }}%
                </span>
              </td>
              <td class="text-center">
                <span>{{ data.overall.negative }}</span>
                <span>
                  {{
                    data.overall.negative > 0
                      ? (
                          (data.overall.negative / data.overall.total) *
                          100
                        ).toFixed(2)
                      : '0.00'
                  }}%
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      }
    </div>
  </mat-card-content>
  <ngx-loading
    [show]="loading"
    [config]="{ fullScreenBackdrop: false }"
  ></ngx-loading>
</mat-card>
