import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGlobalsService } from '../services/app-globals.service';
import { CaseManagementComponent } from './case-management-shell/case-management.component';
import { AuthService } from '../services/auth-service.service';
import { CaseManagerComponent } from './case-manager/case-manager.component';

const routes: Routes = [
  {
    path: 'case-management',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        component: CaseManagementComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'CM',
          breadcrumb: 'Case Management',
        },
      },
      // {
      //   path: ':id',
      //   component: CaseManagementComponent,
      //   canActivate: [AuthService],
      //   data: {
      //     rightNeeded: 'CM',
      //     breadcrumb: 'Case Management',
      //   },
      // },
      {
        path: 'case-manager/:CRID',
        component: CaseManagerComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'CM',
          breadcrumb: 'Case Management',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CaseManagementRouting {}
