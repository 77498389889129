<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    System Usage
    <button
      mat-button
      color="white"
      class="download"
      (click)="exportExcel()"
      [disabled]="!selectedUnit"
    >
      <mat-icon>cloud_download</mat-icon>Download Excel File
    </button>
  </mat-card-header>
  <mat-card-content class="row">
    <div class="col-12">
      <mat-form-field class="mr-2">
        <input
          matInput
          placeholder="Select a Unit"
          [matAutocomplete]="unitAuto"
          [formControl]="unitCtrl"
          [(ngModel)]="selectedUnit"
        />
        <mat-autocomplete
          #unitAuto="matAutocomplete"
          (optionSelected)="selectUnit($event)"
          [displayWith]="displayFn"
        >
          @for (unit of filteredUnits | async; track unit.name) {
            <mat-option [value]="unit">
              {{ unit.name }}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <mat-button-toggle-group
        [(value)]="viewMode"
        name="toggle"
        (change)="getChart()"
        class="mr-2"
      >
        <mat-button-toggle value="chart">Chart</mat-button-toggle>
        <mat-button-toggle value="data">Data</mat-button-toggle>
      </mat-button-toggle-group>
      @if (viewMode === 'data' && !limit30) {
        <mat-form-field class="mr-2">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startPicker"
            placeholder="Choose a start date"
            [formControl]="startDate"
            (dateChange)="getData()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mr-2">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="endPicker"
            placeholder="Choose an end date"
            [formControl]="endDate"
            (dateChange)="getData(true)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      }
      @if (viewMode === 'chart') {
        <mat-form-field class="mr-2">
          <mat-label>Select a Time Period</mat-label>
          <mat-select [(ngModel)]="months" (selectionChange)="getChart()">
            @for (month of monthOptions; track month) {
              <mat-option [value]="month - 1"> {{ month }} Months </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>

    @if (!this.selectedUnit) {
      <div>Please Select a Unit to to See Data.</div>
    }

    @if (viewMode === 'chart' && this.selectedUnit) {
      <div class="chart-container mt-2 w-100">
        @if (chart.data) {
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chart.data"
          ></highcharts-chart>
        }
        <div class="chart-legend">
          @if (chart.legend) {
            <table>
              <thead>
                <tr>
                  @for (th of chart.legend.header; track th) {
                    @if (th.toLowerCase() !== 'color') {
                      <th>
                        {{ th.replace('_', ' ') }}
                      </th>
                    }
                  }
                </tr>
              </thead>
              <tbody>
                @for (tr of chart.legend.data; track tr) {
                  <tr>
                    @for (key of chart.legend.header; track key) {
                      <td>
                        {{ tr[key] }}
                      </td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          }
        </div>
      </div>
    }
    <div
      class="col-12 data-container mt-3"
      [hidden]="viewMode === 'chart' && this.selectedUnit"
    >
      @if (data) {
        <kendo-grid
          class="w-100 case-stats-activity-grid"
          #usageGrid="kendoGrid"
          [kendoGridBinding]="data.data"
          [style.maxHeight.px]="350"
        >
          @for (col of data.header; track col.DataField) {
            <kendo-grid-column
              [field]="col.DataField"
              [title]="col.Label"
              [width]="250"
            ></kendo-grid-column>
          }
          <kendo-grid-excel fileName="system-usage.xlsx"></kendo-grid-excel>
        </kendo-grid>
      }
    </div>
  </mat-card-content>
  <ngx-loading
    [show]="loading"
    [config]="{ fullScreenBackdrop: false }"
  ></ngx-loading>
</mat-card>
