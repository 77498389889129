import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AttestDialogComponent } from '../attest-dialog/attest-dialog.component';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { SessionStorageService } from '../services/session-storage.service';
import { EnvironmentService } from '../services/environment.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoComponent implements OnInit {
  private environment;
  params = {
    session: null,
    org: null,
    app: null,
    profile: null,
    search: null,
    bridgeId: null,
  };
  profileError = false;
  loginError = false;
  correlationID: string;
  queries = {
    correlationID: null,
  };

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService,
    private ss: SessionStorageService,
    private dialog: MatDialog
  ) {
    this.params = route.params['value'];
    this.queries = route.queryParams['value'];
  }

  ngOnInit() {
    this.environment = this.envSvc.get();

    let check = false;
    const paramsApp = this.params.app === 'se' ? this.params.app : 'rb';
    this.rbs
      .querySession(this.params.session, this.params.org, paramsApp, true)
      ?.subscribe(
        x => {
          check = x.results > 0;
          if (check) {
            this.ss.set('session', this.params.session);
            this.rbs.getGlobals().subscribe(data => {
              this.ss.set('rights', data.results.user.rights);
              const rights = data.results.user.rights;
              const hasAttested = data.results.user.hasAttested;

              if (!hasAttested && rights.includes('LicenseAttest')) {
                const dialogRef = this.dialog.open(AttestDialogComponent, {
                  panelClass: 'attest-dialog',
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result.data != 'cancel') {
                    if (this.queries.correlationID) {
                      this.correlationID = this.queries.correlationID.replace(
                        '\\',
                        ''
                      );
                      this.profileError = true;
                    } else {
                      this.rbs.setSso(
                        this.params.org,
                        this.params.session,
                        this.params.app,
                        this.params.profile,
                        this.params.search,
                        this.params.bridgeId
                      );
                    }
                  } else {
                    this.rbs.endSession().subscribe();
                  }
                });
              } else {
                if (this.queries.correlationID) {
                  this.correlationID = this.queries.correlationID.replace(
                    '\\',
                    ''
                  );
                  this.profileError = true;
                } else {
                  this.rbs.setSso(
                    this.params.org,
                    this.params.session,
                    this.params.app,
                    this.params.profile,
                    this.params.search,
                    this.params.bridgeId
                  );
                }
              }
            });
          } else {
            this.profileError = true;
            this.loginError = true;
          }
        },
        error => {
          console.error(error);
          this.profileError = true;
          this.loginError = true;
        }
      );
  }

  backToLanding() {
    this.router.navigate(['/']);
  }
}
