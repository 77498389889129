<div
  class="page-container"
  [ngClass]="{ 'sso-container': !profileError, unified: profileError }"
>
  @if (!profileError) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <div class="jumbotron mb-0">
      <div class="content row col-12">
        <div class="w-100">
          <img src="./assets/rightbridge-logo.png" alt="RightBridge" />
          <h1>Welcome to RightBridge</h1>
          <p>Please wait while we verify your credentials.</p>
        </div>
      </div>
    </div>
  } @else {
    <div class="failed-sso-content-container">
      <div class="failed-sso-decoration-container col-12 col-md-6 col-lg-7">
        <div class="content">
          <img
            id="Polygon-1"
            src="{{ './assets/rightbridge-logo.png' }}"
            class="unified-logo"
            alt="RightBridge"
          />
          <mat-icon
            id="Polygon-2"
            class="app-button-icon"
            svgIcon="aw_icon"
          ></mat-icon>
          <mat-icon
            id="Polygon-3"
            class="app-button-icon"
            svgIcon="pp_icon"
          ></mat-icon>
          <mat-icon
            id="Polygon-4"
            class="app-button-icon"
            svgIcon="iw_icon"
          ></mat-icon>
          <mat-icon
            id="Polygon-5"
            class="app-button-icon"
            svgIcon="lw_icon"
          ></mat-icon>
          <mat-icon
            id="Polygon-6"
            class="app-button-icon"
            svgIcon="cm_icon"
          ></mat-icon>

          <svg
            viewBox="0 0 837 1045"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
          >
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              sketch:type="MSPage"
            >
              <path
                d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
                fill="#fff"
                sketch:type="MSShapeGroup"
              ></path>
              <path
                d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
                fill="#7c9cc0"
                sketch:type="MSShapeGroup"
              ></path>
              <path
                d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
                fill="#939597"
                sketch:type="MSShapeGroup"
              ></path>
              <path
                d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
                fill="#e6e7e8"
                sketch:type="MSShapeGroup"
              ></path>
              <path
                d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
                fill="#939597"
                sketch:type="MSShapeGroup"
              ></path>
            </g>
          </svg>
        </div>
      </div>

      <div class="display-content col-6">
        <h1>SSO - Login Error</h1>

        @if (loginError) {
          <h3
            >There was an error logging you in from your source system. Please
            contact your system administrator.
          </h3>
        } @else if (correlationID) {
          <h3
            >There was an error loading the profile information from your source
            system. Please contact your system administrator.
          </h3>
          <p>Correlation ID: {{ correlationID }}</p>
          <div>
            <button mat-raised-button color="primary" (click)="backToLanding()"
              ><mat-icon>arrow_back</mat-icon>To Landing Page</button
            >
          </div>
        }
      </div>
    </div>
  }
</div>
