import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '../admin.service';
import { isEqual } from 'lodash';
import { VERSION } from '../../../environments/version';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-build-information',
  templateUrl: './build-information.component.html',
  styleUrls: ['./build-information.component.scss'],
})
export class BuildInformationComponent implements OnInit {
  @Input() updateVersions;
  @Input() modalView;

  appFiles = [
    {
      appName: 'pp',
      files: [],
    },
    {
      appName: 'aw',
      files: [],
    },
    {
      appName: 'iw',
      files: [],
    },
    {
      appName: 'li',
      files: [],
    },
    {
      appName: 'rb',
      files: [],
    },
    {
      appName: 'cm',
      files: [],
    },
    {
      appName: 'pm',
      files: [],
    },
  ];
  loadingAppFiles = true;
  newFileDetected = '';
  date = new Date();
  uiVersion: string = VERSION.version + '-' + this.date.toISOString();
  engine: string;
  globals: any;

  constructor(
    private admnSrvc: AdminService,
    private ss: SessionStorageService
  ) {}

  ngOnInit() {
    this.getFileVersions();

    const localGlobals = this.ss.get('globals');
    if (localGlobals) {
      this.globals = localGlobals ? localGlobals : {};
      this.engine = this.globals ? this.globals.Engine : null;
    }
  }

  getFileVersions() {
    this.newFileDetected = '';
    this.admnSrvc.getCheckSolutions().subscribe(res => {
      res['Solutions'].forEach(solution => {
        const solutionKey = Object.keys(solution)[0];
        const solutionName = solution[solutionKey].KBType;
        const appFileIndex = this.appFiles.findIndex(
          x => x.appName === solutionName.toLowerCase()
        );
        if (appFileIndex >= 0) {
          this.appFiles[appFileIndex]['files'] = [];
          if (!solution[solutionKey]?.UIConfigs.length) {
            this.newFileDetected = solutionName.toLowerCase();
          }
          this.appFiles[appFileIndex].files.push(
            {
              label: 'KB',
              fileStatus: solution[solutionKey]?.KBVersion,
            },
            {
              label: 'UIConfig',
              fileStatus: solution[solutionKey]?.UIConfigs.find(
                x =>
                  x.Format === 'JSON' &&
                  x.Type === solutionName.toLowerCase() + '-json'
              )?.Version,
            },
            {
              label: 'Unit Profile UIConfig',
              fileStatus: solution[solutionKey]?.UIConfigs.find(
                x =>
                  x.Format === 'JSON' &&
                  x.Type === solutionName.toLowerCase() + '-unit'
              )?.Version,
            },
            {
              label: 'Shared UIConfig',
              fileStatus: solution[solutionKey]?.UIConfigs.find(
                x => x.Format === 'JSON' && x.Type === 'shared-json'
              )?.Version,
            }
          );
        }
      });
    });
    this.loadingAppFiles = false;
  }
}
