<div class="page-container unified">
  <div class="no-cdr-required-content-container">
    <div class="no-cdr-required-decoration-container col-12">
      <div class="content">
        <svg
          viewBox="0 0 837 1045"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            sketch:type="MSPage"
          >
            <path
              d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
              fill="#fff"
              sketch:type="MSShapeGroup"
            ></path>
            <path
              d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
              fill="#7c9cc0"
              sketch:type="MSShapeGroup"
            ></path>
            <path
              d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
              fill="#939597"
              sketch:type="MSShapeGroup"
            ></path>
            <path
              d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
              fill="#e6e7e8"
              sketch:type="MSShapeGroup"
            ></path>
            <path
              d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
              fill="#939597"
              sketch:type="MSShapeGroup"
            ></path>
          </g>
        </svg>

        <div class="display-content">
          <h3>
            Based on your current selections, a RightBRIDGE Client Detail Report
            (CDR) is not required.
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
