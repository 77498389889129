import { Component, Input, OnInit } from '@angular/core';
import { ReportingService } from '../../reporting.service';
import { Application, Unit } from '../../../models/admin-models';
import { SessionStorageService } from '../../../services/session-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-report-request',
  templateUrl: './report-request.component.html',
  styleUrls: ['./report-request.component.scss'],
})
export class ReportRequestComponent implements OnInit {
  @Input() baseUrl;
  @Input() dashboardSettings;

  applications: Application[] = [];
  units: Unit[] = [] as Unit[];
  selectedApp;
  reportTypes: string[];
  selectedType;
  loading = false;
  startDate;
  endDate;
  reportDetails = '';
  email;
  reportOptions = [];

  constructor(
    private rptSvc: ReportingService,
    private snacky: MatSnackBar,
    private ss: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.ss.get('globals')?.Modules.forEach(el => {
      switch (el.toLowerCase()) {
        case 'aw':
          this.applications.push({
            name: 'Annuity Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'iw':
          this.applications.push({
            name: 'Investment Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'li':
          this.applications.push({
            name: 'Life Insurance Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'pp':
          this.applications.push({
            name: 'Product Profiler',
            value: el.toLowerCase(),
          });
          break;
        default:
          break;
      }
    });
  }

  formatReportOptions(reportType) {
    this.reportOptions = [];
    if (
      this.dashboardSettings[`${reportType}ReportOptions`] &&
      this.dashboardSettings[`${reportType}ReportDescriptions`]
    ) {
      this.dashboardSettings[`${reportType}ReportOptions`].map(
        (reportName, index) => {
          this.reportOptions.push({
            name: reportName,
            description:
              this.dashboardSettings[`${reportType}ReportDescriptions`][index],
          });
        }
      );
    }
  }

  submitReportRequest() {
    this.loading = true;
    const {
      user: { id, username },
      firmId,
    } = this.ss.get('globals');
    const reportData = {
      templateName: 'dashboardReportEmail',
      sendTo: ['sfaber@capitalrock.com', 'tcrane@capitalrock.com'],
      data: {
        module: this.selectedApp,
        startDate: this.startDate,
        endDate: this.endDate,
        reportSelected: this.selectedType,
        deliveryEmailOnceCompleted: this.email,
        submittedBy: {
          userId: id,
          userName: username,
          firmId,
        },
      },
    };

    if (this.reportDetails) {
      reportData['data']['reportDetails'] = this.reportDetails;
    }

    this.rptSvc.submitReportRequest(reportData).subscribe(
      () => {
        this.snacky.open('Report request submitted, thank you.', 'Close', {
          duration: 3000,
        });
        this.loading = false;
      },
      error => {
        if (error) {
          console.error('error: ', error);
          this.snacky.open(
            'Whoops something went wrong, please try again later!',
            'Close',
            {
              duration: 3000,
            }
          );
          this.loading = false;
        }
      }
    );
  }

  onAppChange() {
    this.reportTypes =
      this.dashboardSettings[`${this.selectedApp.toUpperCase()}ReportOptions`];

    this.formatReportOptions(this.selectedApp.toUpperCase());
  }

  dateRangeGovernor(direction) {
    if (this.startDate) {
      if (direction === 'min') {
        return DateTime.fromJSDate(new Date(this.startDate), {
          zone: 'utc',
        })
          .minus({ months: 3 })
          .toISO();
      }
      if (direction === 'max') {
        return DateTime.fromJSDate(new Date(this.startDate), {
          zone: 'utc',
        })
          .plus({ months: 3 })
          .toISO();
      }
    } else {
      return undefined;
    }
  }
}
