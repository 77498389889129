export const caseHistoryKey = [
  { label: 'Case ID', varName: 'CRID' },
  { label: 'Open Date', varName: 'StatusDate', valueMask: 'date' },
  { label: 'Status', varName: 'StatusName' },
];
export const tradeInfoKey = [
  { label: 'Client Name', varName: 'ClientFullName' },
  { label: 'Rep Code', varName: 'RepCode' },
  { label: 'Account #', varName: 'AccountNumDisplay' },
  { label: 'Unit', varName: 'UnitID' },
  { label: 'Trade Date', varName: 'TradeDate' },
  {
    label: 'Side',
    varName: 'Side',
    key: {
      B: 'Buy',
      S: 'Sell',
    },
  },
  {
    label: 'Status',
    varName: 'ApprovalStatus',
    key: {
      PEN: 'Pending',
      APP: 'Approved',
      SYS: 'System Approved',
      BLK: 'Bulk Approved',
      INP: 'In Process',
      REJ: 'Rejected',
      REO: 'Reopened',
    },
  },
];
export const productInfoKey = [
  { label: 'Product Type', varName: 'ProductType', varSet: 'Display' },
  { label: 'CUSIP', varName: 'CUSIP', varSet: 'Trade' },
  { label: 'Symbol', varName: 'Symbol', varSet: 'Trade' },
  { label: 'Name', varName: 'ProductName', varSet: 'Trade' },
  { label: 'Share Class', varName: 'CurrentShareClass', varSet: 'Display' },
  {
    label: 'Fund Family Name',
    varName: 'CurrentFundFamilyName',
    varSet: 'Display',
  },
  { label: 'UnitQuantity', varName: 'Quantity', varSet: 'Trade' },
  {
    label: 'Price',
    varName: 'SharePrice',
    valueMask: 'currency',
    varSet: 'Trade',
  },
  {
    label: 'Amount',
    varName: 'Amount',
    valueMask: 'currency',
    varSet: 'Trade',
  },
  {
    label: 'Sales Charge',
    varName: 'SalesCharge',
    valueMask: 'percent',
    varSet: 'Trade',
  },
  {
    label: 'Dealer Commission',
    varName: 'DealerCommission',
    valueMask: 'currency',
    varSet: 'Trade',
  },
];
export const suitabilityKey = [
  {
    label: 'Entity Type',
    varSet: 'AccountHolder',
    varName: 'ClientType',
    key: {
      P: 'Person',
      E: 'Entity',
    },
  },
  { label: 'SSN/TIN', varSet: 'AccountHolder', varName: 'MaskedSSN' },
  { label: 'Age', varSet: 'AccountHolder', varName: 'Age' },
  { label: 'State of Residence', varSet: 'Display', varName: 'ResidentState' },
  {
    label: 'Liquid Net Worth',
    varSet: 'AccountHolder',
    varName: 'LiquidNetWorth ',
    valueMask: 'currency',
  },
  {
    label: 'Net Worth',
    varSet: 'AccountHolder',
    varName: 'NetWorth',
    valueMask: 'currency',
  },
  {
    label: 'Income',
    varSet: 'AccountHolder',
    varName: 'Income',
    valueMask: 'currency',
  },
  {
    label: 'Tax Bracket',
    varSet: 'AccountHolder',
    varName: 'TaxBracket',
    valueMask: 'percent',
  },
  { label: 'Objective', varSet: 'Display', varName: 'Objective' },
  { label: 'Risk Tolerance', varSet: 'Display', varName: 'RiskTolerance' },
  { label: 'Time Horizon', varSet: 'Display', varName: 'TimeHorizon' },
];
export const accountKey = [
  { label: 'Account #', varSet: 'Account', varName: 'AccountNumber' },
  { label: 'Opened', varSet: 'Account', varName: 'OpenDate' },
  {
    label: 'Balance',
    varSet: 'Account',
    varName: 'Balance',
    valueMask: 'currency',
  },
  { label: 'Registration', varSet: 'Display', varName: 'Registration' },
  { label: 'Nature Source', varSet: 'Display', varName: 'NatureSource' },
  {
    label: 'Employee Owned',
    varSet: 'AccountHolder',
    varName: 'EmployeeOwned',
    key: {
      Y: 'Yes',
      N: 'No',
    },
  },
  { label: 'ROA Number', varSet: 'Account', varName: 'ROANumber' },
  {
    label: 'Cumulative Discount #',
    varSet: 'Trade',
    varName: 'CumulativeDiscountNum',
  },
  {
    label: 'Compensation Type',
    varSet: 'Account',
    varName: 'CompType',
    key: {
      C: 'Commission',
      F: 'Fee',
    },
  },
];
export const breakpointAnalysisKey = [
  { label: 'Group ID', varSet: 'AccountGroup', varName: 'GroupID' },
  { label: 'Description', varSet: 'AccountGroup', varName: 'Description' },
  {
    label: '# of Linked Accounts',
    varSet: 'Display',
    varName: 'GroupAccountCount',
  },
  { label: 'Total Value', varSet: 'Display', varName: 'GroupSumBalance' },
];
export const tradeHistoryKey = [
  { label: 'Account #', varName: 'AccountNumDisplay' },
  { label: 'Trade Date', varName: 'TradeDate', valueMask: 'date' },
  {
    label: 'Side',
    varName: 'Side',
    key: {
      B: 'Buy',
      S: 'Sell',
    },
  },
  {
    label: 'Product Type',
    varName: 'ProductType',
    key: {
      MF: 'Mutual Funds',
      VA: 'Variable Annuity',
      SPI: 'SPIA',
      FA: 'Fixed Annuity',
      RIL: 'RILA',
      FIA: 'Fixed Indexed Annuity',
    },
  },
  { label: 'CUSIP', varName: 'CUSIP' },
  { label: 'Product Name', varName: 'ProductName' },
  { label: 'Amount', varName: 'Amount', valueMask: 'currency' },
  { label: 'Rep Code', varName: 'RepCode' },
  {
    label: 'Commission ($)',
    varName: 'CommissionDollars',
    valueMask: 'currency',
  },
];
export const tradeApprovalKey = [
  {
    label: 'Date Updated',
    varName: 'DateUpdated',
    valueMask: 'date',
  },
  {
    label: 'Status',
    varName: 'NewStatus',
    key: {
      PEN: 'Pending',
      APP: 'Approved',
      SYS: 'System Approved',
      BLK: 'Bulk Approved',
      INP: 'In Process',
      REJ: 'Rejected',
      REO: 'Reopened',
    },
  },
  { label: 'Reviewer ID', varName: 'ReviewerID' },
];
export const tradePositionKey = [
  { label: 'Account Number', varName: 'AccountIDDisplay' },
  { label: 'CUSIP', varName: 'CUSIP' },
  { label: 'Name', varName: 'Name' },
  { label: 'Product Type', varName: 'ProductType' },
  { label: 'Share Class', varName: 'ShareClass' },
  { label: 'Quantity', varName: 'Quantity' },
  { label: 'Unit Value', varName: 'UnitValue' },
  { label: 'Position Value', varName: 'PositionValue' },
];
export const otherPositionKey = [
  { label: 'Account Number', varName: 'AccountIDDisplay' },
  { label: 'CUSIP', varName: 'CUSIP' },
  { label: 'Name', varName: 'Name' },
  { label: 'Product Type', varName: 'ProductType' },
  { label: 'Share Class', varName: 'ShareClass' },
  { label: 'Quantity', varName: 'Quantity' },
  { label: 'Unit Value', varName: 'UnitValue' },
  { label: 'Position Value', varName: 'PositionValue' },
];
export const annuityPositionKey = [
  { label: 'Carrier', varName: 'Carrier' },
  { label: 'Product Code', varName: 'ContractCode' },
  { label: 'Name', varName: 'Name' },
  { label: 'Type', varName: 'Type' },
  { label: 'Share Class', varName: 'ShareClass' },
  { label: 'Term', varName: 'Term' },
  { label: 'Purchase Date', varName: 'PurchaseDate' },
  { label: 'Current Value', varName: 'CurrentValue' },
];
export const associatedCaseKey = [
  { label: 'Case ID', varSet: 'RightBRIDGECase', varName: 'CaseID' },
  { label: 'Score Class', varSet: 'RightBRIDGECase', varName: 'ScoreClass' },
  {
    label: 'Date Generated',
    varSet: 'RightBRIDGECase',
    varName: 'DateGenerated',
    valueMask: 'date',
  },
  {
    label: 'Form CRS Delivery Date',
    varSet: 'ClientProfiling',
    varName: 'CRSDate',
  },
];
export const productModulesKey = {
  pp: 'Product Profiler',
  aw: 'Annuity Wizard',
  iw: 'Investment Wizard',
  li: 'Life Insurance Wizard',
};
export const scoreClassKey = {
  Positive: 'check_circle',
  Warning: 'error',
  Negative: 'remove_circle',
};
