<div class="col-12 chart-container mt-3">
  <h4>System Usage</h4>
  @if (chart.data) {
    <highcharts-chart
      [Highcharts]="Highcharts"
      [options]="chart.data"
      class="col-12 pull-left"
    ></highcharts-chart>
  }
</div>

<ngx-loading
  [show]="loading"
  [config]="{
    fullScreenBackdrop: false
  }"
></ngx-loading>
