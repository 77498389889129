import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import {
  FormControl,
  FormArray,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BeaconService } from '../../services/beacon.service';
import { BeaconServiceV2 } from '../../services/beaconv2.service';
import { Factsheet } from '../factsheet';
import { DateTime } from 'luxon';
import {
  SelectedRider,
  SelectedProduct,
  ExchangeData,
} from '../../models/aw-models';
import { Decimal } from 'decimal.js-light';
import { SessionStorageService } from '../../services/session-storage.service';
import { DtccService } from '../dtcc.service';
import { UnifiedFlowService } from '../../unified-flow/unified.service';
import { MaskService } from '../../services/mask.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Global } from '../../models/global.model';
import { UniversalSearchService } from '../../universal-search/services/universal-search.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
@Component({
  selector: 'app-annuity-dialog',
  templateUrl: './annuity-dialog.component.html',
  styleUrls: ['./annuity-dialog.component.scss'],
})
export class AnnuityDialogComponent implements OnInit {
  private DEFAULT_ERROR_MESSAGE =
    'Sorry, there are no results for this search.';
  maxDate = new Date();
  purchDate;
  companies = [];
  filteredCompanies: Observable<string[]>;
  products = [];
  filteredProducts: Observable<string[]>;
  selectedProduct = <SelectedProduct>{};
  riders = [];
  filteredRiders;
  selectedRider = <SelectedRider>{};
  mande = false;
  selectedMande = {};
  livingBenefit = [];
  livingRiders = [];
  noProductsReturned = false;
  noProductDetails = false;
  selectedPolicyDetails;
  dtccWindowClosed = false;
  deathRiders = [];
  private globals: Global;

  accumulationRiders = [];

  rawAgeBands;
  ageBands = [];
  ageBandOptions = [];

  charges = {};

  rollups = {};

  cases = [];

  form = new UntypedFormGroup({
    dateCtrl: new FormControl(
      { value: null, disabled: false },
      Validators.required
    ),
    compCtrl: new FormControl(
      { value: null, disabled: true },
      Validators.required
    ),
    prodCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    riderCtrl: new FormControl(
      { value: null, disabled: true },
      Validators.required
    ),
    prodMandeCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    purchasedLivingCtrl: new FormControl(
      { value: false, disabled: true },
      Validators.required
    ),
    shareClassCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    purchasedDeathCtrl: new FormControl(
      { value: false, disabled: true },
      Validators.required
    ),
    purchasedAccumulationCtrl: new FormControl(
      { value: false, disabled: true },
      Validators.required
    ),
    livingRiderCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    deathRiderCtrl: new FormControl(
      { value: '', disabled: false },
      Validators.required
    ),
    accumulationRiderCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    livingRollupCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    livingChargesCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    deathRollupCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    deathChargesCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    accumulationChargesCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    casesCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    singleJointBandCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    bandOptionCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    bandCtrl: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
  });

  exchangeData: ExchangeData = {} as ExchangeData;

  canSelectClass = false;
  canSelectMande = false;
  canSelectRider = false;
  canSelectLiving = false;
  canSelectDeath = false;
  canSelectAccumulation = false;
  canSelectBands = false;
  canSelectBandOption = false;
  canSelectJoint = false;
  canSelectCases = false;

  showData = false;

  prodSearched = false;
  noProds = true;

  loading = false;
  simpleLoading = false;
  simpleLoadingMessage = '';
  profile;

  productDetails = {};

  shareClassOptionsDef = {
    A: 1,
    B: 2,
    C: 3,
    L: 4,
    Bonus: 5,
    'No Share Class': 6,
    O: 7,
    I: 1020500007,
    X: 8,
  };

  livingChargesOptions = [];

  shareClassOptions = [];
  dtccEnabled = false;
  noShowBeacon = true;
  beaconEnabled = true;
  startDtcc = false;
  producerNum;
  submittedProducerNum;
  lastName;
  policyNum;
  searchResults = [];
  showAdvisorQuestion = true;
  noResults = false;
  errorMessage = this.DEFAULT_ERROR_MESSAGE;
  mandeOptions;
  dateMask = this.maskSvc.dateMaskSpecs();
  DTCCSearchByNPNOnly = false;

  public gridData;
  public ogData;
  searchForNPNs = false;
  autoPopulateNPN = false;
  userID: string;
  NPNs: { RepCode: string; Name: string; NPN: string; UnitId: string }[] = null;
  firm: string;
  homeOfficeUser: boolean;
  filteredReps: object[];
  repCtrl = new FormControl();
  selectedRep: string;
  repDropdownMessage: string;
  repDropdownNoResults: boolean;
  producerNumBtnTxt: string;
  producerNumBtnIcon: string;
  repMultiSelectSubtitle: string;
  searchUserID: string;
  DTCCNotePrompt: string;
  company: any;
  loadingSignal = this.unfFlowSvc.loadingSignal;

  constructor(
    private dialogRef: MatDialogRef<AnnuityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private beaconSrc: BeaconService,
    private beaconSrcV2: BeaconServiceV2,
    private fs: Factsheet,
    private ss: SessionStorageService,
    private dtccSvc: DtccService,
    private unfFlowSvc: UnifiedFlowService,
    private maskSvc: MaskService,
    private usServ: UniversalSearchService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const rights = this.ss.get('rights');
    this.globals = this.ss.get('globals');
    this.firm = this.ss.get('firm');

    if (rights) {
      this.dtccEnabled = rights.includes('DTCCDataAccess');
      this.beaconEnabled = rights.includes('CreateSamlResponse');
    }
    this.checkDtccWindow();

    this.DTCCSearchByNPNOnly = this.globals
      ? this.globals.GlobalsGroup.UnitInfo.DTCCSearchByNPNOnly
      : false;

    if (this.DTCCSearchByNPNOnly) {
      this.producerNumBtnTxt = 'Search';
      this.producerNumBtnIcon = 'search';
    } else {
      this.producerNumBtnTxt = 'Submit';
      this.producerNumBtnIcon = 'check';
    }

    this.userID = this.globals ? this.globals.user.id : null;

    if (this.globals) {
      if (this.globals.GlobalsGroup.UnitInfo.DTCCNotePrompt) {
        this.DTCCNotePrompt = this.globals.GlobalsGroup.UnitInfo.DTCCNotePrompt;
      }
      if (
        this.globals.GlobalsGroup.ControlAWSettings &&
        this.globals.GlobalsGroup.ControlAWSettings.AutoPopulateNPN
      ) {
        this.autoPopulateNPN = true;
      } else {
        this.autoPopulateNPN = false;
      }
      if (this.globals.user.managerUnitCount > 0) {
        this.homeOfficeUser = true;
        this.getRepCodes();
      } else if (this.globals.user.managerUnitCount === 0) {
        this.homeOfficeUser = false;
      }
    }

    this.selectedProduct.selectedRider = {
      living: {},
      death: {},
      accumulation: {},
    };

    this.selectedProduct.riders = {
      living: [],
      death: [],
      other: [],
      accumulation: [],
    };

    if (!this.dtccEnabled) {
      this.triggerLookup(false);
    } else if (this.dtccEnabled && !this.beaconEnabled) {
      this.showAdvisorQuestion = false;
      this.startDtcc = true;
    }
    this.filteredCompanies = this.form.controls['compCtrl'].valueChanges.pipe(
      startWith(''),
      map(value =>
        this._filterCompanies(typeof value === 'string' ? value : '')
      )
    );
    this.filteredProducts = this.form.controls['prodCtrl'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterProducts(typeof value === 'string' ? value : ''))
    );
  }

  getRepCodes() {
    this.repCtrl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (value && typeof value === 'string') {
          return this._filterReps(value);
        }
      });
  }

  displayReps(val) {
    return val ? `${val.name}` : '';
  }

  searchRepForNPNs() {
    if (this.selectedRep) {
      this.submittedProducerNum = false;
      this.searchResults = null;
      this.noResults = false;
      this.producerNum = null;
      this.policyNum = null;
      this.ogData = null;
      this.gridData = null;
      this.selectedPolicyDetails = null;
      this.repMultiSelectSubtitle = null;
      this.NPNs = null;
      this.repCtrl.disable();
      this.searchUserID = this.selectedRep;
      this.populateNPNDropdown();
    } else {
      console.error('No rep selected');
    }
  }

  selectRep(ev: MatAutocompleteSelectedEvent) {
    this.selectedRep = ev.option.value.id;
  }

  private _filterReps(value: string) {
    this.usServ.userSearch(value).subscribe(res => {
      if (res && res.names.length > 0) {
        this.repDropdownNoResults = false;
        this.filteredReps = res.names;
      } else if (!res || res.names.length === 0) {
        this.repDropdownNoResults = true;
        this.repDropdownMessage = 'No Results';
        this.changeDetector.detectChanges();
      }
    });
  }

  checkDtccWindow() {
    const dayOfWeek = DateTime.now().setZone('EST').toFormat('cccc');
    const timeOfDay = DateTime.now().setZone('EST').toFormat('HH');

    // If the current time is between Saturday 12am to Sun 2pm EST then show the message
    if (
      (dayOfWeek === 'Saturday' || dayOfWeek === 'Sunday') &&
      timeOfDay > 12 &&
      timeOfDay < 14
    ) {
      this.dtccWindowClosed = true;
    } else {
      this.dtccWindowClosed = false;
    }
  }

  setPurchDate(ev) {
    this.clearData('date');
    this.purchDate = ev.value;
    this.form.get('compCtrl').enable();
  }

  triggerLookup(isRep = false) {
    this.showAdvisorQuestion = false;
    if (!isRep) {
      this.getCompaniesList();
      this.startDtcc = false;
      this.noShowBeacon = false;
    } else {
      if (this.autoPopulateNPN && !this.homeOfficeUser) {
        this.searchUserID = this.userID;
        this.populateNPNDropdown();
      }
      this.noShowBeacon = true;
      this.startDtcc = true;
    }
  }
  populateNPNDropdown() {
    this.simpleLoadingMessage =
      'Populating National Producer Number Dropdown Options...';
    this.simpleLoading = true;
    this.dtccSvc.getNPNs(this.searchUserID).subscribe(
      res => {
        if (res.npns.length > 0) {
          this.NPNs = res.npns;
          this.noResults = false;
          this.repMultiSelectSubtitle =
            'This Financial Professional has access to multiple National Producer Numbers (NPN). Please select the correct NPN to proceed.';
        } else if (res.npns.length === 0) {
          this.NPNs = [];
          this.noResults = true;
          this.errorMessage =
            'Sorry, no results found for this representative.';
          this.repMultiSelectSubtitle = null;
        }
        this.repCtrl.enable();
        this.simpleLoading = false;
        if (this.NPNs.length === 1) {
          this.producerNum = this.NPNs[0].NPN;
          this.repMultiSelectSubtitle = null;
          this.submitOrSearchProducerNumber();
        }
      },
      error => {
        if (error) {
          console.error('error: ', error);
          this.simpleLoading = false;
        }
      }
    );
  }

  stepBack() {
    this.showAdvisorQuestion = true;
    this.noShowBeacon = true;
    this.startDtcc = false;
    this.submittedProducerNum = false;
    this.searchResults = null;
    this.noResults = false;
    this.producerNum = null;
    this.policyNum = null;
    this.ogData = null;
    this.gridData = null;
    this.selectedPolicyDetails = null;
    this.filteredReps = null;
    this.selectedRep = null;
    this.repMultiSelectSubtitle = null;
    this.NPNs = null;
    this.searchUserID = null;
    this.repCtrl.setValue(null);
    this.company = null;
  }

  getCompaniesList() {
    this.noProductDetails = false;
    this.beaconSrcV2.getCompanies().subscribe(y => {
      this.companies = y['companies'].sort((a, b) => {
        const nameA = a.carrier_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.carrier_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    });
  }

  getProducts(ev) {
    const company = ev.option;
    this.company = company.value;
    this.noProductDetails = false;
    this.loading = true;
    this.products = [];
    if (this.exchangeData[`${this.data.varset}_Carrier`]) {
      this.clearData('company');
    }

    this.prodSearched = true;
    this.showData = true;
    this.selectedProduct = <SelectedProduct>{};
    this.exchangeData[`${this.data.varset}_Carrier`] =
      company.value.carrier_name;

    this.beaconSrcV2
      .getProductData(company.value.company_id, this.purchDate)
      .subscribe(x => {
        this.products = [...x];
        if (this.products && this.products.length > 0) {
          this.noProductsReturned = false;
          this.form.get('prodCtrl').enable();
        } else {
          this.noProductsReturned = true;
        }
        this.loading = false;
      });
  }

  getShareClass(product) {
    let classes = [];

    if (product.share_class) {
      classes = product.share_class.split(',');
    } else if (product.product_charges.surrender_charges) {
      product.product_charges.surrender_charges.forEach(x => {
        const className =
          x.share_class_name != 'N/A'
            ? x.share_class_name.replace('Class ', '')
            : null;

        if (className) {
          classes.push(className);
        }
      });
      classes = [...new Set(classes)];
    }

    return classes;
  }

  selectProduct(ev) {
    const product = ev.option;
    this.noProductDetails = false;
    this.clearData('product');

    this.exchangeData[`${this.data.varset}_PurchaseDate`] = new Date(
      this.form.controls.dateCtrl.value
    ).toISOString();
    this.exchangeData[`${this.data.varset}_PurchaseYear`] = DateTime.fromJSDate(
      new Date(this.form.controls.dateCtrl.value)
    ).toFormat('yyyy');
    this.showData = true;
    this.selectedProduct = product.value;
    //only see these 3 types from beacon

    switch (this.selectedProduct['product_type']) {
      case 'fixed':
        this.exchangeData[`${this.data.varset}_ProductType`] = 'FA2';
        break;
      case 'indexed':
        this.exchangeData[`${this.data.varset}_ProductType`] = 'IA';
        break;
      case 'variable':
        this.exchangeData[`${this.data.varset}_ProductType`] = 'VA';
        break;
      default:
        break;
    }

    this.exchangeData[`${this.data.varset}_PlanName`] =
      this.selectedProduct['product_name'];
    this.exchangeData.prodId = this.selectedProduct['product_key'];

    this.loading = true;
    this.beaconSrcV2
      .getProductDetails(
        this.selectedProduct['product_key'],
        this.selectedProduct['product_type'],
        this.data.profile,
        this.exchangeData[`${this.data.varset}_Carrier`],
        this.company.company_id
      )
      .pipe(
        catchError(err => {
          this.noProductDetails = true;
          this.loading = false;
          return of(err);
        })
      )
      .subscribe(y => {
        if (!y.type) {
          this.productDetails = y;
          this.selectedProduct.contract = y['HistoricalAnnuityProductContract'];
          const shareClass =
            y.HistoricalAnnuityProductContract.ShareClass &&
            y.HistoricalAnnuityProductContract.ShareClass.includes(',')
              ? y.HistoricalAnnuityProductContract.ShareClass.split(',')
              : [y.HistoricalAnnuityProductContract.ShareClass];

          if (shareClass.length > 1) {
            this.shareClassOptions = [];
            this.canSelectClass = true;
            this.form.controls.shareClassCtrl.enable();
            this.selectedProduct.contract['share_class'] = shareClass;
            shareClass.forEach(el => {
              this.shareClassOptions.push({
                display: el,
                value: this.shareClassOptionsDef[el],
              });
            });
          } else if (
            this.selectedProduct.contract['share_class'] &&
            this.selectedProduct.contract['share_class'].length == 1
          ) {
            if (y.HistoricalAnnuityProductSurrenderCharges[0]) {
              this.exchangeData[`${this.data.varset}_ShareClass`] =
                y.HistoricalAnnuityProductSurrenderCharges[0].RBClassName;
              this.exchangeData[`${this.data.varset}_DisplayShareClass`] =
                y.HistoricalAnnuityProductSurrenderCharges[0].ClassName;
            }
            const charges = y.HistoricalAnnuityProductSurrenderCharges;
            this.getCdsc(charges, shareClass);
          } else {
            this.canSelectClass = false;
            this.form.controls.shareClassCtrl.disable();
            if (
              shareClass.length == 1 &&
              y.HistoricalAnnuityProductSurrenderCharges[0]
            ) {
              this.exchangeData[`${this.data.varset}_ShareClass`] =
                y.HistoricalAnnuityProductSurrenderCharges[0].RBClassName;
              this.exchangeData[`${this.data.varset}_DisplayShareClass`] =
                y.HistoricalAnnuityProductSurrenderCharges[0].ClassName;
            }
            const charges = y.HistoricalAnnuityProductSurrenderCharges;
            this.getCdsc(charges);
          }
          if (
            y.HistoricalAnnuityProductCharges &&
            y.HistoricalAnnuityProductCharges.length > 0
          ) {
            if (y.HistoricalAnnuityProductCharges.length === 1) {
              this.mande =
                y.HistoricalAnnuityProductCharges &&
                y.HistoricalAnnuityProductCharges.length > 0 &&
                y.HistoricalAnnuityProductCharges[0].MEA;
              if (this.mande) {
                this.exchangeData[`${this.data.varset}_BrokeragePercentFee`] =
                  this.formatPercent(y.HistoricalAnnuityProductCharges[0].MEA);
              }
            } else if (y.HistoricalAnnuityProductCharges.length > 1) {
              this.formatMandE(y.HistoricalAnnuityProductCharges);
            }
            this.exchangeData[`${this.data.varset}_IRAManualExpenseRatio`] =
              y.HistoricalAnnuityProductCharges[0].AvgSubaccountFee;
          }

          this.livingBenefit =
            this.selectedProduct.contract['withdrawal'] || null;
          this.selectedProduct.riders = { ...y['riders'] };

          this.riders = !this.riders ? [] : this.riders;

          Object.keys(this.selectedProduct.riders).forEach(rdrTypes => {
            this.selectedProduct.riders[rdrTypes].forEach(el => {
              this.riders.push(el);
            });
          });

          if (this.riders.length > 0) {
            this.riders.forEach((rider, i) => {
              if (i == 0) {
                this.exchangeData[`${this.data.varset}_RidersAvailable`] = '';
                this.exchangeData[`${this.data.varset}_RidersAvailable`] +=
                  rider.Name;
              } else {
                this.exchangeData[`${this.data.varset}_RidersAvailable`] +=
                  ', ' + rider.Name;
              }
            });

            if (!this.selectedProduct.riders) {
              this.selectedProduct.riders = {
                living: [],
                death: [],
                other: [],
                accumulation: [],
              };
            }

            this.riders.forEach(el => {
              if (
                el.Type.toUpperCase() == 'GMWB' ||
                el.Type.toUpperCase() == 'GMIB'
              ) {
                const opt = el;
                if (!opt.Key) {
                  opt.Key = '999999';
                }
                this.livingRiders.push(opt);
                if (!this.selectedProduct.riders.living) {
                  this.selectedProduct.riders.living = [];
                }
                this.selectedProduct.riders.living.push(opt);
              } else if (el.Type.toUpperCase() == 'GMAB') {
                const opt = el;
                if (!opt.Key) {
                  opt.Key = '999999';
                }
                this.accumulationRiders.push(opt);
                if (!this.selectedProduct.riders.accumulation) {
                  this.selectedProduct.riders.accumulation = [];
                }
                this.selectedProduct.riders.accumulation.push(opt);
              } else if (el.Type.toUpperCase() == 'DEATH BENEFIT') {
                const opt = el;
                if (!opt.Key) {
                  opt.Key = '999999';
                }
                this.deathRiders.push(opt);
                if (!this.selectedProduct.riders.death) {
                  this.selectedProduct.riders.death = [];
                }
                this.selectedProduct.riders.death.push(opt);
              }
            });

            if (this.livingRiders.length > 0) {
              this.canSelectLiving = true;
              this.form.controls.purchasedLivingCtrl.enable();
              this.form.patchValue({
                purchasedLivingCtrl: 'false',
                purchasedDeathCtrl: 'false',
                purchasedAccumulationCtrl: 'false',
              });
            }

            if (this.deathRiders.length > 0) {
              this.canSelectDeath = true;
              this.form.controls.purchasedDeathCtrl.enable();
              this.form.patchValue({
                purchasedLivingCtrl: 'false',
                purchasedDeathCtrl: 'false',
                purchasedAccumulationCtrl: 'false',
              });
            }

            if (this.accumulationRiders.length > 0) {
              this.canSelectAccumulation = true;
              this.form.controls.purchasedAccumulationCtrl.enable();
              this.form.patchValue({
                purchasedLivingCtrl: 'false',
                purchasedDeathCtrl: 'false',
                purchasedAccumulationCtrl: 'false',
              });
            }
          } else {
            this.canSelectLiving = false;
            this.form.controls.purchasedLivingCtrl.disable();
            this.form.controls.livingRiderCtrl.disable();
            this.canSelectDeath = false;
            this.form.controls.purchasedDeathCtrl.disable();
            this.form.controls.deathRiderCtrl.disable();
            this.canSelectAccumulation = false;
            this.form.controls.purchasedAccumulationCtrl.disable();
            this.form.controls.accumulationRiderCtrl.disable();
          }
        }
        this.loading = false;
      });
  }

  selectShareClass(share) {
    const shareClass = share.value;
    this.exchangeData[`${this.data.varset}_ShareClass`] = shareClass.toString();
    this.exchangeData[`${this.data.varset}_DisplayShareClass`] =
      this.productDetails['HistoricalAnnuityProductSurrenderCharges'].ClassName;

    const charges =
      this.productDetails['HistoricalAnnuityProductSurrenderCharges'];
    this.getCdsc(charges, share.display);
  }

  selectRider(rider, type) {
    this.selectedProduct.selectedRider = <SelectedRider>{};
    this.clearData(type + '-rider');

    switch (type) {
      case 'living':
        this.selectedRider['living'] = this.riders.find(
          x => x.Key == rider.value
        );
        break;
      case 'death':
        this.selectedRider['death'] = this.riders.find(
          x => x.Key == rider.value
        );
        break;
      case 'accumulation':
        this.selectedRider['accumulation'] = this.riders.find(
          x => x.Key == rider.value
        );
        break;
    }

    const filtered = this.selectedProduct.riders[type].find(z => {
      return z.Key == rider.value;
    });
    this.selectedProduct.selectedRider[type] = filtered;

    if (type == 'living') {
      this.exchangeData[`${this.data.varset}_HasWithdrawalBenefit`] = 'Y';
      this.exchangeData[`${this.data.varset}_LivingBenefitName`] =
        this.selectedProduct.selectedRider[type].Name;
      this.exchangeData[`${this.data.varset}_HistoricalLBDescription`] =
        this.selectedProduct.selectedRider[type].BenefitDescription;
      this.exchangeData[`${this.data.varset}_HistoricalLBOtherInfo`] =
        this.selectedProduct.selectedRider[type].HistoricalLBOtherInfo;
      this.exchangeData[`${this.data.varset}_HistoricalLBBenefitBaseGeneral`] =
        this.selectedProduct.selectedRider[type].BenefitBaseGeneral;
    } else if (type == 'death') {
      this.exchangeData[`${this.data.varset}_HasDeathBenefit`] = 'Y';
      this.exchangeData[`${this.data.varset}_DeathBenefitName`] =
        this.selectedProduct.selectedRider[type].Name;
      this.exchangeData[`${this.data.varset}_HistoricalDBDescription`] =
        this.selectedProduct.selectedRider[type].Description;
      this.exchangeData[`${this.data.varset}_HistoricalDBOtherInfo`] =
        this.selectedProduct.selectedRider[type].HistoricalDBOtherInfo;
      this.exchangeData[`${this.data.varset}_HistoricalDBBenefitBaseGeneral`] =
        this.selectedProduct.selectedRider[type].BenefitBaseGeneral;
      this.exchangeData[`${this.data.varset}_HistoricalDBSpousalContinuation`] =
        this.selectedProduct.selectedRider[type].SpousalContinuationOption;
      this.exchangeData[`${this.data.varset}_HistoricalDBCancellationOption`] =
        this.selectedProduct.selectedRider[type].Cancellation;
      this.exchangeData[`${this.data.varset}_HistoricalDBWithdrawalType`] =
        this.selectedProduct.selectedRider[type].PartialWithdrawals;
      this.exchangeData[`${this.data.varset}_HistoricalDBLivesType`] =
        this.selectedProduct.selectedRider[type].BasedOnLife;
    } else if (type == 'accumulation') {
      this.exchangeData[`${this.data.varset}_HasGMAB`] = 'Y';
      this.exchangeData[`${this.data.varset}_GMABName`] =
        this.selectedProduct.selectedRider[type].Name;
      this.exchangeData[`${this.data.varset}_GMABRiderMandE`] =
        `${this.formatPercent(
          this.selectedProduct.selectedRider[type].Case[0].CurrentCharge
        )}%`;
      this.exchangeData[`${this.data.varset}_GMABNotes`] = `${
        this.selectedProduct.selectedRider[type].Description
          ? this.selectedProduct.selectedRider[type].Description
          : ''
      }`;
      if (this.selectedProduct.selectedRider[type].Cancellation) {
        this.exchangeData[`${this.data.varset}_GMABNotes`] +=
          `GMAB Cancellation: ${this.selectedProduct.selectedRider[type].Cancellation}`;
      }

      if (this.selectedProduct.selectedRider[type].Termination) {
        this.exchangeData[`${this.data.varset}_GMABNotes`] +=
          `GMAB Termination: ${this.selectedProduct.selectedRider[type].Termination}`;
      }
      if (this.selectedProduct.selectedRider[type].SubsequentPremium) {
        this.exchangeData[`${this.data.varset}_GMABNotes`] +=
          `Subsequent Premium: ${this.selectedProduct.selectedRider[type].SubsequentPremium}`;
      }
      if (this.selectedProduct.selectedRider[type].PartialWithdrawals) {
        this.exchangeData[`${this.data.varset}_GMABNotes`] +=
          `Partial Withdrawals: ${this.selectedProduct.selectedRider[type].PartialWithdrawals}`;
      }
      if (this.selectedProduct.selectedRider[type].EffectChangeOwnership) {
        this.exchangeData[`${this.data.varset}_GMABNotes`] +=
          `Effect Change Ownership: ${this.selectedProduct.selectedRider[type].EffectChangeOwnership}`;
      }
      if (this.selectedProduct.selectedRider[type].Case[0].InitialResetDesc) {
        this.exchangeData[`${this.data.varset}_GMABNotes`] +=
          `Initial Reset Description: ${this.selectedProduct.selectedRider[type].Case[0].InitialResetDesc}`;
      }
    }

    //         CurrentAnnuityDetailsArray.HasGMAB = "Y"
    // CurrentAnnuityDetailsArray.GMABName = "name" at the rider level (not the case attribute level)
    // CurrentAnnuityDetailsArray.GMABRiderMandE = "charge"
    // CurrentAnnuityDetailsArray.GMABFeeName= "name" at the case attribute level (not the rider level)
    // CurrentAnnuityDetailsArray.GMABNotes= combine the following: "notes", "gmab_cancellation", "gmab_termination", "subsequent_premium", "partial_withdrawals", "effect_change_ownership" AND from case attribute "initial_reset_desc".

    if (
      Array.isArray(this.selectedProduct.selectedRider[type].Case) &&
      type == 'death'
    ) {
      this.charges[type] = this.selectedProduct.selectedRider[type].Case.map(
        el => {
          let dsplyText = el.CurrentCharge
            ? this.formatPercent(el.CurrentCharge) + '%'
            : '';
          dsplyText += el.MaxCharge
            ? ' - ' + this.formatPercent(el.MaxCharge) + '%'
            : '';

          const chargeVal = { ...el };

          if (el.Name && dsplyText && dsplyText.length > 1) {
            dsplyText += `, ${el.Name}`;
          } else {
            chargeVal.CurrentCharge = 0.0;
            dsplyText += `0.0% - ${el.Name}`;
          }
          return { display: dsplyText, value: chargeVal };
        }
      );

      if (this.charges['death'].length > 0) {
        this.form.controls.deathChargesCtrl.enable();
      }
    } else if (
      this.selectedProduct.selectedRider[type].Charge &&
      this.selectedProduct.selectedRider[type].Charge.length > 0
    ) {
      let option;
      this.charges[type] = this.selectedProduct.selectedRider[
        type
      ].Charge.flatMap(el => {
        let dsplyText = el.CurrentCharge
          ? this.formatPercent(el.CurrentCharge) + '%'
          : '';

        if (el.BasedOn) {
          dsplyText += ` ${el.BasedOn}`;
        }
        if (el.Notes) {
          dsplyText += `, ${el.Notes}`;
        }

        if (el.CurrentCharge) {
          option = { display: dsplyText, value: el };
          return option;
        } else {
          return [];
        }
      });

      if (type == 'accumulation' && this.charges[type].length > 0) {
        this.form.controls.accumulationChargesCtrl.enable();
      } else if (this.charges[type].length > 0) {
        this.livingChargesOptions = this.charges['living'];
        this.form.controls.livingChargesCtrl.enable();
      }
    } else if (
      Array.isArray(this.selectedProduct.selectedRider[type].Case) &&
      !this.selectedProduct.selectedRider[type].Charge
    ) {
      let option;
      this.charges[type] = this.selectedProduct.selectedRider[
        type
      ].Case.flatMap(el => {
        let dsplyText = el.CurrentCharge
          ? this.formatPercent(el.CurrentCharge) + '%'
          : '';

        if (el.BasedOn) {
          dsplyText += ` ${el.BasedOn}`;
        }
        if (el.Notes) {
          dsplyText += `, ${el.Notes}`;
        }

        if (el.CurrentCharge) {
          option = { display: dsplyText, value: el };
          return option;
        } else {
          return [];
        }
      });

      if (type == 'accumulation' && this.charges[type].length > 0) {
        this.form.controls.accumulationChargesCtrl.enable();
      } else if (this.charges[type].length > 0) {
        this.livingChargesOptions = this.charges['living'];
        this.form.controls.livingChargesCtrl.enable();
      }
    }

    if (
      this.selectedProduct.selectedRider[type].Rollup &&
      this.selectedProduct.selectedRider[type].Rollup.length > 0
    ) {
      this.exchangeData[`${this.data.varset}_HistoricalLBRollUpsAvailable`] =
        '';
      this.selectedProduct.selectedRider[type].Rollup.forEach((el, i) => {
        this.exchangeData[`${this.data.varset}_HistoricalLBRollUpsAvailable`] +=
          i > 0
            ? ', ' + this.formatPercent(el.BonusSG) + '%'
            : this.formatPercent(el.BonusSG) + '%';
      });
      this.rollups[type] = this.selectedProduct.selectedRider[type].Rollup.map(
        z => {
          return {
            display: `${z.BonusSG ? this.formatPercent(z.BonusSG) : ''}%`,
            value: z,
          };
        }
      );

      this.form.controls.livingRollupCtrl.enable();
    }

    if (
      this.selectedProduct.selectedRider[type].AgeBandInfo &&
      this.selectedProduct.selectedRider[type].AgeBandInfo.length > 1
    ) {
      this.buildBandOptions(
        this.selectedProduct.selectedRider[type].AgeBandInfo
      );
    }

    const cases = this.selectedProduct.selectedRider[type].Case;
    if (type === 'living' && cases && cases.length > 1) {
      this.buildCaseOptions(cases);
    } else if (cases && cases.length == 1) {
      this.selectCase({ source: 'singleCase', value: cases[0] });
    }
  }

  selectRollup(val) {
    this.exchangeData[`${this.data.varset}_DeferralBonus`] = val.value.BonusSG
      ? `${this.formatPercent(val.value.BonusSG)}%`
      : '';
    this.exchangeData[`${this.data.varset}_HistoricalLBDeferralLength`] =
      val.value.MaxRollupYears;
    this.exchangeData[`${this.data.varset}_HistoricalLBRollUpType`] =
      val.value.SimpleComp;
    this.exchangeData[`${this.data.varset}_BenefitBaseIncreaseType`] =
      val.value.RBSimpleComp;
  }

  selectCharges(val, type) {
    if (type == 'death') {
      this.exchangeData[`${this.data.varset}_DBBasisCost`] =
        val.value.CurrentCharge || val.value.CurrentCharge == 0
          ? `${this.formatPercent(val.value.CurrentCharge)}%`
          : '';
    } else if (type == 'living') {
      this.exchangeData[`${this.data.varset}_RiderMandE`] =
        val.value.CurrentCharge || val.value.CurrentCharge == 0
          ? `${this.formatPercent(val.value.CurrentCharge)}%`
          : '';
    } else if (type == 'accumulation') {
      this.exchangeData[`${this.data.varset}_GMABRiderMandE`] =
        val.value.CurrentCharge || val.value.CurrentCharge == 0
          ? `${this.formatPercent(val.value.CurrentCharge)}%`
          : '';
    }
  }

  buildBandOptions(raw) {
    this.rawAgeBands = [...raw];

    raw.forEach(band => {
      let ageVal, ageDisp;

      if ((band.AgeBand1 || band.AgeBand2) && band.AgeBand1 == band.AgeBand2) {
        ageVal = band.AgeBand1;
        ageDisp = band.AgeBand1;
      } else if (band.AgeBand1 && !band.AgeBand2) {
        ageVal = band.AgeBand1;
        ageDisp = band.AgeBand1;
      } else if (band.AgeBand2 && !band.AgeBand1) {
        ageVal = band.AgeBand2;
        ageDisp = band.AgeBand2;
      } else if (band.AgeBand2 && band.AgeBand1) {
        ageVal = band.AgeBand1 + '-' + band.AgeBand2;
        ageDisp = Number(band.AgeBand1) + ' - ' + Number(band.AgeBand2);
      } else if (band.Others) {
        ageVal = band.Others;
        ageDisp = band.Others;
      } else if (band.SingleLife && !band.JointLife) {
        ageVal = band.SingleLife;
        ageDisp = `${this.formatPercent(band.SingleLife)}%`;
      } else if (band.JointLife && !band.SingleLife) {
        ageVal = band.JointLife;
        ageDisp = `${this.formatPercent(band.JointLife)}%`;
      }

      if (band.SingleLife) {
        ageDisp += `, Single Life: ${this.formatPercent(band.SingleLife)}%`;
      }

      if (band.JointLife) {
        ageDisp += `, Joint Life: ${this.formatPercent(band.JointLife)}%`;
      }

      if (
        band.AgeBand2 ||
        band.AgeBand1 ||
        (band.SingleLife && !band.JointLife) ||
        (band.JointLife && !band.SingleLife) ||
        band.Others
      ) {
        const newBand = band;
        newBand['newValue'] = ageVal;
        this.ageBands.push({ display: ageDisp, value: newBand });
      }

      this.form.controls.bandCtrl.enable();

      let optVal, optDisp;
      const existing = this.ageBandOptions.find(x => {
        if (band.Notes) {
          const equ = band.Notes == x.value;
          const len = band.Notes.length > 0;
          return equ && len;
        } else if (band.Others) {
          const equ = band.Others == x.value;
          const len = band.Others.length > 0;
          return equ && len;
        }
        return band.Notes;
      });

      if (!existing && band.Notes && band.Notes.length > 0) {
        //
        //
        // Add another others option
        //
        //
        optVal = band.Notes;
        optDisp = band.Notes;
        this.ageBandOptions.push({ display: optDisp, value: optVal });
        this.canSelectBandOption = true;
        this.form.controls.bandOptionCtrl.enable();
      }

      if (!this.canSelectJoint && band.SingleLife && band.JointLife) {
        this.canSelectJoint = true;
        this.form.controls.singleJointBandCtrl.enable();
      }
    });

    this.canSelectBands = true;
    this.form.controls.bandCtrl.enable();
    this.form.controls.singleJointBandCtrl.enable();
  }

  selectBand(clearMode) {
    this.clearData(clearMode);

    if (
      this.selectedProduct.selectedRider.living['Rollup'] &&
      this.selectedProduct.selectedRider.living['Rollup'].length > 0
    ) {
      this.form.controls.livingRollupCtrl.enable();
    }

    if (this.charges['living'] && this.charges['living'].length > 0) {
      this.form.controls.livingChargesCtrl.enable();
    }

    let band1: string, band2: string, singleJointFilter;

    if (
      this.form.controls.singleJointBandCtrl.value &&
      this.canSelectBandOption &&
      this.form.controls.bandOptionCtrl.value &&
      this.form.controls.bandCtrl.value
    ) {
      band1 = this.form.controls.bandCtrl.value.AgeBand1;
      band2 = this.form.controls.bandCtrl.value.AgeBand2;
      const val = this.rawAgeBands.find(x => {
        if (band2) {
          return (
            x.AgeBand1 == band1 &&
            x.notes == this.form.controls.bandOptionCtrl.value
          );
        }

        const ageBandsExist =
          (x.AgeBand1 && x.AgeBand1.length > 0) ||
          (x.AgeBand2 && x.AgeBand2.length > 0);
        const othersExist = x.others && x.others.length > 0;

        const ageBandsValid =
          ageBandsExist &&
          x.AgeBand1 == band1 &&
          x.AgeBand2 == band2 &&
          x.notes == this.form.controls.bandOptionCtrl.value;
        const othersValid =
          othersExist && x.others == this.form.controls.bandCtrl.value;

        if (ageBandsValid || othersValid) {
          return true;
        }
      });

      if (this.form.controls.singleJointBandCtrl.value) {
        this.exchangeData[`${this.data.varset}_HistoricalLBLivesType`] =
          this.form.controls.singleJointBandCtrl.value == 'SingleLife'
            ? 'Single'
            : 'Joint';
      }

      if (val && val[this.form.controls.singleJointBandCtrl.value]) {
        this.exchangeData[`${this.data.varset}_HistoricalLBWithdrawalPercent`] =
          `${this.formatPercent(
            val[this.form.controls.singleJointBandCtrl.value]
          )}%`;
      } else if (val && val['SingleLife']) {
        this.exchangeData[`${this.data.varset}_HistoricalLBWithdrawalPercent`] =
          `${this.formatPercent(val['SingleLife'])}%`;
      } else if (val && val['JointLife']) {
        this.exchangeData[`${this.data.varset}_HistoricalLBWithdrawalPercent`] =
          `${this.formatPercent(val['JointLife'])}%`;
      }
    } else if (
      this.form.controls.singleJointBandCtrl.value &&
      !this.canSelectBandOption &&
      !this.form.controls.bandOptionCtrl.value &&
      this.form.controls.bandCtrl.value
    ) {
      band1 = this.form.controls.bandCtrl.value.AgeBand1;
      band2 = this.form.controls.bandCtrl.value.AgeBand2;
      const val = this.rawAgeBands.find(x => {
        if (band2) {
          return x.AgeBand1 == band1;
        } else if (
          !band2 &&
          !this.form.controls.bandCtrl.value.AgeBand1 &&
          !this.form.controls.bandCtrl.value.AgeBand2 &&
          this.form.controls.bandCtrl.value.SingleLife
        ) {
          return this.form.controls.bandCtrl.value.SingleLife == band1;
        } else if (
          !band2 &&
          !this.form.controls.bandCtrl.value.AgeBand1 &&
          !this.form.controls.bandCtrl.value.AgeBand2 &&
          this.form.controls.bandCtrl.value.JointLife
        ) {
          return this.form.controls.bandCtrl.value.JointLife == band1;
        }
        return x.AgeBand1 == band1 && x.AgeBand2 == band2;
      });

      if (val && val[this.form.controls.singleJointBandCtrl.value]) {
        this.exchangeData[`${this.data.varset}_HistoricalLBWithdrawalPercent`] =
          `${this.formatPercent(
            val[this.form.controls.singleJointBandCtrl.value]
          )}%`;
      } else if (val && val['SingleLife']) {
        this.exchangeData[`${this.data.varset}_HistoricalLBWithdrawalPercent`] =
          `${this.formatPercent(val['SingleLife'])}%`;
      } else if (val && val['JointLife']) {
        this.exchangeData[`${this.data.varset}_HistoricalLBWithdrawalPercent`] =
          `${this.formatPercent(val['JointLife'])}%`;
      }
    } else if (
      this.form.controls.singleJointBandCtrl.value &&
      this.canSelectBandOption &&
      this.form.controls.bandOptionCtrl.value &&
      !this.form.controls.bandCtrl.value
    ) {
      singleJointFilter =
        this.form.controls.singleJointBandCtrl.value == 'single_life'
          ? 'single life'
          : 'joint life';

      this.livingChargesOptions = [];
      this.charges['living'].forEach(chrg => {
        const notes = chrg.value.Notes ? chrg.value.Notes.toLowerCase() : null;
        const value = this.form.controls.bandOptionCtrl.value.toLowerCase();

        const nameMatch = notes ? notes.includes(value) : false;
        const singleJointMatch =
          chrg.value.BasedOn && chrg.value.BasedOn.length > 0
            ? chrg.value.BasedOn.toLowerCase() == singleJointFilter
            : true;
        const nullValue =
          !chrg.value.Notes || !this.form.controls.bandOptionCtrl.value;

        if ((nameMatch && singleJointMatch) || nullValue) {
          this.livingChargesOptions.push(chrg);
        }
      });

      this.ageBands = this.ageBands.filter(age => {
        return age.value.Notes == this.form.controls.bandOptionCtrl.value;
      });

      this.form.controls.bandCtrl.enable();
    }
  }

  buildCaseOptions(data) {
    data.forEach(el => {
      this.cases.push({ value: el, display: el.Name });
    });
    this.canSelectCases = true;
    this.form.controls.casesCtrl.enable();
  }

  selectCase(data) {
    this.exchangeData[`${this.data.varset}_HistoricalDBFeeCalcFrequency`] =
      data.value.MaxChargeFreq;
    this.exchangeData[`${this.data.varset}_HistoricalDBFeeAccount`] =
      data.value.ChargeBasisName;
    this.exchangeData[`${this.data.varset}_HistoricalDBFee`] =
      data.value.CurrentCharge;
  }

  benefitChange(ev, type) {
    if (ev.value == 'true' && type == 'death') {
      this.form.controls.deathRiderCtrl.enable();
    } else if (ev.value == 'true' && type == 'living') {
      this.form.controls.livingRiderCtrl.enable();
    } else if (ev.value == 'true' && type == 'accumulation') {
      this.form.controls.accumulationRiderCtrl.enable();
    }

    if (ev.value == 'false') {
      this.clearData('benefit-' + type);
    }
  }

  validateFields(fg) {
    Object.keys(fg.controls).forEach(field => {
      const control = fg.get(field);
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
        control.markAsTouched();
        if (control.invalid) {
          this.form.setErrors({ required: true });
        }
      } else if (
        control instanceof UntypedFormGroup ||
        control instanceof FormArray
      ) {
        this.validateFields(control);
      }
    });
    fg.updateValueAndValidity();
    fg.markAsTouched();
  }

  saveData() {
    this.validateFields(this.form);
    if (this.form.valid) {
      if (
        this.selectedProduct.product_type == 'indexed' ||
        this.selectedProduct.product_type == 'fixed'
      ) {
        this.exchangeData[`${this.data.varset}_ShareClass`] = 6;
        this.exchangeData[`${this.data.varset}_BrokeragePercentFee`] = 0;
      }
      if (
        !this.selectedProduct.riders ||
        !Object.keys(this.selectedProduct.riders).length
      ) {
        this.exchangeData[`${this.data.varset}_RidersAvailable`] = 'None';
      }
      if (
        (!this.exchangeData[`${this.data.varset}_HasWithdrawalBenefit`] ||
          this.exchangeData[`${this.data.varset}_HasWithdrawalBenefit`] !=
            'Y') &&
        this.form.controls.purchasedLivingCtrl.value != 'true'
      ) {
        this.exchangeData[`${this.data.varset}_HasWithdrawalBenefit`] = 'N';
      }

      if (
        (!this.exchangeData[`${this.data.varset}_HasDeathBenefit`] ||
          this.exchangeData[`${this.data.varset}_HasDeathBenefit`] != 'Y') &&
        this.form.controls.purchasedDeathCtrl.value != 'true'
      ) {
        this.exchangeData[`${this.data.varset}_HasDeathBenefit`] = 'N';
      }

      this.exchangeData[`${this.data.varset}_BeaconJSON`] = JSON.stringify(
        this.exchangeData
      );

      this.beaconSrc.annuityData({
        data: this.exchangeData,
        index: +this.data.index,
        group: this.data.group,
        varset: this.data.varset,
      });
      this.dialogRef.close();
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  getCdsc(raw, share?) {
    let cdsc;
    const schedLabel = share ? 'Class ' + share : 'Schedule ';

    if (share) {
      cdsc = raw.filter(x => {
        return x.ShareClassName == 'Class ' + share.toUpperCase();
      });
    } else {
      cdsc = raw;
    }

    this.exchangeData[`${this.data.varset}_CDSCSchedule`] = '';
    cdsc.forEach((ind, t) => {
      if (ind['Name'].toUpperCase() != 'NO CDSC') {
        const schedArray = ind.Schedule;

        if (t == 0 && share) {
          this.exchangeData[`${this.data.varset}_CDSCSchedule`] +=
            schedLabel + ': ';
        } else if (t > 0 && share) {
          this.exchangeData[`${this.data.varset}_CDSCSchedule`] +=
            '  ' + schedLabel + ': ';
        } else if (t > 0 && !share) {
          this.exchangeData[`${this.data.varset}_CDSCSchedule`] +=
            '  Schedule ' + Number(t + 1) + ': ';
        } else {
          this.exchangeData[`${this.data.varset}_CDSCSchedule`] +=
            'Schedule ' + Number(t + 1) + ': ';
        }
        if (schedArray.length < 1) {
          this.exchangeData[`${this.data.varset}_CDSCSchedule`] += 0;
        } else {
          this.exchangeData[`${this.data.varset}_CDSCSchedule`] += schedArray;
        }
      }
    });
  }

  clearData(criteria) {
    switch (criteria) {
      case 'date':
        this.form.controls.compCtrl.setValue(null);
        this.form.controls.prodCtrl.setValue(null);
        this.form.controls.riderCtrl.setValue(null);

        Object.keys(this.exchangeData).forEach(x => {
          this.exchangeData[x] = null;
        });

        Object.keys(this.selectedProduct).forEach(x => {
          this.selectedProduct[x] = null;
        });
        this.form.controls.purchasedLivingCtrl.setValue(null);
        this.form.controls.purchasedLivingCtrl.disable();
        this.form.controls.purchasedDeathCtrl.setValue(null);
        this.form.controls.purchasedDeathCtrl.disable();
        this.form.controls.bandOptionCtrl.setValue(null);
        this.form.controls.bandOptionCtrl.disable();
        this.form.controls.bandCtrl.setValue(null);
        this.form.controls.bandCtrl.disable();
        this.form.controls.livingRollupCtrl.setValue(null);
        this.form.controls.livingRollupCtrl.disable();
        this.form.controls.livingChargesCtrl.setValue(null);
        this.form.controls.livingChargesCtrl.disable();
        this.form.controls.deathRollupCtrl.setValue(null);
        this.form.controls.deathRollupCtrl.disable();
        this.form.controls.deathChargesCtrl.setValue(null);
        this.form.controls.deathChargesCtrl.disable();
        this.form.controls.casesCtrl.setValue(null);
        this.form.controls.casesCtrl.disable();
        this.form.controls.purchasedAccumulationCtrl.setValue(null);
        this.form.controls.purchasedAccumulationCtrl.disable();
        this.form.controls.accumulationRiderCtrl.setValue(null);
        this.form.controls.accumulationRiderCtrl.disable();
        this.form.controls.accumulationChargesCtrl.setValue(null);
        this.form.controls.accumulationChargesCtrl.disable();
        this.form.controls.prodMandeCtrl.disable();
        this.form.controls.prodMandeCtrl.setValue(null);
        this.riders = null;
        this.deathRiders = [];
        this.livingRiders = [];
        this.ageBandOptions = [];
        this.ageBands = [];
        this.cases = [];
        this.rollups = {};

        this.canSelectClass = false;
        this.canSelectMande = false;
        this.canSelectRider = false;
        this.canSelectLiving = false;
        this.canSelectDeath = false;
        this.canSelectBands = false;
        this.canSelectBandOption = false;
        this.canSelectJoint = false;
        this.canSelectCases = false;
        this.showData = false;
        this.canSelectAccumulation = false;

        this.prodSearched = false;
        this.noProds = false;
        this.charges = {};

        this.livingRiders = [];
        this.deathRiders = [];
        this.accumulationRiders = [];

        Object.keys(this.exchangeData).forEach(
          x => (this.exchangeData[x] = null)
        );

        break;

      case 'company':
        this.form.controls.prodCtrl.setValue(null);
        this.form.controls.riderCtrl.setValue(null);

        Object.keys(this.exchangeData).forEach(x => {
          this.exchangeData[x] = null;
        });

        Object.keys(this.selectedProduct).forEach(x => {
          this.selectedProduct[x] = null;
        });
        this.form.controls.purchasedLivingCtrl.setValue(null);
        this.form.controls.purchasedLivingCtrl.disable();
        this.form.controls.purchasedDeathCtrl.setValue(null);
        this.form.controls.purchasedDeathCtrl.disable();
        this.form.controls.bandOptionCtrl.setValue(null);
        this.form.controls.bandOptionCtrl.disable();
        this.form.controls.bandCtrl.setValue(null);
        this.form.controls.bandCtrl.disable();
        this.form.controls.livingRollupCtrl.setValue(null);
        this.form.controls.livingRollupCtrl.disable();
        this.form.controls.livingChargesCtrl.setValue(null);
        this.form.controls.livingChargesCtrl.disable();
        this.form.controls.deathRollupCtrl.setValue(null);
        this.form.controls.deathRollupCtrl.disable();
        this.form.controls.deathChargesCtrl.setValue(null);
        this.form.controls.deathChargesCtrl.disable();
        this.form.controls.casesCtrl.setValue(null);
        this.form.controls.casesCtrl.disable();
        this.form.controls.purchasedAccumulationCtrl.setValue(null);
        this.form.controls.purchasedAccumulationCtrl.disable();
        this.form.controls.accumulationRiderCtrl.setValue(null);
        this.form.controls.accumulationRiderCtrl.disable();
        this.form.controls.accumulationChargesCtrl.setValue(null);
        this.form.controls.accumulationChargesCtrl.disable();
        this.form.controls.prodMandeCtrl.disable();
        this.form.controls.prodMandeCtrl.setValue(null);
        this.riders = null;
        this.deathRiders = [];
        this.livingRiders = [];
        this.ageBandOptions = [];
        this.ageBands = [];
        this.cases = [];
        this.rollups = {};

        this.canSelectClass = false;
        this.canSelectMande = false;
        this.canSelectRider = false;
        this.canSelectLiving = false;
        this.canSelectDeath = false;
        this.canSelectBands = false;
        this.canSelectBandOption = false;
        this.canSelectJoint = false;
        this.canSelectCases = false;
        this.showData = false;
        this.canSelectAccumulation = false;

        this.prodSearched = false;
        this.noProds = false;
        this.charges = {};

        this.livingRiders = [];
        this.deathRiders = [];
        this.accumulationRiders = [];

        Object.keys(this.exchangeData).forEach(
          x => (this.exchangeData[x] = null)
        );

        break;

      case 'product':
        this.form.controls.riderCtrl.setValue(null);
        Object.keys(this.exchangeData).forEach(x => {
          if (x != `${this.data.varset}_Carrier`) {
            this.exchangeData[x] = null;
          }
        });

        this.form.controls.purchasedLivingCtrl.setValue(null);
        this.form.controls.purchasedDeathCtrl.setValue(null);
        this.form.controls.bandOptionCtrl.setValue(null);
        this.form.controls.bandCtrl.setValue(null);
        this.form.controls.livingRollupCtrl.setValue(null);
        this.form.controls.livingChargesCtrl.setValue(null);
        this.form.controls.deathRollupCtrl.setValue(null);
        this.form.controls.deathChargesCtrl.setValue(null);
        this.form.controls.casesCtrl.setValue(null);
        this.form.controls.casesCtrl.disable();
        this.form.controls.purchasedAccumulationCtrl.setValue(null);
        this.form.controls.purchasedAccumulationCtrl.disable();
        this.form.controls.accumulationRiderCtrl.setValue(null);
        this.form.controls.accumulationRiderCtrl.disable();
        this.form.controls.accumulationChargesCtrl.setValue(null);
        this.form.controls.accumulationChargesCtrl.disable();
        this.form.controls.prodMandeCtrl.disable();
        this.form.controls.prodMandeCtrl.setValue(null);
        this.riders = null;
        this.deathRiders = [];
        this.livingRiders = [];
        this.ageBandOptions = [];
        this.ageBands = [];
        this.cases = [];
        this.rollups = {};

        this.canSelectClass = false;
        this.canSelectMande = false;
        this.canSelectRider = false;
        this.canSelectLiving = false;
        this.canSelectDeath = false;
        this.canSelectBands = false;
        this.canSelectBandOption = false;
        this.canSelectJoint = false;
        this.canSelectCases = false;
        this.showData = false;
        this.canSelectAccumulation = false;

        this.prodSearched = false;
        this.noProds = false;
        this.charges = {};

        this.livingRiders = [];
        this.deathRiders = [];
        this.accumulationRiders = [];

        Object.keys(this.exchangeData).forEach(x => {
          if (
            x != `${this.data.varset}_PurchaseDate` &&
            x != `${this.data.varset}_PurchaseYear` &&
            x != `${this.data.varset}_ProductType` &&
            x != `${this.data.varset}_Carrier`
          ) {
            this.exchangeData[x] = null;
          }
        });

        break;

      case 'benefit-living':
        Object.keys(this.exchangeData).forEach(x => {
          if (
            x != `${this.data.varset}_Carrier` &&
            x.includes('HistoricalLB') &&
            x == `${this.data.varset}_LivingBenefitName`
          ) {
            this.exchangeData[x] = null;
          }
        });

        Object.keys(this.selectedProduct).forEach(x => {
          if (
            x != 'carrier_name' &&
            x.includes('HistoricalLB') &&
            x == `${this.data.varset}_LivingBenefitName`
          ) {
            this.selectedProduct[x] = null;
          }
        });

        this.exchangeData[`${this.data.varset}_HasWithdrawalBenefit`] = 'N';
        this.form.controls.bandOptionCtrl.setValue(null);
        this.form.controls.bandOptionCtrl.disable();
        this.form.controls.bandCtrl.setValue(null);
        this.form.controls.bandCtrl.disable();
        this.form.controls.livingRiderCtrl.setValue(null);
        this.form.controls.livingRiderCtrl.disable();
        this.form.controls.livingRollupCtrl.setValue(null);
        this.form.controls.livingRollupCtrl.disable();
        this.form.controls.livingChargesCtrl.setValue(null);
        this.form.controls.livingChargesCtrl.disable();

        this.ageBandOptions = [];
        this.ageBands = [];
        this.canSelectBands = false;
        this.canSelectBandOption = false;
        this.canSelectJoint = false;
        this.charges['living'] = null;
        this.rollups = {};

        break;

      case 'withdrawal':
        if (
          this.selectedProduct.selectedRider['living']['AgeBandInfo'] &&
          this.selectedProduct.selectedRider['living']['AgeBandInfo'].length > 1
        ) {
          this.buildBandOptions(
            this.selectedProduct.selectedRider['living']['AgeBandInfo']
          );
        }

        this.form.controls.bandCtrl.setValue(null);
        this.form.controls.bandCtrl.disable();
        this.form.controls.livingRollupCtrl.setValue(null);
        this.form.controls.livingRollupCtrl.disable();
        this.form.controls.livingChargesCtrl.setValue(null);
        this.form.controls.livingChargesCtrl.disable();

        break;

      case 'ageBand':
        this.form.controls.livingRollupCtrl.setValue(null);
        this.form.controls.livingChargesCtrl.setValue(null);

        break;

      case 'living-rider':
        Object.keys(this.exchangeData).forEach(x => {
          if (
            x != `${this.data.varset}_Carrier` &&
            x.includes('HistoricalLB') &&
            x == `${this.data.varset}_LivingBenefitName`
          ) {
            this.exchangeData[x] = null;
          }
        });

        Object.keys(this.selectedProduct).forEach(x => {
          if (
            x != 'carrier_name' &&
            x.includes('HistoricalLB') &&
            x == `${this.data.varset}_LivingBenefitName`
          ) {
            this.selectedProduct[x] = null;
          }
        });

        this.exchangeData[`${this.data.varset}_HasWithdrawalBenefit`] = 'N';
        this.form.controls.bandOptionCtrl.setValue(null);
        this.form.controls.bandOptionCtrl.disable();
        this.form.controls.bandCtrl.setValue(null);
        this.form.controls.bandCtrl.disable();
        this.form.controls.livingRollupCtrl.setValue(null);
        this.form.controls.livingRollupCtrl.disable();
        this.form.controls.livingChargesCtrl.setValue(null);
        this.form.controls.livingChargesCtrl.disable();
        this.ageBandOptions = [];
        this.ageBands = [];
        this.canSelectBands = false;
        this.canSelectBandOption = false;
        this.canSelectJoint = false;
        this.charges['living'] = null;
        this.rollups = {};

        break;

      case 'benefit-death':
        Object.keys(this.exchangeData).forEach(x => {
          if (
            (x != `${this.data.varset}_Carrier` &&
              x.includes('HistoricalDB')) ||
            x == `${this.data.varset}_DeathBenefitName`
          ) {
            this.exchangeData[x] = null;
          }
        });

        Object.keys(this.selectedProduct).forEach(x => {
          if (
            (x != 'carrier_name' && x.includes('HistoricalDB')) ||
            x == `${this.data.varset}_DeathBenefitName`
          ) {
            this.selectedProduct[x] = null;
          }
        });

        this.exchangeData[`${this.data.varset}_HasDeathBenefit`] = 'N';
        this.form.controls.deathRiderCtrl.setValue(null);
        this.form.controls.deathRiderCtrl.disable();
        this.form.controls.deathRollupCtrl.setValue(null);
        this.form.controls.deathRollupCtrl.disable();
        this.form.controls.deathChargesCtrl.setValue(null);
        this.form.controls.deathChargesCtrl.disable();
        this.form.controls.casesCtrl.setValue(null);
        this.form.controls.casesCtrl.disable();
        this.canSelectCases = false;
        this.charges['death'] = null;

        break;

      case 'death-rider':
        Object.keys(this.exchangeData).forEach(x => {
          if (
            (x != `${this.data.varset}_Carrier` &&
              x.includes('HistoricalDB')) ||
            x == `${this.data.varset}_DeathBenefitName`
          ) {
            this.exchangeData[x] = null;
          }
        });

        Object.keys(this.selectedProduct).forEach(x => {
          if (
            (x != 'carrier_name' && x.includes('HistoricalDB')) ||
            x == `${this.data.varset}_DeathBenefitName`
          ) {
            this.selectedProduct[x] = null;
          }
        });

        this.exchangeData[`${this.data.varset}_HasDeathBenefit`] = 'N';
        this.form.controls.deathRollupCtrl.setValue(null);
        this.form.controls.deathRollupCtrl.disable();
        this.form.controls.deathChargesCtrl.setValue(null);
        this.form.controls.deathChargesCtrl.disable();
        this.form.controls.casesCtrl.setValue(null);
        this.form.controls.casesCtrl.disable();
        this.canSelectCases = false;
        this.charges['death'] = null;

        break;

      case 'benefit-accumulation':
        Object.keys(this.exchangeData).forEach(x => {
          if (
            x != `${this.data.varset}_Carrier` &&
            x.includes('GMABName') &&
            x == `${this.data.varset}_GMABName`
          ) {
            this.exchangeData[x] = null;
          }
        });

        this.exchangeData[`${this.data.varset}_HasGMAB`] = 'N';
        this.exchangeData[`${this.data.varset}_GMABName`] = null;
        this.exchangeData[`${this.data.varset}_GMABRiderMandE`] = null;
        this.exchangeData[`${this.data.varset}_GMABFeeName`] = null;
        this.exchangeData[`${this.data.varset}_GMABNotes`] = null;
        this.form.controls.accumulationRiderCtrl.setValue(null);
        this.form.controls.accumulationRiderCtrl.disable();
        this.form.controls.accumulationChargesCtrl.setValue(null);
        this.form.controls.accumulationChargesCtrl.disable();
        this.charges['accumulation'] = null;

        break;
    }
  }

  dlFactSheet() {
    this.unfFlowSvc.setLoading(true);
    this.selectedProduct.closed_date = new Date(
      this.selectedProduct.closed_date
    ).toISOString();
    this.selectedProduct.compare_date = new Date(
      this.selectedProduct.compare_date
    ).toISOString();
    this.selectedProduct.introduction_date = new Date(
      this.selectedProduct.introduction_date
    ).toISOString();
    this.selectedProduct.prospectus_date = new Date(
      this.selectedProduct.prospectus_date
    ).toISOString();
    this.selectedProduct.supplement_date = new Date(
      this.selectedProduct.supplement_date
    ).toISOString();
    this.selectedProduct['userSelectedDate'] = new Date(
      this.purchDate
    ).toISOString();
    this.fs.getBeaconProduct(this.selectedProduct);
  }

  displayComp(opt) {
    return opt ? opt.carrier_name : '';
  }
  displayProd(opt) {
    return opt ? opt.product_name : '';
  }
  displayRider(opt) {
    return opt ? opt.rider_name : '';
  }

  formatPercent(num) {
    const inpt = new Decimal(num);
    return +inpt.times(100);
  }

  getVarname(varname) {
    return `${this.data.varset}${varname}`;
  }

  submitOrSearchProducerNumber() {
    if (this.producerNum && this.producerNum.length > 0) {
      if (this.DTCCSearchByNPNOnly) {
        this.searchDtcc();
      } else {
        this.submittedProducerNum = true;
      }
    }
  }

  public filterValueChange(input: string, field: string): void {
    const value = input.toLowerCase();
    if (!value) {
      this.gridData = this.ogData;
    } else {
      this.gridData = this.ogData.filter(item => {
        return item[field].toLowerCase().includes(value);
      });
    }
    if (this.gridData.length === 0) {
      this.noResults = true;
      this.errorMessage = this.DEFAULT_ERROR_MESSAGE;
    } else {
      this.noResults = false;
    }
  }

  searchDtcc() {
    this.ogData = null;
    this.gridData = null;
    this.repCtrl.disable();
    this.simpleLoading = true;
    this.dialogRef.disableClose = true;
    this.simpleLoadingMessage = 'Retrieving your in-force policy book...';
    const name = this.lastName ? this.lastName.toUpperCase() : null;

    this.dtccSvc
      .searchPolicies(this.producerNum, this.policyNum, name)
      .subscribe(
        result => {
          if (result.insurancePolicies) {
            this.noResults = false;
            const gridData = [...result.insurancePolicies];
            gridData.forEach(result => {
              result['insurancePolicyPartyFullName'] =
                `${result['insurancePolicyPartyFirstName']} ${result['insurancePolicyPartyLastName']}`;
            });
            this.ogData = gridData;
            this.gridData = gridData;
          } else {
            this.noResults = true;
            this.errorMessage = this.DEFAULT_ERROR_MESSAGE;
          }
          this.repCtrl.enable();
          this.simpleLoading = false;
          this.dialogRef.disableClose = false;
          this.simpleLoadingMessage = '';
        },
        error => {
          if (error) {
            this.noResults = true;
            this.errorMessage = this.DEFAULT_ERROR_MESSAGE;
          }
          this.repCtrl.enable();
          this.simpleLoading = false;
          this.dialogRef.disableClose = false;
          this.simpleLoadingMessage = '';
        }
      );
  }

  setPolicyDetails(data) {
    this.selectedPolicyDetails = data;
  }

  getPolicyDetails() {
    this.simpleLoading = true;
    this.dialogRef.disableClose = true;
    this.simpleLoadingMessage =
      'Loading the most recent data for your in-force policy...';
    this.dtccSvc
      .getPolicyDetailsComplete(
        this.selectedPolicyDetails.insurancePolicyPolNumber,
        this.selectedPolicyDetails.insuranceParticipantCarrierParticipantNumber,
        this.selectedPolicyDetails.insuranceParticipantAssociatedFirmID
      )
      .subscribe(x => {
        // We also will likely want CDSC Schedule if it is actual values and not VAR. I'll need to look more.
        // Also, We do want ShareClass - I just need to see what the values look like from the API. Basically we'd want it to map this way:

        //   <value>1|2|3|4|5|6|7|1020500007</value>
        // <display>A|B|C|L|Bonus|No Share Class|O|I</display>
        const purchDate = new Date(x.CurrentAnnuityDetailsArray.EffectiveDate);
        const refrshDate = new Date(x.CurrentAnnuityDetailsArray.RefreshDate);
        if (x.CurrentAnnuityDetailsArray.ShareClass) {
          switch (x.CurrentAnnuityDetailsArray.ShareClass) {
            case '1':
              break;
            case 2:
              break;
            case 3:
              break;
            case 4:
              break;
            case 5:
              break;
            case 6:
              break;
            case 7:
              break;
            case 1020500007:
              break;
          }
        }

        Object.keys(x.CurrentAnnuityDetailsArray).forEach(z => {
          if (z == 'PurchaseDate') {
            this.exchangeData[`${this.data.varset}_${z}`] =
              purchDate.toISOString();
          } else if (z == 'PurchaseYear') {
            this.exchangeData[`${this.data.varset}_${z}`] =
              purchDate.getFullYear();
          } else if (z == 'RefreshDate') {
            this.exchangeData[`${this.data.varset}_${z}`] =
              refrshDate.toISOString();
          } else {
            this.exchangeData[`${this.data.varset}_${z}`] =
              x.CurrentAnnuityDetailsArray[z];
          }
        });

        if (x.AnnuityDeathRider && x.AnnuityDeathRider.length > 1) {
          x.AnnuityDeathRider.sort(
            (a, b) => +a.DBFeatureSubTypeCode - +b.DBFeatureSubTypeCode
          );
          Object.keys(x.AnnuityDeathRider[0]).forEach(z => {
            this.exchangeData[`${this.data.varset}_${z}`] =
              x.AnnuityDeathRider[0][z];
          });
        } else if (x.AnnuityDeathRider && x.AnnuityDeathRider.length == 1) {
          Object.keys(x.AnnuityDeathRider[0]).forEach(z => {
            this.exchangeData[`${this.data.varset}_${z}`] =
              x.AnnuityDeathRider[0][z];
          });
        }

        this.exchangeData[`${this.data.varset}_DTCCJSON`] = JSON.stringify(
          this.exchangeData
        );

        this.beaconSrc.annuityData({
          data: this.exchangeData,
          index: +this.data.index,
          group: this.data.group,
          varset: this.data.varset,
        });

        this.dialogRef.close();
        this.dialogRef.afterClosed().subscribe(() => {
          this.simpleLoading = false;
          this.dialogRef.disableClose = false;
          this.simpleLoadingMessage = '';
          this.dialogRef = null;
        });
      });
  }

  formatMandE(data) {
    this.canSelectMande = true;
    this.form.controls.prodMandeCtrl.enable();
    this.mandeOptions = [];
    data.forEach(el => {
      this.mandeOptions.push({
        value: `${this.formatPercent(el.MEA)}`,
        display: `${this.formatPercent(el.MEA)}% - ${el.MECurrentChargeText}`,
      });
    });
  }

  selectMandE(ev) {
    this.mande = true;
    this.exchangeData[`${this.data.varset}_BrokeragePercentFee`] = ev.value;
  }

  cantFindPolicy(): void {
    this.stepBack();
    this.triggerLookup(false);
  }

  private _filterCompanies(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.companies.filter(carrier =>
      carrier['alternate_name'].toLowerCase().includes(filterValue)
    );
  }
  private _filterProducts(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.products.filter(product =>
      product['product_name'].toLowerCase().includes(filterValue)
    );
  }
}
