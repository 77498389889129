import { Injectable, signal } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { FormArray } from '@angular/forms';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { SessionStorageService } from '../services/session-storage.service';
import { Subject } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.org = this.environment.org;
  }

  private environment;

  private hashFinished = new Subject();
  private org: string;

  hashingDone$ = this.hashFinished.asObservable();

  private clearIwFunds = new Subject();
  clearIWFunds$ = this.hashFinished.asObservable();

  private clear529PickerData = new Subject();
  clear529PickerData$ = this.clear529PickerData.asObservable();

  formValid = signal(true);
  initialErrorsList = [];

  formModified = signal({modified: false, count: 0, values:{}, app: null});

  hashInput(data, hashtype?) {
    if (hashtype && hashtype.toLowerCase() === 'md5') {
      this.hashComplete(true);
      return this.rbs.postRequest(`${this.rbs.baseUrl}/Util/MD5Hash`, {
        value: data,
      });
    } else {
      this.hashComplete(true);
      return this.rbs.postRequest(`${this.rbs.baseUrl}/Util/Hash`, {
        value: data,
      });
    }
  }

  getLinkedCases(profile) {
    return this.rbs.getRequest(`${this.rbs.baseUrl}/Profile/Linked/${profile}`);
  }

  swapInsured(profile, description = '') {
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/Profile/Swap/${profile}?insuredSwap=true&profileDescription=${description}`
    );
  }

  hashComplete(cmplt) {
    this.hashFinished.next(cmplt);
  }

  clearIwFundData() {
    this.clearIwFunds.next('clear');
  }

  clear529Picker() {
    const random = Math.floor(Math.random() * 10);
    this.clear529PickerData.next(random);
  }

  saveUnitProfile(unit, data, app, note?) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org);
    let url = `${this.rbs.baseUrl}/UnitProfile/UnitProfile?unitId=${unit}`;

    if (note) {
      url += `&note=${note}`;
    }

    return this.rbs.patchRequest(url, data, reqHeaders);
  }

  updateFormValidity(ev) {
    this.initialErrorsList = [];
    if (ev.form.errors && ev.form.errors.length > 0) {
      this.initialErrorsList = [...this.initialErrorsList, ...ev.form.errors];
    }

    Object.entries(ev.form.controls).map(field => {
      if (
        field[0] === 'PurposeRankOrder_OrderedSelection' &&
        field[1]['errors'] &&
        field[1]['touched']
      ) {
        this.initialErrorsList.push(field[1]['errors']);
      } else if (
        Object.getPrototypeOf(field[1]).constructor.name == 'FormControl'
      ) {
        if (field[1]['errors'] && field[1]['touched']) {
          this.initialErrorsList.push(field[1]['errors']);
        }
      } else if (
        Object.getPrototypeOf(field[1]).constructor.name == 'FormArray'
      ) {
        field[1]['controls'].forEach(fld => {
          this.processErrors(fld);
        });
      } else if (
        Object.getPrototypeOf(field[1]).constructor.name == 'FormGroup'
      ) {
        if (field[1]['errors'] && field[1]['touched']) {
          this.initialErrorsList.push(field[1]['errors']);
        }
        Object.entries(field[1]['controls']).forEach(fld => {
          if (fld[1]['errors'] && fld[1]['touched']) {
            this.initialErrorsList.push(fld[1]['errors']);
          }
        });
      }
    });
    const errorsList = this.initialErrorsList.filter(x => {
      return x && x != undefined;
    });

    const optOut = ev.model.regSkipped;
    const useOptOut =
      (typeof ev.model.UnitRegulation_UseOptOut == 'string' &&
        ev.model.UnitRegulation_UseOptOut == 'Y') ||
      (typeof ev.model.UnitRegulation_UseOptOut == 'boolean' &&
        ev.model.UnitRegulation_UseOptOut);
    // let regState = ev.model.UnitRegulationState_States ? ev.model.UnitRegulationState_States.includes(ev.model.LifeWizard_StateOfIssue) || ev.model.UnitRegulationState_States.includes(ev.model.AnnuityWizard_StateOfIssue) : false;
    // { regRequired: true }

    const regRequiredError = errorsList.some(x => x.regRequired);
    const requiredError = errorsList.some(x => x.required);

    if (optOut && useOptOut && regRequiredError) {
      this.formValid.set(!requiredError);
    } else if (optOut && useOptOut && !regRequiredError && !requiredError) {
      this.formValid.set(true);
    } else {
      this.formValid.set(errorsList.length < 1);
    }
  }

  processErrors(field) {
    if (Object.getPrototypeOf(field).constructor.name == 'FormGroup') {
      Object.entries(field.controls).forEach(fld => {
        if (fld[1] instanceof FormArray) {
          const fldList = fld[1] as FormArray;
          fldList.controls.forEach(subFld => {
            this.processErrors(subFld);
          });
        } else if (
          (fld[1]['errors'] || fld[1]['status'] === 'INVALID') &&
          fld[1]['touched']
        ) {
          this.initialErrorsList.push(fld[1]['errors']);
        }
      });
    } else if (Object.getPrototypeOf(field).constructor.name == 'FormArray') {
      field.controls.forEach(fld => {
        this.processErrors(fld);
      });
    }
  }

  getFilteredProgramOptions(program, profile) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'iw')
      .set('X-RB-O', this.org);
    const data =  {"InvestmentValidation":{"Program": program}}


    let url = `${this.rbs.baseUrl}/GroupProfile?groupName=FilterAlternativeInvestments&saveProfile=false&profileId=${profile}`;

    return this.rbs.patchRequest(url, data, reqHeaders);
  }
}
