import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../../services/session-storage.service';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { EnvironmentService } from '../../services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class UniversalSearchService {
  private environment;
  private baseUrl: string;
  private app: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private router: Router,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.rbs.baseUrl;
    this.org = this.environment.org;
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'pp';
    this.org = this.environment.org || 'basev2';
  }

  userSearch(search?, unit?): Observable<any> {
    const sendApp =
      this.app === 'case-explorer' || this.app === 'post-trade'
        ? 'rb'
        : this.app;
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', sendApp)
      .set('X-RB-O', this.org);
    let url = this.baseUrl + '/Hierarchy/User?searchString=';

    if (search) {
      url += search;
    } else {
      url += '%25';
    }

    if (unit) {
      url += '&UnitID=' + unit;
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  unitSearch(search?, searchOnID = false): Observable<any> {
    const sendApp =
      this.app === 'case-explorer' ||
      this.app === 'post-trade' ||
      this.app === 'account-grouping'
        ? 'rb'
        : this.app;
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', sendApp)
      .set('X-RB-O', this.org);
    let url = this.baseUrl + '/Hierarchy/Unit?searchString=';

    if (searchOnID) {
      url = this.baseUrl + '/Hierarchy/UnitList?searchString=';
    }

    if (search) {
      url += search;
    } else {
      url += '%25';
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  repCodeSearch(search?, unit?): Observable<any> {
    const sendApp =
      this.app === 'case-explorer' ||
      this.app === 'post-trade' ||
      this.app === 'account-grouping'
        ? 'rb'
        : this.app;
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', sendApp)
      .set('X-RB-O', this.org);
    let url = this.baseUrl + '/Hierarchy/RepCode?searchString=';

    if (search) {
      url += search;
    } else {
      url += '%25';
    }

    if (unit) {
      url += '&UnitID=' + unit;
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getSuggestions() {
    const sess = this.ss.get('session');

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = this.baseUrl + '/Search/Bridges';

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        const data = res;
        const suggs = {};
        data['bridges'].map(z => {
          const cat = z.Category;

          if (cat && suggs[cat]) {
            suggs[cat].push({ id: z.ID, name: z.Name, category: cat });
          } else if (cat) {
            suggs[cat] = [];
            suggs[cat].push({ id: z.ID, name: z.Name, category: cat });
          }
        });

        return suggs;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  profileSearch(type, unified = false): Observable<any> {
    if (!this.baseUrl) {
      this.environment = this.envSvc.get();
      this.baseUrl = this.environment.apiBase;
    }

    this.app = unified ? 'rb' : this.ss.get('currentApp');
    const sess = this.ss.get('session');
    const app = this.app === 'summary' ? 'pp' : this.app;

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const url = this.baseUrl + '/Search/ProfileSearch';

    const stringData = encodeURIComponent(type);
    const body = `searchCriteria=${stringData}`;

    return this.http.post(url, body.toString(), { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  nameSearch(srch): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Search/NameSearch?searchString=${srch}&unitId=%&userId=%`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  excelAddress(ids): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Reports/Address`;
    const data = { profileIds: ids };

    return this.http
      .post(url, data, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error');
        })
      );
  }
}
