<div class="w-100 notes clearfix">
  <h5>Notes</h5>
  <p [innerHTML]="this.notesData.prompt"></p>
  <div class="textbox-section">
    <mat-form-field class="w-100">
      @if (moduleNotes && notesData.required) {
        <div class="notes-notification" [ngClass]="{ valid: note }">
          @if (note && moduleNoteSaved) {
            <mat-icon class="mr-1">thumb_up</mat-icon>Required Notes Entered for
            This Module
          } @else {
            <mat-icon class="mr-1">error</mat-icon>Notes Required for This
            Module
          }
        </div>
      }
      <mat-label>Enter Notes Here</mat-label>
      <textarea
        #noteInput
        matInput
        [placeholder]="'Enter Notes Here'"
        [(ngModel)]="note"
        (keyup)="noteTyped($event)"
        (click)="getCursorPosition($event)"
        [formControl]="noteInputControl"
      ></textarea>
      @if (noteInputControl.hasError('minlength')) {
        <mat-error align="start"
          >Note must be 10 characters or more. {{ note.length }} / 10</mat-error
        >
      }
      <br />
      @if (noteInputControl.hasError('pattern')) {
        <mat-error align="start"
          >Note has too many consecutive spaces.</mat-error
        >
      }
    </mat-form-field>
    @if (canSpeechToText) {
      <app-speech-to-text
        (text)="applySpeechToTextNote($event)"
        (noteSpeechError)="applySpeechToTextError($event)"
        [cursorPosition]="cursorPosition"
        [typedText]="typedText"
      ></app-speech-to-text>
    }
  </div>
  <div class="note-actions-container">
    <button
      mat-flat-button
      color="primary"
      class="submit-note-btn"
      (click)="saveNote()"
      [disabled]="noteInputControl.errors || !note || !fieldTouched"
      *hasPermission="'!NoteValidation'"
    >
      Save Note
    </button>
    <button
      *hasPermission="'NoteValidation'"
      mat-flat-button
      color="primary"
      class="submit-note-btn"
      (click)="validateAndSaveNote()"
      [disabled]="noteInputControl.errors || !note || !fieldTouched"
    >
      Save & Review
    </button>
  </div>

  <div class="note-score-container">
    @if (noteSaved && rights.includes('ShowNoteAssistant')) {
      <div [ngClass]="noteScore">
        <div>
          <ng-container *ngIf="noteScore === 'positive'">
            <mat-icon class="close-icon" (click)="closeValidation()"
              >close</mat-icon
            >
            Your note was reviewed and saved.
          </ng-container>
          <ng-container *ngIf="noteScore === 'negative'">
            <mat-icon class="close-icon" (click)="closeValidation()"
              >close</mat-icon
            >
            <mat-icon>error</mat-icon> {{ scoreMessage }}
          </ng-container>
        </div>
      </div>
    }
    @if (containsPii) {
      <div class="negative">
        <div>
          <mat-icon class="close-icon" (click)="closePii()">close</mat-icon>
          <mat-icon>error</mat-icon> The submitted note appears to have
          sensitive information in it like an SSN or Account Number(s). Please
          check for this, remove any of these details, and try again.
        </div>
      </div>
    }
    @if (noteSpeechError) {
      <div class="negative">
        <div>
          <mat-icon class="close-icon">close</mat-icon>
          <mat-icon>error</mat-icon> {{ noteSpeechError }}
        </div>
      </div>
    }
    @if (noteSaved && !rights.includes('ShowNoteAssistant') && !containsPii) {
      <div class="note-save-success">
        <div> <mat-icon>thumb_up</mat-icon> Your note was saved. </div>
      </div>
    }
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
