@if (field.key === 'exchange') {
  <div class="exchange-control-container py-3 px-2 mb-3">
    <div class="header-section col-sm-12">
      <label class="section col-12 mb-0">Existing Annuities</label>
      <p class="explanation mb-0"
        >Select the annuities that should be included for this replacement:</p
      >
    </div>

    <div class="exchange-control-switches col-12">
      @for (controlSwitch of field?.model; track i; let i = $index) {
        <div
          tabindex="2"
          class="control-switch mb-2"
          (click)="toggleControlSwitch(i)"
          (keydown.enter)="$event.preventDefault()"
          [ngClass]="{
            'active-control':
              controlSwitch.CurrentAnnuityDetailsArray_IsExchange
          }"
          matTooltip="{{
            controlSwitch?.CurrentAnnuityDetailsArray_PlanName
          }}: {{
            controlSwitch &&
            containsCurrency(
              controlSwitch.CurrentAnnuityDetailsArray_TotalAssets
            )
              ? controlSwitch.CurrentAnnuityDetailsArray_TotalAssets
              : (controlSwitch.CurrentAnnuityDetailsArray_TotalAssets
                | currency: 'USD' : 'symbol' : '1.0-0')
          }}"
        >
          <span class="icon-container">
            <mat-icon>check</mat-icon>
          </span>
          <div class="control-data-container">
            <span class="control-data name-container text-truncate">
              Name:
              <strong class="text-truncate">{{
                controlSwitch.CurrentAnnuityDetailsArray_PlanName
              }}</strong>
            </span>
            <span
              class="control-data amount-container pull-right text-truncate"
            >
              Amount:
              @if (
                containsCurrency(
                  controlSwitch.CurrentAnnuityDetailsArray_TotalAssets
                )
              ) {
                <strong>
                  {{ controlSwitch.CurrentAnnuityDetailsArray_TotalAssets }}
                </strong>
              } @else {
                <strong>{{
                  controlSwitch.CurrentAnnuityDetailsArray_TotalAssets
                    | currency: 'USD' : 'symbol' : '1.0-0'
                }}</strong>
              }
            </span>
          </div>
        </div>
      }
    </div>
  </div>
}
@for (subField of field.fieldGroup; track subField.id; let i = $index) {
  <div>
    @if (field.key === 'exchange' ? subField['isExchange'] : true) {
      <formly-group
        [field]="subField"
        [class]="'w-100 row formly-form-container'"
      >
      </formly-group>
    }
    @if (showDelete(subField)) {
      <div class="button-container remove clearfix w-100">
        <button
          mat-button
          class="btn btn-danger w-100"
          color="warn"
          type="button"
          (click)="remove(i)"
          [disabled]="subField.props.disableCheck"
        >
          <mat-icon class="mr-2">add_circle</mat-icon>Remove
        </button>
      </div>
    }
  </div>
}
<div class="button-container clearfix w-100">
  @if (!disableAdd) {
    <button
      mat-raised-button
      class="btn btn-primary w-100"
      color="primary"
      type="button"
      (click)="add()"
    >
      <mat-icon class="mr-2">add_circle</mat-icon>Add
    </button>
  }
</div>
@if (showAllocationCalculator) {
  <app-form-calculator
    [field]="field"
    [mode]="mode"
    [totalAmount]="amountAvailable"
    [totalInvestment]="amountAllocated"
  ></app-form-calculator>
}
