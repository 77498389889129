import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
import { uniq, remove } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ModuleStatusService {
  private environment;
  private org: string;

  appStatuses = signal({
    complete: [],
    inProgress: [],
    required: [],
    available: [],
  });
  apps = signal([]);
  apps$ = toObservable(this.apps);
  showCaseSummary = signal(false);
  hasAwComparisonPage = signal(false);
  availableApps = signal([]);
  clientInfoNotRequired = signal(false);
  nextApp = signal(null);

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.org = this.environment.org;
    this.availableApps.set(this.ss.get('availableApps'));
  }

  getSession() {
    return this.rbs.getSession();
  }

  getApps(profile, empty = false): any {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);

    const url = empty
      ? `${this.rbs.baseUrl}/GroupProfile/${profile}?groupName=ModuleStatus&saveProfile=false&isEmptyProfile=true`
      : `${this.rbs.baseUrl}/GroupProfile/${profile}?groupName=ModuleStatus&saveProfile=false`;

    return this.rbs
      .getRequest(url, reqHeaders)
      .pipe()
      .subscribe(x => {
        this.ss.set('moduleStatus', x);
        this.processApps(x);
      });
  }

  processApps(resp) {
    const appStatusTemporary = {
      complete: [],
      inProgress: [],
      required: [],
      available: [],
    };
    const appsList = [];
    this.appStatuses().complete = [];
    this.appStatuses().inProgress = [];

    resp.Modules.map(module => {
      if (module.vars.ModuleStatus?.FlowStatus === 'C') {
        appStatusTemporary.complete.push(module.name.toLowerCase());
        remove(this.appStatuses().inProgress, app => {
          return app === module.name.toLowerCase();
        });
        this.appStatuses().complete = uniq(appStatusTemporary.complete);
      }

      if (
        module.vars.ModuleStatus?.FlowStatus === 'O' ||
        module.vars.ModuleStatus?.FlowStatus === 'OR'
      ) {
        appStatusTemporary.inProgress.push(module.name.toLowerCase());
        remove(this.appStatuses().complete, app => {
          return app === module.name.toLowerCase();
        });
        this.appStatuses().inProgress = uniq(appStatusTemporary.inProgress);
      }

      if (
        module.vars.ModuleStatus?.FlowStatus === 'R' ||
        module.vars.ModuleStatus?.FlowStatus === 'OR'
      ) {
        appStatusTemporary.required.push(module.name.toLowerCase());
        remove(this.appStatuses().required, app => {
          return app === module.name.toLowerCase();
        });
        this.appStatuses().required = uniq(appStatusTemporary.required);
      }

      if (
        module.vars.ModuleStatus?.FlowStatus === 'R' ||
        module.vars.ModuleStatus?.FlowStatus === 'OR' ||
        module.vars.ModuleStatus?.FlowStatus === 'X' ||
        module.vars.ModuleStatus?.FlowStatus === 'C'
      ) {
        appStatusTemporary.available.push(module.name.toLowerCase());
        remove(this.appStatuses().available, app => {
          return app === module.name.toLowerCase();
        });
        this.appStatuses().available = uniq(appStatusTemporary.available);
      }

      if (module.vars.ModuleStatus?.ShowInSidebarNav) {
        appsList.push(module.name.toLowerCase());
      }

      if (module.name === 'SummaryPage') {
        this.showCaseSummary.set(true);
      }

      if (
        resp.Modules.find(z => z.name === 'AW') &&
        resp.Modules.find(z => z.name === 'AW').vars.ModuleStatus
          .EJComparisonPage
      ) {
        this.hasAwComparisonPage.set(true);
      }

      if (resp.UnitInfo.ClientInfoPageNotRequired) {
        this.clientInfoNotRequired.set(resp.UnitInfo.ClientInfoPageNotRequired);
      }
    });
    this.apps.set(appsList);
  }

  getNextApp(profile) {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    this.rbs
      .getRequest(
        `${this.rbs.baseUrl}/GroupProfile/${profile}?groupName=NextModule&saveProfile=false`,
        reqHeaders
      )
      .subscribe(x => {
        this.nextApp.set(x.NextModule.Module.toLowerCase());
      });
  }
}
