import { Component, OnInit, Input } from '@angular/core';
import { ReportingService } from '../../reporting.service';
import { AdminService } from '../../../admin/admin.service';
import { Application, Unit } from '../../../models/admin-models';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-rep-exceptions-report',
  templateUrl: './rep-exceptions.component.html',
  styleUrls: ['./rep-exceptions.component.scss'],
})
export class RepExceptionsComponent implements OnInit {
  @Input() alertIds;
  exceptionsData = { data: null, headers: [] };
  startDate;
  endDate;
  alertId;
  filteredUnits = [];
  unitsControl = new FormControl();
  units: Unit[] = [] as Unit[];
  selectedUnit;
  applications: Application[] = [];
  selectedApp;
  viewAlertIds = true;

  constructor(
    private rptSvc: ReportingService,
    private admnSrvc: AdminService,
    private ss: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.getUnits();
    this.getDropdownOptions();
  }

  getDropdownOptions() {
    const globals = this.ss.get('globals');
    if (globals) {
      globals.Modules.forEach(app => {
        switch (app.toLowerCase()) {
          case 'aw':
            if (this.hasAlerts(app.toLowerCase())) {
              this.applications.push({
                name: 'Annuity Wizard',
                value: app.toLowerCase(),
              });
            }
            break;
          case 'iw':
            if (this.hasAlerts(app.toLowerCase())) {
              this.applications.push({
                name: 'Investment Wizard',
                value: app.toLowerCase(),
              });
            }
            break;
          case 'li':
            if (this.hasAlerts(app.toLowerCase())) {
              this.applications.push({
                name: 'Life Insurance Wizard',
                value: app.toLowerCase(),
              });
            }
            break;
          case 'pp':
            if (this.hasAlerts(app.toLowerCase())) {
              this.applications.push({
                name: 'Product Profiler',
                value: app.toLowerCase(),
              });
            }
            break;
          case 'se':
            if (this.hasAlerts(app.toLowerCase())) {
              this.applications.push({
                name: 'Suggestion Engine',
                value: app.toLowerCase(),
              });
            }
            break;
          default:
            break;
        }
      });
    }
  }

  getUnits() {
    this.unitsControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.admnSrvc.getUnitList(value).subscribe(resp => {
            if (resp['names']) {
              this.filteredUnits = resp['names'];
            }
          });
        }
      });
  }

  checkSelection() {
    if (
      !this.filteredUnits ||
      !this.filteredUnits.find(unit => {
        return unit.id === this.unitsControl.value;
      })
    ) {
      this.unitsControl.setValue(null);
    }
  }

  autoCompleteUnitChanged(ev) {
    this.unitsControl.setValue(ev.option.value);
  }

  getExceptions() {
    this.exceptionsData.data = [];
    this.exceptionsData.headers = [];
    const start = new Date(this.startDate).toISOString();
    const end = new Date(this.endDate).toISOString();
    this.viewAlertIds = false;
    this.rptSvc
      .getRepExceptions(
        start,
        end,
        this.alertId,
        this.selectedApp,
        this.selectedUnit
      )
      .subscribe({
        next: data => {
          if (data.results.data.length > 0) {
            this.exceptionsData.data = [...data.results.data];
            const keys = Object.keys(data.results.data[0]);
            keys.forEach(x => {
              this.exceptionsData.headers.push({ DataField: x, Label: x });
            });
          }
        },
        error: error => {
          console.error(error);
        },
      });
  }

  exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  hasAlerts(app = this.selectedApp) {
    return this.alertIds.find(alertId => alertId.Module.toLowerCase() === app);
  }
}
