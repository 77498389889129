<div class="page-container fia-container container">
  <p class="col-12 filter-label" *ngIf="cusip && cusipTitle">
    Currently filtering results to
    {{ cusipTitle.AnnuityProduct_AnnuityID }} &mdash;
    {{ cusipTitle.AnnuityProduct_Carrier }}
    {{ cusipTitle.AnnuityProduct_Contract }}
  </p>
  <div class="row w-100 controls">
    <mat-form-field class="col-4 default-select">
      <mat-select
        [placeholder]="'Preset Table Grouping and Filtering'"
        [(value)]="defaultValue"
        (selectionChange)="useDefault($event)"
      >
        <mat-option *ngFor="let opt of defaultList" [value]="opt.value">{{
          opt.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <app-grid-column-filter
      [columns]="gridColumns"
      (columnsUpdated)="externalColumnsUpdated($event)"
    ></app-grid-column-filter>

    <button
      mat-button
      color="primary"
      class="reset-button"
      (click)="useDefault({ value: 'clear' })"
    >
      <mat-icon>replay</mat-icon>Reset
    </button>
    <button
      mat-button
      color="primary"
      class="grid-button"
      (click)="exportToExcel(fiaGrid)"
    >
      <mat-icon>cloud_download</mat-icon>Download Excel File
    </button>
  </div>
  <kendo-grid
    class="col-12"
    #fiaGrid="kendoGrid"
    [kendoGridBinding]="ogData"
    [pageSize]="pageSize"
    [pageable]="true"
    [resizable]="true"
    [sortable]="{ allowUnsort: true, mode: 'multiple' }"
    [sort]="sort"
    [columnMenu]="{ columnChooser: false }"
    [filter]="filter"
    filterable="menu"
    (filterChange)="filterChange($event)"
    [groupable]="true"
    [group]="group"
    (dataStateChange)="dataStateChange($event)"
    app-grid-column-filter
    [height]="gridHeight()"
  >
    <ng-template ngFor [ngForOf]="gridColumns" let-column>
      <kendo-grid-column
        [field]="column.DataField"
        [hidden]="isHidden(column.DataField)"
        [title]="column.Label"
        [sortable]="column.Sort"
        [groupable]="column.Group"
        [media]="column.RespVis"
        [width]="column.Width ? column.Width : 150"
        [filterable]="column.Filter"
        [sticky]="column.Sticky"
      >
        @if (column.Sort || column.Filter) {
          <ng-template kendoGridColumnMenuTemplate let-service="service">
            @if (column.Sort) {
              <kendo-grid-columnmenu-sort [column]="column" [service]="service">
                <kendo-grid-messages
                  [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                  [sortDescending]="getFilterMenuText(column.Type, 'desc')"
                ></kendo-grid-messages>
              </kendo-grid-columnmenu-sort>
            }
            @if (column.Filter) {
              <kendo-grid-columnmenu-filter
                *ngIf="column.Filter"
                [column]="column"
                [service]="service"
              >
                <ng-template
                  kendoGridFilterMenuTemplate
                  let-filter="filter"
                  let-filterService="filterService"
                >
                  <kendo-multiselect
                    style="width: 99%"
                    [data]="filterData[column.DataField]"
                    textField="display"
                    valueField="value"
                    [valuePrimitive]="true"
                    [value]="filter | filterValues"
                    (valueChange)="
                      filterValueChange($event, column.DataField, filterService)
                    "
                    [fillMode]="'outline'"
                    placeholder="Filter By..."
                    [filterable]="true"
                    (filterChange)="onFilterChange($event, column.DataField)"
                  >
                  </kendo-multiselect>
                </ng-template>
              </kendo-grid-columnmenu-filter>
            }
          </ng-template>
        }
        <ng-template
          *ngIf="column.Type === 'integer'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | number: '1.0-0' }}
          <!-- {{dataItem[column.DataField] | percent}} -->
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'integer'"
          >{{ value | number: '1.0-0' }}</ng-template
        >
        <ng-template
          *ngIf="column.Type === 'perc'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | percent }}
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'perc'"
          >{{ value | percent }}</ng-template
        >

        <ng-template
          *ngIf="column.Type === 'perc2'"
          kendoGridCellTemplate
          let-dataItem
        >
          <ng-container
            *ngIf="
              dataItem[column.DataField] == -9.99 ||
                dataItem[column.DataField] == -999;
              else realData
            "
          >
            N/A
          </ng-container>
          <ng-template #realData>
            {{
              dataItem[column.DataField] === 'N/A' ||
              dataItem[column.DataField] === 'Uncapped'
                ? dataItem[column.DataField]
                : (dataItem[column.DataField] | percent: '1.2')
            }}
          </ng-template>
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'perc2'"
          >{{ value | percent: '1.2' }}</ng-template
        >

        <ng-template
          *ngIf="column.Type === 'curr'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | currency }}
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'curr'"
          >{{ value | currency }}</ng-template
        >
      </kendo-grid-column>
    </ng-template>
    <kendo-grid-excel fileName="RightBridge-FIA.xlsx" [fetchData]="excelData">
    </kendo-grid-excel>
    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="buttonCount"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes
        [pageSizes]="pageSizeOptions"
      ></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
