<div class="page-container account-review-detail-container container pt-2">
  @if (!loading) {
    <h1 class="header col-12 mb-1">
      Account Details - {{ accountDetail.AccountNumDisplay }}
    </h1>
    <div class="actions-container mb-3">
      <button mat-button (click)="nav('account-review')" color="primary">
        <mat-icon>arrow_back_ios</mat-icon> Back to Account Monitor
      </button>
    </div>

    <div class="trade-info-container info-card mb-4 w-100 p-0">
      <div class="card-header">
        <span class="card-title-container">
          <mat-icon
            [matTooltip]="'Account Score: ' + accountDetail.ScoreClass"
            class="scoreclass-icon mr-2"
            [class]="accountDetail.ScoreClass"
          >
            {{ scoreClassKey[accountDetail.ScoreClass] }}
          </mat-icon>
          Account #:
          <span class="card-title mb-0 ml-2">
            {{ accountDetail.AccountNumDisplay }}
          </span>
        </span>

        <span>
          <button
            class="mr-2"
            mat-raised-button
            [matMenuTriggerFor]="accountActionsMenu"
          >
            <mat-icon>settings</mat-icon> Actions
          </button>

          <button
            class="mr-2"
            mat-raised-button
            [matMenuTriggerFor]="additionalAccountActionsMenu"
          >
            <mat-icon>more_vert</mat-icon> More
          </button>

          <mat-menu
            #accountActionsMenu="matMenu"
            direction="down"
            yPosition="below"
            class="unified-menu"
          >
            @if (accountDetail.ReviewStatus !== 'INP') {
              <button mat-menu-item (click)="toggleReviewed('INP')">
                <mat-icon>construction</mat-icon> Mark In Process
              </button>
            }
            @if (accountDetail.ReviewStatus !== 'REV') {
              <button mat-menu-item (click)="toggleReviewed('REV')">
                <mat-icon>check_circle_outline</mat-icon> Mark as Reviewed
              </button>
            } @else {
              <button mat-menu-item (click)="toggleReviewed('REO')">
                <mat-icon>meeting_room</mat-icon> Reopen
              </button>
            }
          </mat-menu>

          <mat-menu
            #additionalAccountActionsMenu="matMenu"
            direction="down"
            yPosition="below"
            class="unified-menu"
          >
            @if (
              accountDetail.AccountHistory &&
              !isEmpty(accountDetail.AccountHistory)
            ) {
              <button mat-menu-item (click)="viewAccountReviewHistory()">
                <mat-icon class="mr-0">alarm_on</mat-icon> View Account Monitor
                History
              </button>
            }

            <button mat-menu-item (click)="seeDocuments()">
              <mat-icon class="mr-0">file_present</mat-icon> View Notes &
              Attachments
            </button>

            <button mat-menu-item (click)="initiateCase()">
              <mat-icon>medical_services</mat-icon> Initiate Case
            </button>
            @if (rights.includes('AccountGroup')) {
              <button mat-menu-item (click)="accountGrouping()">
                <mat-icon>dataset_linked</mat-icon> Account Grouping
              </button>
            }
          </mat-menu>
        </span>
      </div>
      <div class="card-info row">
        @for (accountInfo of accountInfoKey; track accountInfo.label) {
          <div class="col-sm-3 col-12">
            <div class="card-info-label">
              {{ accountInfo.label }}
            </div>
            <div class="card-info-value mb-2">
              @if (accountInfo.key) {
                {{ accountInfo.key[accountDetail[accountInfo.varName]] }}
              } @else if (accountInfo.varName === 'RepCode') {
                <button
                  class="detail-link-button"
                  mat-stroked-button
                  (click)="nav('rep-review/' + accountDetail['Rep']['RepID'])"
                  [matTooltip]="
                    'FP Score: ' + accountDetail['Rep']['ScoreClass']
                  "
                >
                  <span
                    [class]="
                      'detail-score-indicator mr-1 ' +
                      accountDetail['Rep']['ScoreClass']
                    "
                  ></span>
                  {{ accountDetail[accountInfo.varName] }}
                </button>
              } @else if (
                accountInfo.valueMask && accountInfo.valueMask === 'currency'
              ) {
                <span>{{ accountDetail[accountInfo.varName] | currency }}</span>
              } @else {
                {{ accountDetail[accountInfo.varName] }}
              }
            </div>
          </div>
        }
      </div>
    </div>

    @if (rights.includes('CM') && caseHistory.length > 0) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Case History </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              <th></th>
              @for (header of caseHistoryKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (case of caseHistory; track case.CRID; let idx = $index) {
              @if (idx < caseHistoryRows) {
                <tr>
                  <td width="75">
                    <div class="action-button-container">
                      <button
                        mat-icon-button
                        class="fs-button"
                        (click)="toggleCaseReview(case)"
                        color="primary"
                        matTooltip="Review Case"
                      >
                        <mat-icon>rate_review</mat-icon>
                      </button>
                    </div>
                  </td>
                  @for (caseKey of caseHistoryKey; track caseKey.label) {
                    <td>
                      @if (
                        caseKey.valueMask && caseKey.valueMask === 'currency'
                      ) {
                        <span>{{ case[caseKey.varName] | currency }}</span>
                      } @else if (
                        caseKey.valueMask && caseKey.valueMask === 'date'
                      ) {
                        <span>{{
                          case[caseKey.varName] | localTime: 'shortDateTime'
                        }}</span>
                      } @else {
                        {{ case[caseKey.varName] }}
                      }
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (caseHistory.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="toggleRows('caseHistoryRows', caseHistory.length)"
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (caseHistoryRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (caseHistoryRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }
      </div>
    }

    @if (accountDetail?.AccountProfile.bridges) {
      <div class="alerts-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Alerts </span>
        </div>
        <div class="card-info row">
          <div class="alerts">
            @for (
              bridge of accountDetail.AccountProfile.bridges;
              track bridge
            ) {
              <app-reason-text
                class="col-6"
                [data]="bridge.trueRules"
              ></app-reason-text>
            }
          </div>
        </div>
      </div>
    }

    <div class="client-suitability-container info-card mb-4">
      <div class="card-header">
        <span class="card-title mb-0"> Client Suitability Information </span>
      </div>
      <div class="card-info row pb-0">
        @for (suitabilityInfo of suitabilityKey; track suitabilityInfo.label) {
          @if (
            accountDetail.AccountProfile[suitabilityInfo.varSet][
              suitabilityInfo.varName
            ]
          ) {
            <div class="col-sm-2 col-12">
              <div class="card-info-label">
                {{ suitabilityInfo.label }}
              </div>
              @if (
                accountDetail.AccountProfile[suitabilityInfo.varSet][
                  suitabilityInfo.varName
                ]
              ) {
                <div class="card-info-value mb-2">
                  @if (
                    suitabilityInfo.valueMask &&
                    suitabilityInfo.valueMask === 'currency'
                  ) {
                    {{
                      accountDetail.AccountProfile[suitabilityInfo.varSet][
                        suitabilityInfo.varName
                      ] | currency
                    }}
                  } @else if (
                    suitabilityInfo.valueMask &&
                    suitabilityInfo.valueMask === 'percent'
                  ) {
                    {{
                      accountDetail.AccountProfile[suitabilityInfo.varSet][
                        suitabilityInfo.varName
                      ] | percent
                    }}
                  } @else {
                    {{
                      accountDetail.AccountProfile[suitabilityInfo.varSet][
                        suitabilityInfo.varName
                      ]
                    }}
                  }
                </div>
              }
            </div>
          }
        }
      </div>
    </div>

    @if (accountDetail.AccountProfile.TradeAccountPosition) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Account Holdings </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              @for (header of accountHoldingsKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (
              account of accountDetail.AccountProfile.TradeAccountPosition;
              track account.SecID;
              let idx = $index
            ) {
              @if (idx < accountHoldingsRows) {
                <tr>
                  @for (holding of accountHoldingsKey; track holding.label) {
                    <td>
                      @if (
                        holding.valueMask && holding.valueMask === 'currency'
                      ) {
                        <span>{{ account[holding.varName] | currency }}</span>
                      } @else if (
                        holding.valueMask && holding.valueMask === 'date'
                      ) {
                        <span>{{
                          account[holding.varName] | localTime: 'shortDate'
                        }}</span>
                      } @else {
                        {{ account[holding.varName] }}
                      }
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (accountDetail.AccountProfile.TradeAccountPosition.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="
                toggleRows(
                  'accountHoldingsRows',
                  accountDetail.AccountProfile.TradeAccountPosition.length
                )
              "
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (accountHoldingsRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (accountHoldingsRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }
      </div>
    }

    @if (
      accountDetail.AccountProfile.bridges &&
      accountDetail.AccountProfile.bridges[0] &&
      accountDetail.AccountProfile.bridges[0].charts &&
      !isEmpty(accountDetail.AccountProfile.bridges[0].charts)
    ) {
      <div class="breakpoints-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Account Information </span>
        </div>
        <div class="card-info row">
          @for (
            chrt of accountDetail.AccountProfile.bridges[0].charts;
            track chrt
          ) {
            @if (chrt) {
              <div
                class="chart-container clearfix mb-5"
                [ngClass]="{
                  'col-6': chrt.ChartContainer === 'half',
                  'col-12': chrt.ChartContainer === 'full'
                }"
              >
                @if (!isEmpty(chrt.Data)) {
                  <div
                    class="chart pull-left px-4"
                    [ngClass]="{
                      'col-12': chrt.LegendPosition === 'bottom',
                      'col-6': chrt.LegendPosition === 'right'
                    }"
                  >
                    <highcharts-chart
                      [Highcharts]="Highcharts"
                      [options]="chrt.Data"
                      class="mx-auto"
                    ></highcharts-chart>
                  </div>
                }

                @if (chrt.Legend) {
                  <div
                    class="chart-legend"
                    [ngClass]="{
                      'col-12': chrt.LegendPosition === 'bottom',
                      'col-6 ml-auto': chrt.LegendPosition === 'right'
                    }"
                  >
                    @if (chrt.Legend.header) {
                      <table>
                        <thead>
                          <tr>
                            @for (th of chrt.Legend.header; track th) {
                              @if (th.toLowerCase() !== 'color') {
                                <th>
                                  {{ th }}
                                </th>
                              }
                            }
                          </tr>
                        </thead>
                        <tbody>
                          @for (tr of chrt.Legend.data; track tr) {
                            <tr>
                              <ng-container
                                *ngFor="
                                  let td of tr | keyvalue: returnZero;
                                  let i = index
                                "
                              >
                                @if (
                                  chrt.Legend.header[i].toLowerCase() !==
                                  'color'
                                ) {
                                  <td>
                                    @if (
                                      chrt.Legend.header[i].toLowerCase() !==
                                        'color' &&
                                      chrt.Legend.header[0].toLowerCase() ===
                                        'color' &&
                                      i === 1
                                    ) {
                                      <span
                                        [style.background]="tr['Color']"
                                        class="indicator"
                                      ></span>
                                    }
                                    {{ td.value }}
                                  </td>
                                }
                              </ng-container>
                            </tr>
                          }
                        </tbody>
                      </table>
                    } @else {
                      @for (c of chrt.Legend; track c.value) {
                        <div>
                          <strong>{{ c.label }}</strong> {{ c.value }}
                        </div>
                      }
                    }
                  </div>
                }
              </div>
            }
          }
        </div>
      </div>
    }

    @if (accountDetail.AccountProfile.RecentAccountActivity) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Recent Account Activity </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              @for (header of recentActivityKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (
              account of accountDetail.AccountProfile.RecentAccountActivity;
              track account.SecID;
              let idx = $index
            ) {
              @if (idx < recentActivityRows) {
                <tr>
                  @for (holding of recentActivityKey; track holding.label) {
                    <td>
                      @if (
                        holding.valueMask && holding.valueMask === 'currency'
                      ) {
                        <span>{{ account[holding.varName] | currency }}</span>
                      } @else if (
                        holding.valueMask && holding.valueMask === 'date'
                      ) {
                        <span>{{
                          account[holding.varName] | localTime: 'shortDate'
                        }}</span>
                      } @else if (holding.varName === 'ScoreClass') {
                        <div class="score-class-container">
                          <mat-icon
                            [matTooltip]="'Score: ' + account[holding.varName]"
                            class="scoreclass-icon mr-2"
                            [class]="account[holding.varName]"
                          >
                            {{ scoreClassKey[account[holding.varName]] }}
                          </mat-icon>
                        </div>
                      } @else {
                        {{ account[holding.varName] }}
                      }

                      @if (holding.varName === 'TradeDate') {
                        <button
                          class="launch-account-button"
                          mat-icon-button
                          color="primary"
                          (click)="nav('trade-review/' + account['TradeID'])"
                        >
                          <mat-icon class="mr-2"> launch </mat-icon>
                        </button>
                      }
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (accountDetail.AccountProfile.RecentAccountActivity.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="
                toggleRows(
                  'recentActivityRows',
                  accountDetail.AccountProfile.RecentAccountActivity.length
                )
              "
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (recentActivityRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (recentActivityRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }
      </div>
    }
  }

  <ngx-loading [show]="loading"></ngx-loading>
</div>
