import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportingService } from '../reporting.service';
import { EnvironmentService } from '../../services/environment.service';
import { saveAs } from '@progress/kendo-file-saver';
import { AdminService } from '../../admin/admin.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Application, Unit } from '../../models/admin-models';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-unified-reporting',
  templateUrl: './unified-reporting.component.html',
  styleUrls: ['./unified-reporting.component.scss'],
})
export class UnifiedReportingComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup;

  baseUrl;
  environment;
  applications: Application[] = [
    { value: 'aw', name: 'Annuity Wizard' },
    { value: 'iw', name: 'Investment Wizard' },
    { value: 'li', name: 'Life Insurance Wizard' },
    { value: 'pp', name: 'Product Profiler' },
    { value: 'rb', name: 'Control KB (RB)' },
  ];

  units: Unit[] = [] as Unit[];
  reportTypes: string[];
  selectedApp;
  selectedType;
  loading = false;
  reportRequestTitle = '';
  reportRequestSubtitle = '';
  dashboardSettings;
  alertIds;

  constructor(
    private rptSvc: ReportingService,
    private envSvc: EnvironmentService,
    private pltfrm: Platform,
    private dbs: DashboardService,
    private admnSvc: AdminService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.dbs.getDashboardGlobals().subscribe(resp => {
      if (resp['UnitDashboardSettings']) {
        this.dashboardSettings = resp['UnitDashboardSettings'];
      }
      if (resp['AlertIDInfo']) {
        this.alertIds = resp['AlertIDInfo'];
      }
      if (resp['UnitDashboardSettings']['ReportRequestPageTitle']) {
        this.reportRequestTitle =
          resp['UnitDashboardSettings']['ReportRequestPageTitle'];
      }
      if (resp['UnitDashboardSettings']['ReportRequestPageSubtitle']) {
        this.reportRequestSubtitle =
          resp['UnitDashboardSettings']['ReportRequestPageSubtitle'];
      }
      this.loading = false;
    });

    this.reportTypes = [];
  }

  tabChanged(ev) {}

  unitProfileSelected(ev) {
    const id = ev.selectedRows[0].dataItem.ID;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    this.rptSvc.getUnitProfileReport(id).subscribe({
      next: data => {
        const filename = `${ev.selectedRows[0].dataItem.KBVersion}.json`;
        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          // this.loading = false;
        };
      },
      error: error => {
        console.error(error);
      },
    });
  }

  exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  onAppChange() {
    this.admnSvc.getReportTypes(this.selectedApp).subscribe(data => {
      this.reportTypes = data['reportTypes'];
      this.selectedType = this.reportTypes[0];
    });
  }
}
