import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { cloneDeep } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { AmountModalComponent } from '../amount-modal/amount-modal.component';
import { UnifiedFlowService } from '../unified.service';
import { MaskService } from '../../services/mask.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { ModuleStatusService } from '../../services/module-status.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { FormService } from '../../conditional-form/form-service.service';

@Component({
  selector: 'app-pp-results',
  templateUrl: './pp-results.component.html',
  styleUrls: ['./pp-results.component.scss'],
})
export class PpResultsComponent implements OnInit, AfterViewInit {
  @Input() profile;
  @Output() appStatus = new EventEmitter();
  @Output() componentLoading = new EventEmitter();
  @Output() notesValid = new EventEmitter();
  @Output() prodsSelected = new EventEmitter();

  id: string;
  chartData;
  products = [];
  productsCharts = [];
  loading = false;
  name: string;
  crossApp;
  path: string;
  awButtonTooltip;
  assessmentTypes = [];
  Highcharts: typeof Highcharts = Highcharts;
  currentApp;
  reportSbtxt: string = null;
  finalAssetsRemaining = 0;
  hideDetails = true;
  validationError = false;
  rights;
  globals;
  showReportNotes;
  notesPlaceholder;
  existingReportNotes;
  isRolloverCase = false;
  caseType = 1;
  caseAssesmentType;
  altsExpanded = false;
  availableApps = {
    aw: {
      available: false,
      required: false,
    },
    pp: {
      available: false,
      required: false,
    },
    iw: {
      available: false,
      required: false,
    },
    li: {
      available: false,
      required: false,
    },
  };
  lockedCase = false;
  moduleNotes = {
    required: false,
    prompt: '',
    include: false,
    note: '',
  };

  selectedCount = 0;
  selectedAllowed;

  constructor(
    public dialog: MatDialog,
    private tes: TagsEvaluationService,
    private unfFlowSvc: UnifiedFlowService,
    private mskSvc: MaskService,
    private ss: SessionStorageService,
    private modStatusSvc: ModuleStatusService,
    private frmSvc: FormService
  ) {}

  ngOnInit(): void {
    this.rights = this.ss.get('rights');
    this.globals = this.ss.get('globals');
    this.selectedAllowed =
      this.globals.GlobalsGroup?.UnitSettings?.MaxSelectProducts &&
      this.globals.GlobalsGroup?.UnitSettings?.MaxSelectProducts > 0
        ? this.globals.GlobalsGroup.UnitSettings.MaxSelectProducts
        : 9999;
  }

  ngAfterViewInit(): void {
    this.getResults(this.profile);
    this.getNextApp(this.profile);
  }

  getResults(profile): any {
    setTimeout(() => {
      const save = this.unfFlowSvc.getSaving();

      if (save) {
        this.getResults(this.profile);
      } else {
        this.unfFlowSvc.setLoading(true);
        this.unfFlowSvc.getPPResults(profile).subscribe(data => {
          if (this.globals.GlobalsGroup.UnitNotesSettings) {
            if (data.ClientProfiling && data.ClientProfiling.Notes) {
              this.moduleNotes.note = data.ClientProfiling.Notes;
            }

            this.moduleNotes.include =
              this.globals.GlobalsGroup.UnitNotesSettings.IncludeSinglePPNote;
            this.moduleNotes.required =
              this.globals.GlobalsGroup.UnitNotesSettings.SinglePPNoteRequired;
            this.moduleNotes.prompt =
              this.globals.GlobalsGroup.UnitNotesSettings.SinglePPNotePrompt;
          }
          this.updatePageData(data);
          this.rights = this.ss.get('rights');
          if (
            data?.ClientProfilingOut?.SubmitStatus &&
            this.rights.includes('locksubmittedcase')
          ) {
            this.lockedCase = true;
          }
        });
      }
    }, 250);

    this.frmSvc.formModified.set({ modified: false, count: 0, values: {}, app: this.currentApp })
  }

  getNextApp(profile) {
    this.unfFlowSvc.getNextApp(profile).subscribe(data => {
      if (data.UnitInfo.ViewAlternativesDefaultOpen) {
        this.altsExpanded = true;
      }
    });
  }

  updatePageData(data, internal = false) {
    this.selectedCount = 0;

    if (!internal) {
      this.unfFlowSvc.setLoading(true);
    } else {
      this.loading = true;
    }
    this.modStatusSvc.getApps(this.profile);
    this.unfFlowSvc.setSaving(false);

    this.caseAssesmentType = data.ClientProfiling.AssessmentType;
    this.products = [];
    this.assessmentTypes = [];

    const types = [];
    const parsed = data;
    const prodList = parsed.Products;
    this.reportSbtxt = parsed.UnitSettings.ReportSubtext;
    this.finalAssetsRemaining =
      Math.round(
        (parsed.SourceValidationCalcs.FinalAssetsRemaining + Number.EPSILON) *
          1000
      ) / 1000;
    this.hideDetails =
      parsed.UnitSettings.PrintCompare == 'Y' &&
      parsed.ClientProfiling.AssessmentType == 2;
    this.hideDetails = !this.hideDetails;
    this.awButtonTooltip = parsed.UnitSettings.AWButtonTooltip;
    this.showReportNotes = parsed.UnitQuestions.NoteField.toLowerCase() == 'y';
    this.existingReportNotes = parsed.ClientProfiling.Notes;
    this.notesPlaceholder = parsed.UnitQuestions.NoteFieldContents;
    this.caseType = parsed.ClientProfiling.AssessmentType;
    this.name = parsed.FirstName + ' ' + parsed.LastName;

    this.products = prodList.filter(x => {
      return !x.chart;
    });

    this.products.forEach(x => {
      x.negativeReason = false;
      if (x.subBridges) {
        x.subBridges.forEach(sub => {
          if (sub.trueRules) {
            sub.trueRules.forEach(tr => {
              x.trueRules.push(tr);
            });
          }
        });
      }

      if (!x.ShortName && !x.LongName) {
        x.ShortName = x.name;
      }
      if (x.trueRules) {
        x.trueRules.forEach(e => {
          e.explanation = this.tes.evalTags(e.explanation);
          e.headline = this.tes.evalTags(e.headline);
          x.negativeReason =
            e.reasonType == 'Negative' ? true : x.negativeReason;
        });
      }

      if (x.name != 'DataEntryValidation') {
        const requiredNotes =
          x.vars.CurrentBridgeNote.Required == 2
            ? true
            : x.vars.CurrentBridgeNote.Required == 3 && x.negativeReason;

        let notesEntered = false;

        if (this.moduleNotes.include && this.moduleNotes.note) {
          notesEntered = this.moduleNotes.note.length > 0;
        } else if (
          x.vars.CurrentBridgeNote &&
          x.vars.CurrentBridgeNote.NoteText
        ) {
          notesEntered = x.vars.CurrentBridgeNote.NoteText.length > 0;
        }

        x.notesValid =
          (requiredNotes && notesEntered) || !requiredNotes ? true : false;

        if (!x.notesValid) {
          this.unfFlowSvc.setNoteValidity({ app: 'pp', valid: false });
        }
      }

      if (x.PlanGrid && x.PlanGrid.length > 0) {
        x.PlanGrid = JSON.parse(x.PlanGrid);
      }

      if (x.ComplianceChart && x.ComplianceChart.length > 0) {
        x.ComplianceChart = JSON.parse(x.ComplianceChart);
      }

      if (x.BridgeDetailChart && x.BridgeDetailChart.length > 0) {
        x.BridgeDetailChart = JSON.parse(x.BridgeDetailChart);
      }

      if (x.assessmenttype) {
        if (!this.assessmentTypes.includes(x.assessmenttype)) {
          types.push(x.assessmenttype);
        }
        if (x.assessmenttype === 'validate') {
          this.selectedCount++;
        }
      }

      if (x.RelatedBridge) {
        const relatedBrdg = cloneDeep(JSON.parse(x.RelatedBridge));

        relatedBrdg.Bridges.forEach((el, i) => {
          const bridge = prodList.find(z => z.name == el);
          if (bridge) {
            relatedBrdg.Bridges[i] = {};
            relatedBrdg.Bridges[i]['charts'] = [];
            bridge.charts.forEach(z => {
              z.Data.plotOptions.series = {};
              z.Data.plotOptions.series.events = {
                legendItemClick: function (e) {
                  e.preventDefault();
                },
              };
              relatedBrdg.Bridges[i]['charts'].push(z);
              relatedBrdg.Bridges[i].ShortDescription = bridge.ShortDescription;
              bridge.trueRules.forEach(rs => {
                rs.explanation = this.tes.evalTags(rs.explanation);
                rs.headline = this.tes.evalTags(rs.headline);
              });
              relatedBrdg.Bridges[i].reasons = bridge.reasons;
              relatedBrdg.Bridges[i].dynamicContent =
                bridge[bridge.dynamicContent];
              relatedBrdg.Bridges[i].header = bridge.ShortName;
            });
          } else if (relatedBrdg.Bridges.length == 1) {
            relatedBrdg.Bridges = null;
          } else {
            relatedBrdg.Bridges[i] = null;
          }

          x['bridgeCharts'] = relatedBrdg;
        });
      }

      this.isRolloverCase =
        x.BridgeType == 'rollover' ? true : this.isRolloverCase;
    });
    this.validationError =
      this.products.length == 1 &&
      this.products[0].name == 'DataEntryValidation';

    const fullSet = ['rollover', 'validate', 'select', 'chart'];
    this.assessmentTypes = fullSet.filter(x => types.includes(x));

    this.prodsSelected.emit(this.assessmentTypes.includes('validate'));

    this.unfFlowSvc.setLoading(false);
    this.loading = false;
  }

  expand(event) {
    const el = this.closestByClass(event.target, 'product');
    el.classList.toggle('expanded');
  }

  selectToggle(ev, row) {
    this.unfFlowSvc.setSaving(true);
    let saveData;

    if (row.assessmenttype.toLowerCase() === 'select') {
      if (this.selectedCount >= this.selectedAllowed) {
        let dialogRef = this.dialog.open(WarnDialogComponent, {
          panelClass: 'warn-dialog',
          data: {
            headline: 'Warning',
            content:
              'You have selected the maximum number of products allowed. If you would like to select another product, please deselect the currently selected product.',
            confirm: 'OK',
            hideCancel: true,
          },
        });

        dialogRef.afterClosed().subscribe(() => {
          dialogRef = null;
        });
      } else {
        const dialogRef = this.dialog.open(AmountModalComponent, {
          minWidth: '550px',
          panelClass: 'amount-dialog',
          data: {
            amount: row.Amount,
            finalAssetsRemaining: this.finalAssetsRemaining,
          },
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.data != 'cancel') {
            this.loading = true;
            saveData = {
              Client: {
                ID: this.profile,
              },
              CPProcessControl: {
                SelectOperation: 1,
              },
              PPControlSelect: [
                {
                  BridgeID: row.name,
                  Amount: this.mskSvc.removeMoneyMask(result.data),
                },
              ],
            };

            // this.selectedCount++;

            this.unfFlowSvc
              .savePPSelected(this.profile, saveData)
              .subscribe(data => {
                this.modStatusSvc.getNextApp(this.profile);
                this.updatePageData(data, true);
              });
            this.unfFlowSvc.setSaving(false);
          } else {
            this.unfFlowSvc.setSaving(false);
          }
        });
      }
    } else {
      this.unfFlowSvc.setSaving(true);
      saveData = {
        Client: {
          ID: this.profile,
        },
        CPProcessControl: {
          SelectOperation: 2,
        },
        PPControlSelect: [
          {
            BridgeID: row.name,
            Amount: 0,
          },
        ],
      };

      // this.selectedCount--;

      this.unfFlowSvc.savePPSelected(this.profile, saveData).subscribe(data => {
        this.updatePageData(data, true);
        if (this.moduleNotes.include && !this.moduleNotes.note) {
          this.unfFlowSvc.setNoteValidity({ app: 'pp', valid: false });
        } else if (
          data.Products.find(
            product => product.notesValid && product.notesValid !== true
          )
        ) {
          this.unfFlowSvc.setNoteValidity({ app: 'pp', valid: false });
        } else {
          this.unfFlowSvc.setNoteValidity({ app: 'pp', valid: true });
        }
        this.unfFlowSvc.setSaving(false);
      });
      this.unfFlowSvc.setSaving(false);
    }
  }

  editAmount(ev, item) {
    let saveData;
    const dialogRef = this.dialog.open(AmountModalComponent, {
      minWidth: '550px',
      panelClass: 'amount-dialog',
      data: {
        amount: item.Amount,
        finalAssetsRemaining: this.finalAssetsRemaining,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data != 'cancel') {
        this.unfFlowSvc.setLoading(true);
        saveData = {
          Client: {
            ID: this.profile,
          },
          CPProcessControl: {
            SelectOperation: 2,
          },
          PPControlSelect: [
            {
              BridgeID: item.name,
              Amount: this.mskSvc.removeMoneyMask(result.data),
            },
          ],
        };

        this.unfFlowSvc.savePPSelected(this.profile, saveData).subscribe(() => {
          saveData = {
            Client: {
              ID: this.profile,
            },
            CPProcessControl: {
              SelectOperation: 1,
            },
            PPControlSelect: [
              {
                BridgeID: item.name,
                Amount: this.mskSvc.removeMoneyMask(result.data),
              },
            ],
          };

          this.unfFlowSvc
            .savePPSelected(this.profile, saveData)
            .subscribe(data => {
              this.updatePageData(data, true);
            });
        });
      }
    });
  }

  handleNotesValidation(ev, item?) {
    if (!this.moduleNotes.include) {
      item.notesValid = ev;
      this.unfFlowSvc.setNoteValidity({
        app: 'pp',
        valid: !(this.products.filter(x => !x.notesValid).length > 0),
      });
    } else {
      this.unfFlowSvc.setNoteValidity({
        app: 'pp',
        valid: ev,
      });
    }
  }

  updateModuleNote(ev) {
    this.moduleNotes.note = ev;
  }

  toggleAlternatives() {
    this.altsExpanded = !this.altsExpanded;
  }

  closestByClass(el, clazz) {
    while (!el.classList.contains(clazz)) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  }
}
