<mat-card appearance="outlined" class="col-12 stretch case-activity">
  <mat-card-header> Case Statistics Summary </mat-card-header>
  <mat-card-content class="row">
    <div class="col-12 mb-3 toolbar">
      <mat-form-field class="mr-2">
        <mat-label>Solution</mat-label>
        <mat-select [(ngModel)]="solution" (selectionChange)="updateData()">
          @for (sltn of solutions; track sltn.value) {
            <mat-option [value]="sltn.value">{{ sltn.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-button-toggle-group
        [(ngModel)]="assessmentType"
        (change)="updateData()"
        class="mr-2"
      >
        <mat-button-toggle value="VALIDATE">Validate</mat-button-toggle>
        <mat-button-toggle value="SELECT">Select</mat-button-toggle>
        <mat-button-toggle value="ALL">Both</mat-button-toggle>
      </mat-button-toggle-group>
      @if (!limit30) {
        <mat-form-field class="mr-2">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startPicker"
            placeholder="Choose a start date"
            [formControl]="startDate"
            (dateChange)="updateData()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="endPicker"
            placeholder="Choose an end date"
            [formControl]="endDate"
            (dateChange)="updateData()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      }
    </div>
    <div class="button-row">
      @if (unitList && unitList.length >= 1) {
        <button mat-button (click)="goBack()">
          <mat-icon>arrow_back</mat-icon> Go back to

          @if (unitList.length > 1) {
            {{ unitList[unitList.length - 2].UnitName }}
          } @else {
            Top Level
          }
        </button>
      }
    </div>
    <div class="col-12 data-container">
      <kendo-grid
        class="col-12 case-activity-grid"
        #resultsGrid="kendoGrid"
        [kendoGridBinding]="data"
        scrollable="none"
        (cellClick)="handleRowClick($event)"
      >
        @if (level === 'UNIT_USERS') {
          <kendo-grid-column
            field="UserName"
            title="User"
            [headerClass]="'header'"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.UserName }}
            </ng-template>
          </kendo-grid-column>
        }
        @if (level !== 'UNIT_USERS') {
          <kendo-grid-column
            field="UnitName"
            title="Unit"
            [headerClass]="'header'"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [ngClass]="{ active: dataItem.Units >= 1 }">
                {{ dataItem.UnitName }}
                @if (dataItem.Units >= 1) {
                  <mat-icon>trending_flat</mat-icon>
                }
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="UsersWithCases"
            title="# of Users"
            [headerClass]="'header'"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [ngClass]="{ active: dataItem.Users >= 1 }">
                {{ dataItem.UsersWithCases }}
                @if (dataItem.Users >= 1) {
                  <mat-icon>trending_flat</mat-icon>
                }
              </span>
            </ng-template>
          </kendo-grid-column>
        }
        <kendo-grid-column
          field="Cases"
          title="# of Cases"
          [headerClass]="'header'"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Products"
          title="# of Products"
          [headerClass]="'header'"
        ></kendo-grid-column>
        <kendo-grid-column
          field="PositivePct"
          title="Greens"
          [headerClass]="'header'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.Positive }} | {{ dataItem.PositivePct }}%
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="NeutralPct"
          title="Yellows"
          [headerClass]="'header'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.Neutral }} | {{ dataItem.NeutralPct }}%
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="NegativePct"
          title="Reds"
          [headerClass]="'header'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.Negative }} | {{ dataItem.NegativePct }}%
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </mat-card-content>
  <ngx-loading
    [show]="loading"
    [config]="{ fullScreenBackdrop: false }"
  ></ngx-loading>
</mat-card>
