<div class="col-12 row cases-by-scoreclass-container">
  <h4>Products By Score Class <small>(Last 30 days)</small></h4>
  @if (chartData) {
    <div class="chart-container col-12">
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="chartData"
        style="width: 100%; display: block; overflow: hidden"
      >
      </highcharts-chart>
    </div>
  }
</div>
<ngx-loading
  [show]="loading"
  [config]="{
    fullScreenBackdrop: false
  }"
></ngx-loading>
